import React, { forwardRef, Ref } from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { APP_URL } from 'shared/constants';

import { ComponentDataProps } from '../../types';

export type RouterLinkProps = MuiLinkProps &
  ComponentDataProps & {
    absolute?: boolean;
    to?: string;
    replace?: boolean;
  };

export const RouterLink = forwardRef(
  (
    {
      absolute = false,
      onClick,
      to = APP_URL.user.home.index,
      replace = false,
      style = {},
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      ...rest
    }: RouterLinkProps,
    ref: Ref<HTMLSpanElement>,
  ) => {
    const history = useHistory();

    const commonProps = {
      ...rest,
      'data-test': dataTest,
      'data-node-id': dataNodeID,
      'data-node-render-path': dataRenderPath,
      style: {
        cursor: 'pointer',
        ...style,
      },
    };

    if (absolute) {
      return <MuiLink ref={ref} onClick={onClick} {...commonProps} />;
    }

    return (
      <MuiLink
        ref={ref}
        {...commonProps}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          onClick?.(event);
          replace ? history.replace(to) : history.push(to);
        }}
      />
    );
  },
) as React.FC<RouterLinkProps>;
