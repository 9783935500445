/* eslint-disable consistent-return */
import React from 'react';
import { APIResponse, StreamClient } from 'getstream';
import * as R from 'ramda';

import { POST_REACTIONS } from '@jebel/constants';

import { useStreamChat } from 'providers/StreamChatProvider';
import { StreamChatEnrichedActivity, CommentEnrichedReaction } from 'shared/types';
import { sendToSentry } from 'shared/utils/sentry';
import { HomeFeedItem } from 'shared/graphql';
import { MentionPosition } from 'shared/features/mentions/types';

import { usePostReactionsNotifications } from '../features/notifications';
import { CommentImage, STREAM_CHAT_ENRICHED_ACTIVITY_QUERY_KEY } from '../features/posts';
import { useSchoolConfiguration } from './useSchoolConfiguration';
import { useCheckComment } from './useCheckComment';
import { useCurrentUser } from './useCurrentUser';
import { useQueryClient } from 'react-query';

export type QueryActivitiesProps = {
  id?: string;
  postId?: string;
  createdAt: string;
}[];

export type StreamChatActivity = StreamChatEnrichedActivity;

/** @deprecated Use `useStreamChatEnrichedActivity` or `useStreamChatEnrichedActivities` instead. */
export const useStreamChatActivityQuery = () => {
  const [activities, setActivities] = React.useState<StreamChatActivity[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const activitiesRef = React.useRef<StreamChatActivity[]>([]);
  const postsRef = React.useRef<any[]>([]);
  const { streamChatClient } = useStreamChat();

  const queryActivities = React.useCallback(
    async (postsInfo: QueryActivitiesProps) => {
      setLoading(true);
      try {
        const newActivities = await streamChatClient?.getActivities({
          reactions: { counts: true, recent: true, own: true },
          foreignIDTimes: postsInfo.map(data => ({
            foreign_id: String(data.postId ?? data.id),
            time: data.createdAt,
          })),
        });

        const results = (newActivities?.results as StreamChatActivity[]) || [];

        if (!results?.length) {
          return;
        }

        const totalReactions = {
          page: results[0].verb,
          count: results.reduce((totalReactions, post) => {
            const totalComments = post?.latest_reactions?.comment?.length ?? 0;
            const totalLikes = post?.latest_reactions?.like?.length ?? 0;
            return totalReactions + totalLikes + totalComments;
          }, 0),
        };

        localStorage.setItem(`${totalReactions.page} posts`, totalReactions.count.toString());

        const totalActivities = R.uniqBy(
          activity => activity.id,
          [...activitiesRef.current, ...results],
        );

        setActivities(totalActivities);
        activitiesRef.current = totalActivities;

        return newActivities;
      } catch (e: any) {
        sendToSentry(`Query activities error: ${e}`);
      } finally {
        setLoading(false);
      }
    },
    [streamChatClient],
  );

  const onListQueryComplete = React.useCallback(
    (posts: HomeFeedItem[]) => {
      const newPosts = posts.sort(post => {
        if (post.isPinned) {
          return 0;
        }

        return 1;
      });

      const previousPosts = postsRef.current;
      const diffPosts = R.differenceWith((a, b) => a.id === b.id, newPosts, previousPosts);

      if (!diffPosts.length) {
        return;
      }

      const postsInfo = diffPosts
        .map(post => {
          if (!post.id || !post.createdAt) {
            return null;
          }

          return {
            postId: post.id,
            createdAt: post.createdAt,
          };
        })
        .filter(Boolean) as QueryActivitiesProps;

      queryActivities(postsInfo);

      postsRef.current = [...newPosts];
    },
    [queryActivities],
  );

  return {
    activities,
    loading,
    queryActivities,
    /** @deprecated No longer needed. */
    onListQueryComplete,
    postsRef,
  };
};

type PostIdProps =
  | {
      activityId: string;
      foreignId?: string;
      commentId?: never;
    }
  | {
      activityId?: never;
      foreignId?: string;
      commentId: string;
    }
  | {
      activityId: string;
      foreignId?: never;
      commentId: string;
    };

type ParentEntityProps = {
  parentEntityAuthorId?: string;
  parentEntityType?: ParentEntityType;
};

export type CommentAddProps = {
  text: string | undefined;
  media: CommentImage[];
  mentions?: MentionPosition[];
} & PostIdProps &
  ParentEntityProps;

export type OnCommentAdd = (props: CommentAddProps) => Promise<CommentEnrichedReaction | undefined>;

export type OnCommentRemove = (reactionId: string) => Promise<APIResponse | undefined>;

export type LikeAddProps = PostIdProps & ParentEntityProps;

export type ReactionType = 'add' | 'addChild';

export type ParentEntityType = 'post' | 'topic' | 'comment';

export type OnCustomReactionAdd = (
  props: LikeAddProps & {
    type: string;
  },
) => // eslint-disable-next-line camelcase
Promise<{ user_id: string; id: string }[] | undefined>;
export type CommentRemoveProps = PostIdProps;

export type OnLikeAdd = (
  props: LikeAddProps,
) => Promise<{ user_id: string; id: string }[] | undefined>;

export type OnReactionRemove = (reactionId: string) => Promise<APIResponse | undefined>;

export function useStreamChatReactions() {
  const { streamChatClient } = useStreamChat();
  const { user } = useCurrentUser();
  const { sendPostReactionNotification } = usePostReactionsNotifications();
  const { configuration } = useSchoolConfiguration();
  const { onCheckComment } = useCheckComment();

  const queryClient = useQueryClient();

  const blacklistId = configuration?.wordsBlacklist?.blacklistId ?? '';

  const onCommentAdd = React.useCallback<OnCommentAdd>(
    async ({
      text,
      activityId,
      commentId,
      parentEntityAuthorId,
      parentEntityType,
      media,
      foreignId,
      mentions,
    }) => {
      const validComment = await onCheckComment({ text, blacklistId, media });

      if (!validComment) {
        return;
      }

      const reactionType: ReactionType = activityId ? 'add' : 'addChild';
      const reactionId = activityId || commentId;

      if (!reactionId || !streamChatClient) {
        return;
      }

      if (parentEntityAuthorId && parentEntityType) {
        const notificationReactionType = getNotificationReactionType(parentEntityType);

        sendPostReactionNotification({
          authorId: parentEntityAuthorId,
          postId: foreignId || '',
          type: notificationReactionType,
        });
      }

      const commentCreate = await streamChatClient?.reactions[reactionType](
        'comment',
        reactionId,
        {
          text,
          deleted: false,
          media,
          mentions,
        },
        {
          userId: user?.id || '',
        },
      );

      // Invalidate the query to force refresh of the data later.
      queryClient.invalidateQueries([STREAM_CHAT_ENRICHED_ACTIVITY_QUERY_KEY, foreignId]);

      return commentCreate as CommentEnrichedReaction;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [streamChatClient, sendPostReactionNotification],
  );

  const onCustomReactionAdd = React.useCallback<OnCustomReactionAdd>(
    async ({ activityId, commentId, type, parentEntityAuthorId, foreignId }) => {
      if (!streamChatClient) {
        return;
      }

      const addReaction = async () => {
        if (activityId) {
          await streamChatClient.reactions.add(type, activityId);
        }

        if (commentId) {
          await streamChatClient?.reactions.addChild(type, commentId);

          if (parentEntityAuthorId) {
            const notificationReactionType =
              type === 'helpful' ? POST_REACTIONS.helpfulComment : POST_REACTIONS.notHelpfulComment;
            sendPostReactionNotification({
              authorId: parentEntityAuthorId,
              postId: foreignId || '',
              type: notificationReactionType,
            });
          }
        }
      };

      const reactions = await streamChatClient.reactions.filter({
        activity_id: activityId,
        reaction_id: commentId,
        kind: type,
      });

      const userReactions =
        reactions.results.filter(reaction => reaction.user_id === streamChatClient.userId) || [];

      const removeExtraLikes = async () => {
        if (userReactions.length < 2) {
          return;
        }

        const redundantReactions = userReactions.slice(1);
        const deleteReactionsPromise = redundantReactions.map(reaction =>
          streamChatClient.reactions.delete(reaction.id),
        );

        await Promise.all(deleteReactionsPromise);
      };

      if (userReactions.length) {
        removeExtraLikes();

        return reactions.results.filter(
          reaction =>
            reaction.user_id !== streamChatClient.userId || reaction.id === userReactions[0].id,
        );
      }

      await addReaction();

      // Invalidate the query to force refresh of the data later.
      queryClient.invalidateQueries([STREAM_CHAT_ENRICHED_ACTIVITY_QUERY_KEY, foreignId]);

      return [
        ...reactions.results,
        { user_id: streamChatClient.userId || '', id: streamChatClient.userId || '' },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [streamChatClient, sendPostReactionNotification],
  );

  const onLikeAdd = React.useCallback<OnLikeAdd>(
    async ({ activityId, commentId, parentEntityAuthorId, foreignId }) => {
      if (!streamChatClient) {
        return;
      }

      const addLike = async () => {
        if (commentId) {
          const response = await streamChatClient?.reactions.addChild('like', commentId);

          if (parentEntityAuthorId) {
            await sendPostReactionNotification({
              postId: foreignId || '',
              authorId: parentEntityAuthorId,
              type: POST_REACTIONS.likeComment,
            });
          }

          return response;
        }

        const response = await streamChatClient.reactions.add('like', activityId as string);

        if (parentEntityAuthorId) {
          await sendPostReactionNotification({
            postId: foreignId || '',
            authorId: parentEntityAuthorId,
            type: POST_REACTIONS.likePost,
          });
        }

        return response;
      };

      const likes = await streamChatClient.reactions.filter({
        activity_id: activityId,
        reaction_id: commentId,
        kind: 'like',
      });

      const userLikes =
        likes.results.filter(reaction => reaction.user_id === streamChatClient.userId) || [];

      const removeExtraLikes = async () => {
        if (userLikes.length < 2) {
          return;
        }

        const redundantReactions = userLikes.slice(1);
        const deleteReactionsPromise = redundantReactions.map(reaction =>
          streamChatClient.reactions.delete(reaction.id),
        );

        await Promise.all(deleteReactionsPromise);
      };

      if (userLikes.length) {
        removeExtraLikes();

        return likes.results.filter(
          reaction =>
            reaction.user_id !== streamChatClient.userId || reaction.id === userLikes[0].id,
        );
      }

      const response = await addLike();

      // Invalidate the query to force refresh of the data later.
      queryClient.invalidateQueries([STREAM_CHAT_ENRICHED_ACTIVITY_QUERY_KEY, foreignId]);

      return [...likes.results, response];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [streamChatClient, sendPostReactionNotification],
  );

  const onReactionRemove = React.useCallback(
    async (reactionId: string) => {
      if (!streamChatClient) {
        return;
      }

      const response = await streamChatClient.reactions.delete(reactionId);

      // Invalidate the query to force refresh of the data later.
      queryClient.invalidateQueries(STREAM_CHAT_ENRICHED_ACTIVITY_QUERY_KEY);

      return response;
    },
    [queryClient, streamChatClient],
  );

  const onCommentRemove = React.useCallback<OnCommentRemove>(
    async (reactionId: string) => {
      if (!streamChatClient) {
        return;
      }

      const response = await streamChatClient.reactions.update(reactionId, { deleted: true });

      // Invalidate the query to force refresh of the data later.
      queryClient.invalidateQueries(STREAM_CHAT_ENRICHED_ACTIVITY_QUERY_KEY);

      return response;
    },
    [queryClient, streamChatClient],
  );

  return {
    onCommentAdd,
    onCommentRemove,
    onLikeAdd,
    onReactionRemove,
    onCustomReactionAdd,
  };
}

const getNotificationReactionType = (entityType: ParentEntityType) => {
  switch (entityType) {
    case 'post':
      return POST_REACTIONS.replyPost;
    case 'topic':
      return POST_REACTIONS.replyTopic;
    case 'comment':
      return POST_REACTIONS.replyComment;
  }
};
