import { useSchoolConfiguration } from 'shared/hooks';

import { AuthorNameLink, AuthorNameLinkProps } from './AuthorNameLink';
import { SchoolConfigurationFragment } from 'shared/graphql';

interface Props extends Omit<AuthorNameLinkProps, 'name'> {
  school?: SchoolConfigurationFragment;
}

export function SchoolNameLink(props: Props) {
  const { data: currentSchool } = useSchoolConfiguration();

  const name = props?.school?.name ?? currentSchool?.name;

  return <AuthorNameLink className={props?.className} name={name} />;
}
