import { gql } from '@apollo/client';

import { USER_FRAGMENT } from 'shared/features/community-sidebar/queries';
import { DISCOUNT_PRESENTATION_FRAGMENT } from 'shared/graphql/discounts';

import { DISCOUNT_LIST_ITEM_FRAGMENT } from '../discounts/query';
import { CAREER_INFO_FRAGMENT } from '../../shared/features/search/queries';

export const HOME_FEED_POSTS_LIST_ITEM_FRAGMENT = gql`
  fragment HomeFeedPostsListItem on HomeFeedPost {
    id
    createdAt
    text
    isPinned
    commentsAllowed

    discount {
      ...DiscountPresentation
    }

    media {
      items {
        id
        downloadUrl
        meta
        fileId
        filename
      }
    }
    author {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
      ownedOrganizations {
        items {
          id
          advertising {
            id
            supporter {
              id
              isSupporter
            }
          }
        }
      }
    }
    createdBy {
      id
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
    status
    reportStatus
    flaggedAt
    flagReason
    updatedAt
    isAutoReported
    lastFlaggedBy {
      id
      firstName
      lastName
    }
    mentions {
      items {
        id
        mentionId
        mentionText
        position
        type
      }
    }
  }

  ${DISCOUNT_PRESENTATION_FRAGMENT}
`;

export const HOME_FEED_POST_CREATE_MUTATION = gql`
  mutation HomeFeedPostCreate($data: HomeFeedPostCreateInput!) {
    homeFeedPostCreate(data: $data) {
      ...HomeFeedPostsListItem
    }
  }

  ${HOME_FEED_POSTS_LIST_ITEM_FRAGMENT}
`;

export const HOME_FEED_POST_UPDATE_MUTATION = gql`
  mutation HomeFeedPostUpdate($data: UpdateHomeFeedPostRequestInput!) {
    updateHomeFeedPostRequest(data: $data) {
      success
      verified
    }
  }
`;

export const HOME_FEED_POST_QUERY = gql`
  query HomeFeedSinglePost($id: ID!) {
    homeFeedPost(id: $id) {
      ...HomeFeedPostsListItem
    }
  }
  ${HOME_FEED_POSTS_LIST_ITEM_FRAGMENT}
`;

export const ORGANIZATION_CONTACT_MUTATION = gql`
  mutation OrganizationContact($subject: String!, $text: String!, $userIds: [String!]) {
    contactOrganizationRequest(subject: $subject, text: $text, userIds: $userIds) {
      success
    }
  }
`;

export const JOB_OFFER_UPDATE = gql`
  mutation JOB_OFFER_UPDATE($data: JobOfferUpdateInput!) {
    jobOfferUpdate(data: $data) {
      id
      engagementCount
    }
  }
`;

export const JOB_QUERY = gql`
  query JOB_QUERY($id: ID) {
    jobOffer(id: $id) {
      id
      engagementCount
    }
  }
`;

export const HOME_FEED_JOB_OFFERS_LIST_QUERY = gql`
  query HomeFeedJobOffersList {
    jobOffersList {
      items {
        ...CareerInfo
      }
    }
  }
  ${CAREER_INFO_FRAGMENT}
`;

export const HOME_FEED_USERS_BIRTHDAY_LIST_QUERY = gql`
  query HomeFeedUsersBirthdayList($filter: UserFilter!) {
    usersList(sort: { birthDate: ASC }, filter: $filter) {
      items {
        ...UserListItem
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const HOME_FEED_DISCOUNTS_LIST_QUERY = gql`
  query HomeFeedDiscountsList($dateNow: DateTime!, $first: Int) {
    discountsList(
      filter: {
        status: { equals: "active" }
        startDate: { lte: $dateNow }
        expirationDate: { gte: $dateNow }
      }
      first: $first
    ) {
      items {
        ...DiscountListItem
      }
    }
  }
  ${DISCOUNT_LIST_ITEM_FRAGMENT}
`;

export const HOME_FEED_ITEM_FRAGMENT = gql`
  fragment HomeFeedItem on HomeFeedItem {
    id
    text
    status
    reportStatus
    postType
    type: postType
    mentions
    media
    location
    groupId
    isPinned
    isFuture
    commentsAllowed
    authorId
    authorFirstName
    authorLastName
    authorAvatar
    discount
    amountPercent
    postDate
    startDate
    expirationDate
    legacyDate
    createdAt
    updatedAt
  }
`;

export const HOME_FEED_ITEMS_QUERY = gql`
  query HomeFeedItems(
    $filter: HomeFeedItemFilter
    $first: Int
    $skip: Int
    $sort: [HomeFeedItemSort!]
  ) {
    feed: homeFeedItemsList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      count
      items {
        ...HomeFeedItem
      }
    }
  }

  ${HOME_FEED_ITEM_FRAGMENT}
`;

export const HOME_FEED_ITEMS_COUNT_QUERY = gql`
  query HomeFeedItemsCount($filter: HomeFeedItemFilter) {
    feed: homeFeedItemsList(filter: $filter) {
      count
    }
  }
`;

export const HOME_FEED_SINGLE_ITEM = gql`
  query HomeFeedSingleItem($id: ID!) {
    homeFeedItem(id: $id) {
      updatedAt
      text
      status
      startDate
      reportStatus
      postType
      postDate
      groupId
      legacyDate
      mentions
      discount
      media
      location
      isPinned
      id
      expirationDate
      createdAt
      commentsAllowed
      authorId
      authorFirstName
      authorLastName
      authorAvatar
      amountPercent
    }
  }
`;
