/**
 * Formats an address object into a single string.
 *
 * @param address - The address object to format.
 * @param address.state - The state of the address.
 * @param address.city - The city of the address.
 * @param address.street1 - The primary street address.
 * @param address.street2 - The secondary street address (optional).
 * @param address.zip - The ZIP code of the address.
 * @returns A formatted address string with the components separated by commas.
 */
export function formatAddress(address) {
    const { state, city, street1, street2, zip } = address;
    return [city, state, zip, street1, street2].filter(Boolean).join(', ');
}
