import { useHomeFeedItemsCountQuery } from 'shared/graphql';

export type UseHomeFeedItemsCountOptions = Parameters<typeof useHomeFeedItemsCountQuery>[0];

/**
 * Custom hook to fetch and return the count of home feed items.
 *
 * @param {UseHomeFeedItemsCountOptions} [options] - Optional parameters for the home feed items query.
 */
export function useHomeFeedItemsCount(options?: UseHomeFeedItemsCountOptions) {
  const { data: response, loading } = useHomeFeedItemsCountQuery(options);

  const count = response?.feed.count ?? 0;

  return {
    count,
    loading,
  };
}
