import { cloneElement, Fragment, ReactElement, ReactNode } from 'react';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { css } from '@emotion/react';

import { useResponsive } from 'shared/hooks';
import { LoadingOverlay } from 'shared/components/ui';
import { CardLayout } from 'shared/components/layouts';
import { Theme } from '@material-ui/core';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const headerCSS = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const sectionsContainerCSS = (theme: Theme) => css`
  min-height: 10rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const sectionCSS = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex: 1 1 0;
  padding: 1rem;
`;

const sectionAvatarCSS = (color?: string) => (theme: Theme) =>
  css`
    --section-avatar-size: 3rem;

    width: var(--section-avatar-size);
    height: var(--section-avatar-size);
    background-color: ${color ?? theme.palette.primary.main};
    color: ${theme.palette.getContrastText(color ?? theme.palette.primary.main)};
    margin-top: 0.5rem;
  `;

const sectionHeaderCSS = css`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface CountersType {
  label: string;
  count: string | number;
}

interface Props {
  title: string;
  children: ReactNode;
  icon: ReactElement;
  color?: string;
  loading?: boolean;
  counters: CountersType[];
}

export function DashboardCountStatsItem({
  title,
  children,
  counters,
  loading,
  icon,
  color,
}: Props) {
  const { isMobile } = useResponsive();

  return (
    <Box css={containerCSS}>
      <Box css={headerCSS}>
        <Typography variant="body1" fontWeight="medium" lineHeight={1.5}>
          {title}
        </Typography>

        {children}
      </Box>

      <CardLayout css={sectionsContainerCSS}>
        {counters.map(({ count, label }, index) => (
          <Fragment key={label}>
            <Box css={sectionCSS}>
              <Box css={sectionHeaderCSS}>
                <Avatar alt={label} css={sectionAvatarCSS(color)}>
                  {cloneElement(icon, { fontSize: 'inherit' })}
                </Avatar>

                <LoadingOverlay loading={loading}>
                  <Typography variant="h5" fontWeight="medium" fontSize="50px">
                    {count}
                  </Typography>
                </LoadingOverlay>
              </Box>

              <LoadingOverlay loading={loading}>
                <Typography variant="body2" color="textSecondary">
                  {label}
                </Typography>
              </LoadingOverlay>
            </Box>

            {index < counters.length - 1 && (
              <Divider
                flexItem
                variant="fullWidth"
                orientation={isMobile ? 'horizontal' : 'vertical'}
              />
            )}
          </Fragment>
        ))}
      </CardLayout>
    </Box>
  );
}
