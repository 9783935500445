import { Container, Name } from './AuthorNameLink.styles';

export interface AuthorNameLinkProps {
  className?: string;

  name: string | undefined | null;
  route?: string;
  disabled?: boolean;
}

export function AuthorNameLink(props: AuthorNameLinkProps) {
  const name = props.name ?? '';

  if (props.route && !props.disabled) {
    return (
      <Container to={props.route}>
        <Name className={props.className} title={name}>
          {name}
        </Name>
      </Container>
    );
  }

  return (
    <Name className={props.className} title={name}>
      {name}
    </Name>
  );
}
