import { Fragment, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { Box, MenuItem } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { useLocation } from 'react-router-dom';

import { createInboxSubject } from '@jebel/utils';

import { MESSAGE_TYPE_OPTIONS, INBOX_TYPES } from 'features/inbox/constants';
import {
  Form,
  FormTextField,
  Icon,
  Typography,
  Button,
  FormSelect,
  ResultFile,
  MediaInput,
} from 'shared/components/ui';
import { required } from 'shared/utils/form';
import {
  User,
  useInboxMessageMediaCreateMutation,
  MinimalUserFragment,
  GroupsListItemFragment,
} from 'shared/graphql';
import { InboxCreateFormData, useInboxChatsList } from 'features/inbox/hooks/useInboxChatsList';
import { ResultFileValue } from 'shared/types/files';
import { useCurrentUser, useSchoolConfiguration, useToast } from 'shared/hooks';
import { FormSelectGroup, FormSelectUser } from 'shared/components/form';

import { normalizeMembers } from './normalizeMembers';
import { recordError } from 'shared/utils/record';

const DROP_ZONE_HEIGHT = 150;
const MESSAGE_INPUT_MIN_HEIGHT = 250;

const formCss = theme => css`
  display: grid;
  grid-row-gap: ${theme.spacing(1.25)}px;
`;

const dropZoneContainer = theme => css`
  background-color: ${theme.palette.background.light};
  border: 1px dashed #bbbbbb;
  height: ${DROP_ZONE_HEIGHT}px;
  display: grid;
  grid-template-rows: min-content min-content;
  place-items: center;
  align-content: center;
  cursor: pointer;
`;

const dropZoneIconCss = theme => css`
  font-size: ${theme.palette.fontSize}px;
  fill: ${theme.palette.primary.light};
`;

const dropZoneTextCss = theme => css`
  font-size: ${theme.typography.fontSize + 2}px;
  font-weight: 500;
  color: ${theme.palette.primary.light};
`;

const messageInputCss = theme => css`
  width: 100%;

  .MuiTextField-root {
    width: 100%;
  }
  & textarea {
    min-height: ${MESSAGE_INPUT_MIN_HEIGHT}px;
  }
`;

const footerButtonsCss = theme => css`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-column-gap: ${theme.spacing(2.25)}px;
  padding: ${theme.spacing(3)}px 0 0 0;
`;

const cancelButtonCss = theme => css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  color: ${theme.palette.text.secondary};
`;

type FormData = {
  subject?: string;
  text?: string;
  messageType?: string;
  members?: MinimalUserFragment[] | GroupsListItemFragment[];
  media?: ResultFile[];
};

export const InboxCreateMessageModal = ({
  onModalClose,
  members,
  messageType,
}: {
  onModalClose: () => void;
  members: User[];
  messageType: string;
}) => {
  const { userId } = useCurrentUser();
  const { pathname } = useLocation();
  const { onCreateInbox } = useInboxChatsList();
  const { showError } = useToast();
  const { configuration: school } = useSchoolConfiguration();

  const isAdminRoute = useMemo(() => {
    return pathname.startsWith('/admin');
  }, [pathname]);

  const [onCreateInboxMedia] = useInboxMessageMediaCreateMutation();

  const onChangeMessageType = resetForm => () => {
    resetForm();
  };

  const onCreateMessage = async (form: FormData) => {
    const users = normalizeMembers(form.members);

    const userIdentifiers = users
      .map(user => user.id)
      // Remove the current user from the members to be added later
      .filter(curr => curr !== userId)
      .concat([userId]);

    if (users.length === 0) {
      return;
    }

    const payload: InboxCreateFormData = {
      subject: form.subject ?? '',
      text: form?.text ?? '',
      type: form.messageType,
      userIds: userIdentifiers as string[],
      destinationGroups: null,
    };

    if (isAdminRoute) {
      payload.subject = createInboxSubject({
        content: form?.subject as string,
        isFromSchool: true,
        school: {
          id: school?.id,
          name: school?.name,
        },
      });
    }

    if (form.messageType === INBOX_TYPES.personal && users.length > 1) {
      payload.type = INBOX_TYPES.multiPersonal;
    }

    if (form.messageType === INBOX_TYPES.group) {
      const groups = form.members as GroupsListItemFragment[];
      payload.destinationGroups = groups.map(group => ({ id: group.id, name: group.title }));
    }

    if (form?.media?.length !== 0) {
      const response = await onCreateInboxMedia({
        variables: {
          data: {
            media: {
              create: form?.media?.map(file => ({
                fileId: file.fileId,
                filename: file.filename,
              })),
            },
          },
        },
      });

      const mediaId = response.data?.inboxMessageMediaCreate.id;

      if (mediaId) {
        payload.media = mediaId;
      }
    }

    try {
      await onCreateInbox(payload);
      onModalClose();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const onChangeMedia = useCallback(
    (setFieldValue: FormikHelpers<FormData>['setFieldValue']) =>
      (messageMedia: ResultFileValue | null) => {
        if (Array.isArray(messageMedia)) {
          setFieldValue('media', messageMedia);
        }
      },
    [],
  );

  return (
    <Form
      oldCss={formCss}
      initialValues={{ members, messageType, images: [] }}
      onSubmit={onCreateMessage}
    >
      {({ isSubmitting, values, resetForm, setFieldValue }) => (
        <Fragment>
          <FormSelect
            selectProps={{
              children: MESSAGE_TYPE_OPTIONS.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              )),
              variant: 'outlined',
              label: 'Message type',
            }}
            fieldProps={{
              name: 'messageType',
              onChange(event) {
                resetForm();
                setFieldValue('messageType', event.target.value);
              },
            }}
          />

          {values.messageType === 'personal' && (
            <FormSelectUser
              name="members"
              label="Members"
              placeholder="Members"
              variant="outlined"
              validate={required}
              showSuggestions={false}
              allowUnknown="none"
              onlyActive
              multiple
            />
          )}

          {values.messageType === 'group' && (
            <FormSelectGroup
              name="members"
              label="Groups"
              placeholder="Groups"
              variant="outlined"
              validate={required}
              showSuggestions={false}
              allowUnknown="none"
              onlyActive
              onlyMember
              multiple
            />
          )}

          <FormTextField
            inputProps={{
              color: 'primary',
              label: 'Subject *',
              variant: 'outlined',
            }}
            fieldProps={{ name: 'subject', validate: required }}
          />

          <Box css={messageInputCss}>
            <FormTextField
              inputProps={{
                color: 'primary',
                label: 'Message *',
                variant: 'outlined',
                multiline: true,
              }}
              fieldProps={{ name: 'text', validate: required }}
            />
          </Box>
          <MediaInput maxFiles={5} onChange={onChangeMedia(setFieldValue)}>
            <Box css={dropZoneContainer}>
              <Icon css={dropZoneIconCss} name="CloudUpload" variant="filled" />
              <Typography css={dropZoneTextCss} variant="inherit">
                Upload media
              </Typography>
            </Box>
          </MediaInput>

          <Box css={footerButtonsCss}>
            <Button variant="text" css={cancelButtonCss} onClick={onModalClose}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit" loading={isSubmitting}>
              Send message
            </Button>
          </Box>
        </Fragment>
      )}
    </Form>
  );
};
