import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { alpha } from '@mui/material';

import { Icon } from 'shared/components/ui';

// Container

interface ContainerProps {
  visible?: boolean;
}

export const Container = styled.nav<ContainerProps>(p => {
  const selectedColor = p.theme.palette.primary.sideFont;
  const selectedBackground = p.theme.palette.primary.side;

  const color = selectedColor ?? p.theme.palette.getContrastText(selectedBackground);
  const background = selectedBackground ?? p.theme.palette.primary.main;

  return css`
    width: 100%;
    // fallback on browser that does not support DVH.
    max-height: calc(100vh - 4rem);
    // Use the dynamic viewport height to get the maximum of the viewport
    // https://developer.mozilla.org/en-US/docs/Web/CSS/length#relative_length_units_based_on_viewport
    max-height: calc(100dvh - 4rem);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: ${background};
    color: ${color};
    z-index: 8; // lower than bottom navigation
    padding: 2rem;
    gap: 2rem;

    ${!p.visible &&
    css`
      display: none;
    `}
  `;
});

// NavList

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  pointer-events: initial;
  list-style: none;
  gap: 0.5rem;
`;

// BaseNavItem

interface BasicNavItemProps {
  isSelected?: boolean;
}

const BasicNavItem = (props: BasicNavItemProps & { theme: Theme }) => {
  const color = props.theme.palette.primary.sideFont;
  const selectedColor = props.theme.palette.primary.light;

  const hoverBackground = alpha(color, 0.05);
  const selectedBackground = alpha(selectedColor, 0.1);
  const selectedHoverBackground = alpha(selectedColor, 0.2);

  return css`
    --sidebar-color: ${color};
    --sidebar-background: transparent;
    --sidebar-hover-background: ${hoverBackground};

    display: flex;
    align-items: center;
    border-radius: 0.3rem;
    padding: 0 0.8rem;
    height: 3rem;
    gap: 0.7rem;
    user-select: none;
    cursor: pointer;
    color: var(--sidebar-color);
    background-color: var(--sidebar-background);
    text-decoration: none;

    &:hover {
      background-color: var(--sidebar-hover-background);
    }

    ${props.isSelected &&
    css`
      --sidebar-color: ${selectedColor};
      --sidebar-background: ${selectedBackground};
      --sidebar-hover-background: ${selectedHoverBackground};
    `}
  `;
};

// NavItem

export const NavItem = styled.li<BasicNavItemProps>`
  ${BasicNavItem}
`;

// ExternalLinkItem

export const ExternalLinkItem = styled.a<BasicNavItemProps>`
  ${BasicNavItem}
`;

// NavItemText

export const NavItemText = styled.span`
  font-size: 0.9rem;
`;

// NavItemIcon

export const NavItemIcon = styled(Icon)`
  color: inherit;
  font-size: 1.3rem;
`;
