import { Button, ButtonProps } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';

import {
  OrganizationItemFragment,
  OrganizationLikeKeyFilter,
  useOrganizationCreateLikeMutation,
  useOrganizationDeleteLikeMutation,
} from 'shared/graphql';
import { useCurrentUser, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

export interface OrganizationLikeButtonProps extends Omit<ButtonProps, 'color' | 'onClick'> {
  organization: OrganizationItemFragment;
}

/**
 * Component for rendering a like button for an organization.
 *
 * @example
 * // Usage example:
 * <OrganizationLikeButton organization={organization} />
 *
 * @remarks
 * This component allows users to like or unlike an organization. It handles the state
 * of the user's like and synchronizes it with the backend using GraphQL mutations.
 */
export function OrganizationLikeButton({ organization, ...props }: OrganizationLikeButtonProps) {
  const [hasUserLike, setHasUserLike] = useState(false);
  const [userLike, setUserLike] = useState<OrganizationLikeKeyFilter>();

  const { user } = useCurrentUser();
  const { showError } = useToast();

  const organizationLikes = organization.likesCount ?? 0;
  const userLikes = organization.userLikes?.items ?? [];

  const [createLikeMutation] = useOrganizationCreateLikeMutation();
  const [removeLikeMutation] = useOrganizationDeleteLikeMutation();

  useEffect(() => {
    const userLike = userLikes.find(curr => curr.user?.id === user?.id);

    if (userLike) {
      setUserLike(userLike);
      setHasUserLike(true);
    }
  }, [userLikes, user?.id]);

  const likesCount = useMemo(() => {
    return hasUserLike ? organizationLikes + 1 : organizationLikes;
  }, [organizationLikes, hasUserLike]);

  /** Allow to remove the like when there is one selected. */
  const removeLike = async () => {
    if (!userLike?.id) {
      return;
    }

    await removeLikeMutation({ variables: { likeId: userLike.id } });

    setUserLike(undefined);
  };

  /** Allow to create the like when there is no one. */
  const createLike = async () => {
    if (!organization?.id || !user?.id) {
      return;
    }

    const response = await createLikeMutation({
      variables: {
        organizationId: organization.id,
        userId: user.id,
      },
    });

    const like = response.data?.organizationLikeCreate;

    if (like) {
      setUserLike(like);
    }
  };

  /** Create or remove the like in the database. */
  const synchronizeUserLike = async (hasUserLike: boolean) => {
    try {
      if (hasUserLike === false) {
        await removeLike();
        return;
      }

      await createLike();
    } catch (err) {
      recordError(err);
      setHasUserLike(!hasUserLike);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const toggleLike = () => {
    setHasUserLike(prev => {
      const curr = !prev;

      synchronizeUserLike(curr);
      return curr;
    });
  };

  return (
    <Button
      {...props}
      startIcon={<ThumbUp />}
      color={hasUserLike ? 'secondary' : 'primary'}
      onClick={toggleLike}
    >
      {likesCount}
    </Button>
  );
}
