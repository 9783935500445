import { useCallback } from 'react';
import { css, Theme } from '@emotion/react';
import { Box } from '@material-ui/core';

import { Button, AsyncContent, Typography } from 'shared/components/ui';
import { HomeFeedCard } from 'shared/features/posts';
import { HomeFeedItemFragment } from 'shared/graphql';
import { useStreamChatReactions } from 'shared/hooks/useStreamChatActivity';
import { useSchoolNewsFeed } from 'features/news/hooks';
import { PaperLayout } from 'shared/components/layouts';

const BOTTOM_BUTTONS_PADDING = 20;

const commonButtonsCSS = css`
  padding: ${BOTTOM_BUTTONS_PADDING / 4}px ${BOTTOM_BUTTONS_PADDING}px;
  margin-left: ${BOTTOM_BUTTONS_PADDING}px;
`;

const cancelButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS};
  color: ${theme.palette.text.secondary};
`;

const submitButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS};
  background-color: ${theme.palette.error.light};
  color: #ffffff;
`;

const deleteCss = (theme: Theme) => css`
  color: ${theme.palette.error.light};
  font-weight: 600;
`;

type ConfirmDeleteSchoolNewsPostModalProps = {
  onModalClose: () => void;
  onDeletePost: (id: string) => void;
  loading: boolean;
  id: string | undefined;
};

export const ConfirmDeleteSchoolNewsPostModal = ({
  onModalClose,
  onDeletePost,
  loading,
  id,
}: ConfirmDeleteSchoolNewsPostModalProps) => {
  const { data: posts, loading: postsLoading } = useSchoolNewsFeed();
  const { onCommentAdd, onLikeAdd, onReactionRemove, onCommentRemove } = useStreamChatReactions();

  const renderItem = useCallback(
    (data: HomeFeedItemFragment) => {
      if (!id) {
        return null;
      }

      return <HomeFeedCard data={data} hideComments hideLikes hideOptions />;
    },
    [onCommentAdd, onCommentRemove, onLikeAdd, onReactionRemove],
  );

  const onDeletePostClick = () => {
    if (!id) {
      return;
    }

    onDeletePost(id);
    onModalClose();
  };

  return (
    <AsyncContent loading={loading || postsLoading}>
      <Box display="flex" flexDirection="column" justifyContent="start">
        <PaperLayout>{posts.map(renderItem)}</PaperLayout>

        <Box my={1} mx={2}>
          <Typography variant="subtitle5" css={deleteCss}>
            Are you sure you want to delete this post?
          </Typography>
          <Typography variant="body1" color="textSecondary">
            This post will be removed immediately from the platform. You can undo this action from
            the Content Curation menu.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="end">
          <Button css={cancelButtonCSS} disabled={loading} onClick={onModalClose} size="medium">
            GO BACK
          </Button>
          <Button
            css={submitButtonCSS}
            loading={loading}
            disableElevation
            variant="contained"
            onClick={onDeletePostClick}
          >
            YES, DELETE POST
          </Button>
        </Box>
      </Box>
    </AsyncContent>
  );
};
