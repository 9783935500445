import React, { useMemo } from 'react';

import { createFilterBuilder, humanizeText, sanitizeHomeFeedItemText } from '@jebel/utils';

import {
  ContentEngagementReportQuery,
  ContentEngagementReportQueryVariables,
  ContentReportFilter,
} from 'shared/graphql';
import { useSpreadsheetSearch } from 'shared/features/search';
import { Spreadsheet, useSpreadsheetContext } from 'shared/features/spreadsheet';
import { formatTableDate } from 'shared/utils/date';
import { useDownloadLazyQueryCSV, useDownloadLazyQueryPDF } from 'shared/hooks';
import { getFileNameWithTimestamp } from 'shared/utils/file';
import { SELECT_OPTION_ALL } from 'shared/constants';

import { ContentReportHeaders, ContentReportsHeadlines } from '../constants';
import { CONTENT_ENGAGEMENT_REPORT_QUERY } from '../queries';
import { LocalFilterPostCategory } from '../types';

interface Props {
  localFilter: ContentReportFilter;
  toolbarHeader?: JSX.Element;
  textToolbarHeader?: string;
  selectedCategory?: LocalFilterPostCategory;
}

export const ContentReportsSpreadsheet: React.FC<Props> = ({
  localFilter,
  toolbarHeader,
  textToolbarHeader,
  selectedCategory,
}) => {
  const { selected, queryParams } = useSpreadsheetContext();

  const filter = useMemo(() => {
    const filter = createFilterBuilder(localFilter);

    if (queryParams.filter) {
      filter.and(queryParams.filter);
    }

    if (selectedCategory && selectedCategory !== SELECT_OPTION_ALL) {
      filter.and({ category: { equals: selectedCategory } });
    }

    if (localFilter) {
      filter.and(localFilter);
    }

    return filter.build();
  }, [localFilter, queryParams.filter, selectedCategory]);

  const { tableData, tableLoading, queryVariables } =
    useSpreadsheetSearch<ContentEngagementReportQuery>({
      query: CONTENT_ENGAGEMENT_REPORT_QUERY,
      searchingFields: ['text', 'category', 'authorName'],
      queryVariables: { ...queryParams, filter },
    });

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<ContentReportFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [downloadPDF] = useDownloadLazyQueryPDF<
    ContentEngagementReportQuery,
    ContentEngagementReportQueryVariables
  >(CONTENT_ENGAGEMENT_REPORT_QUERY, {
    displayName: 'Content Engagement Report',

    filename: getFileNameWithTimestamp('ContentEngagement.pdf'),
    transform: transformExport,

    variables: { filter: downloadFilter },
  });

  const [downloadCSV] = useDownloadLazyQueryCSV<
    ContentEngagementReportQuery,
    ContentEngagementReportQueryVariables
  >(CONTENT_ENGAGEMENT_REPORT_QUERY, {
    displayName: 'Content Engagement Report',

    filename: getFileNameWithTimestamp('ContentEngagement.csv'),
    transform: transformExport,

    variables: { filter: downloadFilter },
  });

  const reportData = React.useMemo(() => {
    return transformExport(tableData);
  }, [tableData]);

  return (
    <Spreadsheet
      loading={tableLoading}
      headlines={ContentReportsHeadlines}
      data={reportData}
      itemsCount={tableData?.report?.count ?? 0}
      toolbarOptions={{
        withDownload: true,
        withPDFDownload: true,
        withPerPage: true,
        downloadHandler: downloadCSV,
        downloadPDFHandler: downloadPDF,
        rawData: reportData,
      }}
      toolbarHeader={toolbarHeader}
      textToolbarHeader={textToolbarHeader}
    />
  );
};

function transformExport(response: ContentEngagementReportQuery | undefined) {
  const posts = response?.report.items ?? [];

  return posts.map(post => {
    return {
      [ContentReportHeaders.id]: post.id as string,
      [ContentReportHeaders.text]: sanitizeHomeFeedItemText(post.text ?? ''),
      [ContentReportHeaders.category]: humanizeText(post.category as string),
      [ContentReportHeaders.postDate]: formatTableDate(post.createdAt),
      [ContentReportHeaders.author]: post.authorName,
      [ContentReportHeaders.isSupporter]: post.isSupporter ? 'Yes' : 'No',
      [ContentReportHeaders.commentsCount]: post.commentsCount,
      [ContentReportHeaders.likesCount]: post.likesCount,
      [ContentReportHeaders.reactionsCount]: post.reactionsCount,
    };
  });
}
