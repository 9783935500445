import { Fragment } from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface SpreadsheetPreloaderProps {
  columnsCount: number;
}

const ROWS_COUNT = 15;
const ROWS = Array.from({ length: ROWS_COUNT }, (_, index) => index);

export function SpreadsheetPreloader(props: SpreadsheetPreloaderProps) {
  return (
    <Fragment>
      {ROWS.map(index => (
        <SpreadsheetPreloaderRow key={index} {...props} />
      ))}
    </Fragment>
  );
}

function SpreadsheetPreloaderRow({ columnsCount }: SpreadsheetPreloaderProps) {
  return (
    <TableRow>
      {Array.from({ length: columnsCount }, (_, index) => (
        <TableCell key={index}>
          <Skeleton width="90%" style={{ minWidth: '2rem' }} />
        </TableCell>
      ))}
    </TableRow>
  );
}
