import React, { useCallback } from 'react';
import {
  Box,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  CardMedia,
} from '@material-ui/core';
import type { PickerOptions } from 'filestack-js';
import { FileInputProps, FileInputValue, OriginalFileInputValue } from '@8base-react/file-input';
import _ from 'lodash';
import { Close as IconClose } from '@mui/icons-material';
import { css } from '@emotion/react';
import pluralize from 'pluralize';

import { fileSizeToHuman, isVideoFile } from '@jebel/utils';

import { ONE_MEGABYTE } from 'shared/constants/files';
import { ResultFile, ResultFileValue } from 'shared/types/files';

import { FileInputWrap as FileInput } from '../FileInputWrap';

interface VideoInputProps {
  label?: string;
  pickerOptions?: PickerOptions;
  fileInputProps?: FileInputProps;
  initialValue?: ResultFileValue;
  showPreview?: boolean;
  showDescription?: boolean;
  descriptionPlacement?: 'top' | 'bottom';
  maxFiles?: number;
  onChange: (data: ResultFileValue | null) => void;
}

const MAX_FILES_SIZE = ONE_MEGABYTE * 50;

export const MediaInput: React.FC<VideoInputProps> = ({
  children,
  label,
  pickerOptions,
  onChange,
  fileInputProps,
  initialValue,
  descriptionPlacement = 'bottom',
  showDescription = true,
  showPreview = true,
  maxFiles = 6,
}) => {
  const [value, setValue] = React.useState<ResultFileValue | null>(initialValue || null);

  const handleChange = React.useCallback(
    (newValue: ResultFileValue | null) => {
      setValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  const addFiles = useCallback(
    (result: ResultFileValue) => {
      const files: ResultFile[] = [];
      if (Array.isArray(value)) {
        files.push(...value);
      }

      if (value && !Array.isArray(value)) {
        files.push(value);
      }

      if (Array.isArray(result)) {
        files.push(...result);
      }

      if (result && !Array.isArray(result)) {
        files.push(result);
      }

      if (maxFiles === 1 && files.length === 2) {
        const [, last] = files;
        // Select the last element to replace the value one
        handleChange(last);
        return;
      }

      handleChange(files);
    },
    [handleChange, maxFiles, value],
  );

  const pickerContent = React.useMemo(() => {
    return (
      <React.Fragment>
        {children}
        {label && (
          <Typography variant="body1" color="secondary">
            {label}
          </Typography>
        )}
      </React.Fragment>
    );
  }, [children, label]);

  const onPickFile = React.useCallback(
    (pick: (options: PickerOptions) => void) => {
      // picker spamming his script into DOM on every pick
      document.getElementById('fs-loader-picker')?.remove();

      pick({
        fromSources: ['local_file_system'],
        maxSize: MAX_FILES_SIZE,
        accept: ['image/*', 'video/*'],
        // Prevent to upload images with a resolution higher than 1080p
        // https://github.com/jebelapp/jebel/issues/1639#issuecomment-2450783301
        imageMax: [2160, 1080],

        ...pickerOptions,
      });
    },
    [pickerOptions],
  );

  const onUploadDone = async (
    file: FileInputValue,
    originalFile?: OriginalFileInputValue | undefined,
  ): Promise<FileInputValue> => {
    if (!_.isNil(originalFile)) {
      if (_.isArray(file) && _.isArray(originalFile) && file.length === originalFile.length) {
        const files = file as ResultFile[];

        addFiles(
          files.map((data, index) => ({
            ...data,
            size: originalFile[index].size,
          })),
        );
      }

      if (!_.isArray(file) && !_.isArray(originalFile)) {
        addFiles({ ...file, size: originalFile.size } as ResultFile);
      }
    } else {
      addFiles(file as ResultFileValue);
    }

    return file;
  };

  const onRemoveFile = React.useCallback(
    (id: string) => {
      const newValue = _.isArray(value) ? value.filter(file => file.fileId !== id) : null;
      handleChange(newValue);
    },
    [value, handleChange],
  );

  const preview = React.useMemo(() => {
    if (showPreview) {
      if (!_.isNil(value)) {
        if (_.isArray(value)) {
          return (
            <React.Fragment>
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Uploaded {value.length} {pluralize('file', value.length)} out of {maxFiles}
                </Typography>
              </Box>

              <List>
                {value.map(file =>
                  renderFilePreview(file, onRemoveFile, !!showDescription, Boolean(file?.size)),
                )}
              </List>
            </React.Fragment>
          );
        }
        return renderFilePreview(value, onRemoveFile, !!showDescription, Boolean(value?.size));
      }
    }

    return null;
  }, [value, maxFiles, onRemoveFile, showDescription, showPreview]);

  const filesLeft = React.useMemo(() => {
    return maxFiles - (_.isArray(value) ? value.length : 0);
  }, [maxFiles, value]);

  return (
    <Box>
      {descriptionPlacement === 'top' ? preview : null}
      <Box>
        <FileInput {...(fileInputProps || {})} maxFiles={filesLeft} onUploadDone={onUploadDone}>
          {({ pick }) => <Box onClick={() => onPickFile(pick)}>{pickerContent}</Box>}
        </FileInput>
      </Box>
      {descriptionPlacement === 'bottom' ? preview : null}
    </Box>
  );
};

const previewImageContainerCSS = css`
  width: 3rem;
  height: 3rem;
`;

const renderFilePreview = (
  preview: ResultFile,
  onDelete: (fileId: string) => void,
  showDescription: boolean,
  showFileSize = false,
) => {
  const filename = preview.filename ?? '(File)';
  const fileSize = fileSizeToHuman(preview.size, '(Unknown)');

  return (
    <ListItem key={preview.fileId}>
      <ListItemAvatar>
        {isVideoFile(filename) ? (
          <CardMedia
            component="video"
            css={previewImageContainerCSS}
            src={preview.downloadUrl as string}
          />
        ) : (
          <Avatar
            variant="rounded"
            src={preview.downloadUrl as string}
            alt={filename}
            css={previewImageContainerCSS}
          />
        )}
      </ListItemAvatar>

      <ListItemText
        primary={showDescription ? filename : null}
        secondary={showFileSize ? fileSize : null}
      />

      <IconButton
        size="small"
        aria-label="Close"
        aria-roledescription="Button"
        onClick={() => onDelete(preview.fileId as string)}
      >
        <IconClose />
      </IconButton>
    </ListItem>
  );
};
