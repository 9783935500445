import { useCallback, useMemo } from 'react';
import { css, Theme } from '@emotion/react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Forum } from '@material-ui/icons';

import { OrganizationStatus } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { ChooseStatusChipOption, Icon, Typography } from 'shared/components/ui';
import { APP_URL, DISCOUNTS_OPTIONS } from 'shared/constants';
import { useSpreadsheetSearch } from 'shared/features/search';
import {
  Spreadsheet,
  useSpreadsheetContext,
  SpreadsheetCellActions,
} from 'shared/features/spreadsheet';
import {
  BusinessRegistryListQuery,
  BusinessRegistryListQueryVariables,
  MinimalUserFragment,
  OrganizationFilter,
  OrganizationUpdateInput,
} from 'shared/graphql';
import { buildUrl } from 'shared/routes';
import { getBusinessAddressShort } from 'shared/utils/address';
import { formatTableDate } from 'shared/utils/date';
import { useInboxContext } from 'providers/InboxProvider';
import { useDownloadLazyQueryCSV, useToast } from 'shared/hooks';
import { formatUserName } from 'shared/utils/user';
import { getFileNameWithTimestamp } from 'shared/utils/file';
import { BUSINESS_REGISTRY_LIST_QUERY } from 'features/organizations/queries';
import { useUpdateOrganization } from 'features/organizations/hooks';
import { sendToSentry } from 'shared/utils/sentry';

import {
  BusinessSpreadsheetHeadlines,
  BusinessSpreadsheetFilters,
  BusinessReportHeaders,
} from '../../constants';
import { ChooseOrganizationStatusChip } from '../ChooseOrganizationStatusChip';
import { OrganizationAvatar } from 'shared/components/symbols';

const ORGANIZATION_AVATAR_SIZE = 30;

const organizationNameCss = css`
  font-weight: 500;
  display: flex;
  justify-content: center;
`;

const organizationImageCSS = (theme: Theme) => css`
  width: ${ORGANIZATION_AVATAR_SIZE}px;
  height: ${ORGANIZATION_AVATAR_SIZE}px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.palette.divider};
`;

const advertisingStatusCss = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  height: 20px;
`;

export const advertisingActiveCss = (theme: Theme) => css`
  ${advertisingStatusCss(theme)};
  background-color: ${theme.palette.success.main};
`;

export const advertisingInvitationSendCss = (theme: Theme) => css`
  ${advertisingStatusCss(theme)};
  background-color: ${theme.palette.warning.dark};
`;

export const advertisingPendingApprovalCss = (theme: Theme) => css`
  ${advertisingStatusCss(theme)};
  background-color: ${theme.palette.info.dark};
`;

export const advertisingInactiveCss = (theme: Theme) => css`
  ${advertisingStatusCss(theme)};
  background-color: ${theme.palette.error.main};
`;

type OrganizationLocation = BusinessRegistryListQuery['locations']['items'][number];
type Organization = OrganizationLocation['organization'];

export function BusinessesList() {
  const { push: navigate } = useHistory();

  const { queryParams, selected } = useSpreadsheetContext();
  const { onOpenInboxModal } = useInboxContext();
  const { showError, showSuccess, showMessage } = useToast();

  const { organizationUpdate: onUpdate } = useUpdateOrganization({
    awaitRefetchQueries: true,
    refetchQueries: ['BusinessRegistryList'],
  });

  const { tableData, tableLoading, queryVariables } =
    useSpreadsheetSearch<BusinessRegistryListQuery>({
      query: BUSINESS_REGISTRY_LIST_QUERY,
      queryVariables: queryParams,
      searchingFields: [
        'locationName',
        'organization.name',
        'organization.status',
        'organization.industry',
        'organization.createdBy.fullName',
      ],
    });

  const businessesListCount = tableData?.locations.count ?? 0;

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<OrganizationFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [downloadCSV] = useDownloadLazyQueryCSV<
    BusinessRegistryListQuery,
    BusinessRegistryListQueryVariables
  >(BUSINESS_REGISTRY_LIST_QUERY, {
    filename: getFileNameWithTimestamp('Business.csv'),
    transform: transformExport,

    variables: {
      filter: downloadFilter,
    },
  });

  const onActivate = async (organization: Organization) => {
    if (!organization?.id) {
      return;
    }

    const data: OrganizationUpdateInput = {
      status: OrganizationStatus.Active,
      id: organization.id,
    };

    try {
      await onUpdate(data, { context: {} });
      showSuccess('The organization has been approved');
    } catch (err) {
      sendToSentry(err);
      showMessage('Something went wrong updating the organization status, please try again');

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const onReject = async (organization: Organization) => {
    if (!organization?.id) {
      return;
    }

    const data: OrganizationUpdateInput = {
      status: OrganizationStatus.Inactive,
      id: organization.id,
    };

    try {
      await onUpdate(data, { context: {} });
      showSuccess('The organization has been rejected');
    } catch (err) {
      sendToSentry(err);
      showMessage('Something went wrong updating the organization status, please try again');

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const switchChip = useCallback(
    (organization: Organization) => {
      const options: ChooseStatusChipOption[] = [];

      if (!organization?.id) {
        return;
      }

      if (organization.status === OrganizationStatus.PendingApproval) {
        options.push({
          value: OrganizationStatus.Active,
          label: 'Approve',
          async onClick() {
            await onActivate(organization);
          },
        });

        options.push({
          value: OrganizationStatus.Inactive,
          label: 'Reject',
          async onClick() {
            await onReject(organization);
          },
        });
      }

      if (organization.status === OrganizationStatus.Inactive) {
        options.push({
          value: OrganizationStatus.Active,
          label: 'Activate',
          async onClick() {
            await onActivate(organization);
          },
        });
      }

      if (organization.status === OrganizationStatus.Active) {
        options.push({
          value: OrganizationStatus.Inactive,
          label: 'Inactivate',
          async onClick() {
            await onReject(organization);
          },
        });
      }

      return (
        <ChooseOrganizationStatusChip status={organization?.status as string} options={options} />
      );
    },
    [onActivate, onReject],
  );

  const businessesListData = useMemo(() => {
    const organizations = tableData?.locations?.items ?? [];

    return organizations.map(location => {
      const organization = location.organization;
      const countSchoolSupporter = organization?.schoolSupporter?.count ?? 0;
      const industry = DISCOUNTS_OPTIONS.find(({ value }) => value === organization?.industry);

      const [address] = location?.address?.items ?? [];

      const completeLocation = getBusinessAddressShort(address);

      return {
        id: location.id as string,
        name: (
          <Box display="flex" alignItems="center" justifyContent="left">
            <OrganizationAvatar
              css={organizationImageCSS}
              organization={organization}
              variant="square"
            />

            <Box ml={1}>
              <Typography variant="body1" color="primary" css={organizationNameCss}>
                {organization?.name}
              </Typography>
            </Box>
          </Box>
        ),
        locationName: location?.name ?? '(Unnamed)',
        location: completeLocation,
        industry: industry?.label ?? '',
        createdBy: formatUserName(organization?.createdBy),
        createdAt: formatTableDate(location?.createdAt),
        supporter: countSchoolSupporter ? `Yes` : `No`,
        status: switchChip(organization),
      };
    });
  }, [switchChip, tableData]);

  const onViewBusinessDetails = useCallback(
    (id: string) => {
      const locations = tableData?.locations?.items ?? [];

      const location = locations.find(business => business.id === id);
      const organization = location?.organization;

      if (!location?.id || !organization?.id) {
        return;
      }

      const url = buildUrl(APP_URL.admin.business.organizationDetails, {
        pathParams: { id: organization.id },
      });

      navigate(url);
    },
    [navigate, tableData],
  );

  const onEditBusiness = useCallback(
    (id: string) => {
      const locations = tableData?.locations?.items ?? [];

      const location = locations.find(business => business.id === id);
      const organization = location?.organization;

      if (!location?.id || !organization?.id) {
        return;
      }

      const url = buildUrl(APP_URL.admin.business.details, { pathParams: { id: organization.id } });
      navigate(url);
    },
    [navigate, tableData],
  );

  const eventsListTableActions = useMemo(
    (): SpreadsheetCellActions => [
      {
        id: 'Edit Business',
        title: 'Edit Business',
        icon: <Icon name="Edit" />,
        onClickAction: onEditBusiness,
      },
      {
        id: 'View Business Details',
        title: 'View Business Details',
        icon: <Icon name="Info" />,
        onClickAction: onViewBusinessDetails,
      },
    ],
    [onEditBusiness, onViewBusinessDetails],
  );

  const handleMessage = useCallback(() => {
    const locations = tableData?.locations?.items ?? [];

    const receivers: MinimalUserFragment[] = [];
    const receiversIDs = new Set<string>();

    for (const location of locations) {
      const organization = location.organization;
      const isSelected = selected.includes(location?.id as string);

      if (!organization || !isSelected) {
        continue;
      }

      const managers = organization.managers?.items ?? [];

      for (const manager of managers) {
        const isActive = manager.status === 'active';

        if (!isActive || !manager.user) {
          continue;
        }

        const userID = manager.user?.id as string;
        const isRegistered = receiversIDs.has(userID);

        if (isRegistered) {
          continue;
        }

        receiversIDs.add(userID);
        receivers.push(manager.user);
      }
    }

    if (!onOpenInboxModal) {
      return;
    }

    onOpenInboxModal({
      isOpen: true,
      options: {
        members: receivers,
        messageType: 'personal',
      },
    });
  }, [selected, tableData, onOpenInboxModal]);

  const mainToolbarAction = useMemo(
    () => ({
      icon: <Forum />,
      label: 'Message',
      onClick: handleMessage,
    }),
    [handleMessage],
  );

  return (
    <Spreadsheet
      loading={tableLoading}
      headlines={BusinessSpreadsheetHeadlines}
      cellActions={eventsListTableActions}
      data={businessesListData}
      itemsCount={businessesListCount ?? 0}
      toolbarOptions={{
        filters: BusinessSpreadsheetFilters,
        withPerPage: true,
        withDownload: true,
        downloadHandler: downloadCSV,
        rawData: tableData?.locations?.items ?? [],
        mainToolbarAction,
      }}
    />
  );
}

function transformExport(response: BusinessRegistryListQuery) {
  const organizations = response.locations.items ?? [];

  return organizations.map(location => {
    const [address] = location.address?.items ?? [];

    const business = location.organization;
    const countSchoolSupporter = business?.schoolSupporter?.count ?? 0;
    const industry = DISCOUNTS_OPTIONS.find(({ value }) => value === business?.industry);

    const completeLocation = getBusinessAddressShort(address);

    return {
      [BusinessReportHeaders.name]: business?.name ?? '',
      [BusinessReportHeaders.locationName]: location?.name ?? '',
      [BusinessReportHeaders.location]: completeLocation,
      [BusinessReportHeaders.industry]: industry?.value,
      [BusinessReportHeaders.createdBy]: formatUserName(business?.createdBy),
      [BusinessReportHeaders.createdOn]: formatTableDate(business?.createdAt),
      [BusinessReportHeaders.supporter]: countSchoolSupporter ? `Yes` : `No`,
      [BusinessReportHeaders.status]: business?.status,
    };
  });
}
