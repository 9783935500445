import { DiscountKeyFilter, useRedeemDiscountMutation } from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';

/**
 * Custom hook to redeem a discount.
 *
 * This hook provides a function to redeem a discount by its ID and a loading state.
 */
export function useRedeemDiscount() {
  const [execute, { loading }] = useRedeemDiscountMutation();

  const { data: school } = useSchoolConfiguration();

  const mutate = async (discount: DiscountKeyFilter) => {
    if (!discount.id || !school?.id) {
      return;
    }

    await execute({
      variables: {
        discount: { id: discount.id },
        school: { id: school.id },
      },
    });
  };

  return {
    mutate,
    loading,
  };
}
