import { useCallback } from 'react';
import { Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import { Popover, PopoverRenderParams } from 'shared/components/ui';
import {
  SpreadsheetBulkAction,
  SpreadsheetToolbarOptions,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';

interface Props {
  toolbarOptions?: SpreadsheetToolbarOptions;
}

export function SpreadsheetToolbarBulkActions(props: Props) {
  const { selected, loading } = useSpreadsheetContext();

  const wrapClickAction = useCallback(
    (action: SpreadsheetBulkAction, popover: PopoverRenderParams) => () => {
      action.onClick(selected);
      popover.close();
    },
    [selected],
  );

  if (!props.toolbarOptions?.bulkActions) {
    return null;
  }

  return (
    <Popover
      disabled={loading}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      target={
        <Button
          size="small"
          variant="outlined"
          disabled={loading}
          endIcon={<KeyboardArrowDown />}
          disableElevation
        >
          Actions
        </Button>
      }
    >
      {popover => (
        <List>
          {props.toolbarOptions?.bulkActions?.map(action => (
            <ListItem
              dense
              button
              disabled={action.disabled}
              onClick={wrapClickAction(action, popover)}
            >
              <ListItemIcon>{action.icon}</ListItemIcon>
              <ListItemText primary={action.label} secondary={action.description} />
            </ListItem>
          ))}
        </List>
      )}
    </Popover>
  );
}
