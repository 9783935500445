import { Error as IconError, Cancel as IconClose } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useLayoutEffect, useMemo } from 'react';

import { parseDate } from '@jebel/utils';

import { useLocalStorageState, useSchoolConfiguration } from 'shared/hooks';

import { Buttons, Container, Header, Link, Text, Title } from './TopBar.styles';
import { LOCAL_STORAGE_LAST_TICKER_OPENING, LOCAL_STORAGE_TICKER_OPEN } from 'shared/constants';

export function TopBar() {
  const [open, setOpen] = useLocalStorageState<boolean>(LOCAL_STORAGE_TICKER_OPEN, false);

  const [lastOpening, setLastOpening] = useLocalStorageState<number>(
    LOCAL_STORAGE_LAST_TICKER_OPENING,
  );

  const { data: configuration } = useSchoolConfiguration();

  const settings = configuration?.topBar;

  useLayoutEffect(() => {
    const startDate = parseDate(settings?.startDate);
    const starts = startDate.toMillis();

    if (show === false && (!lastOpening || lastOpening < starts)) {
      // Show when the new top-bar changes
      setOpen(true);
    }
  }, [settings]);

  const show = useMemo(() => {
    if (!settings?.startDate || !settings?.endDate) {
      return false;
    }

    const startDate = parseDate(settings?.startDate);
    const endDate = parseDate(settings?.endDate);

    const startDiff = startDate.diffNow().as('minutes');
    const endDiff = endDate.diffNow().as('minutes');

    const inRange = startDiff <= 0 && endDiff >= 0;

    return inRange && open;
  }, [open, settings]);

  const href = useMemo(() => {
    const link = configuration?.topBar?.link;

    if (typeof link !== 'string') {
      return undefined;
    }

    if (link.startsWith('https') || link.startsWith('https')) {
      return link;
    }

    return `https://${link}`;
  }, [configuration]);

  const handleClose = () => {
    setLastOpening(Date.now());
    setOpen(false);
  };

  if (!show) {
    return null;
  }

  return (
    <Container>
      <Header>
        <IconError fontSize="small" />
        <Title>{settings?.title}</Title>
      </Header>

      <Text>{settings?.text}</Text>

      {href && (
        <Link href={href} target="_blank">
          {settings?.label}
        </Link>
      )}

      <Buttons>
        <IconButton size="small" color="inherit" onClick={handleClose}>
          <IconClose fontSize="small" />
        </IconButton>
      </Buttons>
    </Container>
  );
}
