import React from 'react';

import { CreateGraduatingYearFormValues } from 'shared/validationSchemas';
import { useGraduatingYearsSpreadsheetInfo, useSchoolConfiguration } from 'shared/hooks';
import { Modal } from 'shared/components/ui';

import { useScorecardStats } from '../hooks/useScorecardStats';
import { CreateOrEditGraduatingYearForm } from './CreateOrEditGraduatingYearForm';

type CreateGraduatingYearModalProps = {
  isOpen: boolean;
  onModalClose: () => void;
  onCreate: () => void | Promise<void>;
};

export function CreateGraduatingYearModal({
  isOpen,
  onModalClose,
  onCreate,
}: CreateGraduatingYearModalProps) {
  const { graduatingYears } = useScorecardStats();
  const { data: school } = useSchoolConfiguration();
  const { createGraduatingYear } = useGraduatingYearsSpreadsheetInfo();

  const onSubmit = async (formData: CreateGraduatingYearFormValues) => {
    await createGraduatingYear({
      variables: {
        data: {
          school: { connect: { id: school?.id } },
          graduateCount: formData.alumniCount,
          year: Number(formData.classYear),
        },
      },
    });

    onCreate();
    onModalClose();
  };

  return (
    <Modal
      dialogProps={{ maxWidth: 'xs', fullWidth: true, open: isOpen, onClose: onModalClose }}
      titleProps={{ title: 'New Class' }}
    >
      <CreateOrEditGraduatingYearForm
        onSubmit={onSubmit}
        onClose={onModalClose}
        existingGraduatingYears={graduatingYears}
      />
    </Modal>
  );
}
