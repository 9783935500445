import React from 'react';
import { Grid } from '@mui/material';

import { DashboardCountPostWrapper } from '../DashboardCountWrappers/DashboardCountPostWrapper';
import { DashboardCountBusinessWrapper } from '../DashboardCountWrappers/DashboardCountBusinessWrapper';
import { DashboardCountSpecialOffersWrapper } from '../DashboardCountWrappers/DashboardCountSpecialOffersWrapper';

export function DashboardCountStats() {
  return (
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={12} lg={6} xl={4}>
        <DashboardCountPostWrapper />
      </Grid>

      <Grid item xs={12} lg={6} xl={4}>
        <DashboardCountBusinessWrapper />
      </Grid>

      <Grid item xs={12} lg={6} xl={4}>
        <DashboardCountSpecialOffersWrapper />
      </Grid>
    </Grid>
  );
}
