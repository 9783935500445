import { DEFAULT_USER_NAME } from '@jebel/constants';
/**
 * Formats the user's name based on the provided input.
 *
 * @param user - The user object containing name information. It can be of type `FormatUserNameInput`, `undefined`, or `null`.
 * @param fallback - An optional fallback string to use if the user's first and last names are not available.
 * @returns The formatted user name. If `user.fullName` is provided, it returns that. Otherwise, it concatenates `firstName` and `lastName`. If neither is available, it returns the fallback or a default user name.
 */
export function formatUserName(user, fallback) {
    var _a, _b;
    const firstName = (_a = user === null || user === void 0 ? void 0 : user.firstName) !== null && _a !== void 0 ? _a : '';
    const lastName = (_b = user === null || user === void 0 ? void 0 : user.lastName) !== null && _b !== void 0 ? _b : '';
    if (user === null || user === void 0 ? void 0 : user.fullName) {
        return user.fullName;
    }
    if (!firstName || !lastName) {
        return fallback !== null && fallback !== void 0 ? fallback : DEFAULT_USER_NAME;
    }
    return `${firstName} ${lastName}`;
}
