import { useHistory } from 'react-router-dom';
import { AvatarProps, Tooltip } from '@material-ui/core';
import { ReactNode, useMemo } from 'react';

import {
  processFilestackUrl,
  ProcessFileStackURLOptions,
  processFilestackUrlSrcSet,
} from '@jebel/utils';

import { StyledAvatar } from './AuthorAvatar.styles';

export interface AuthorAvatarProps {
  className?: string;

  children?: ReactNode;

  disabled?: boolean;

  variant?: AvatarProps['variant'];

  /**
   * URL source of the avatar.
   */
  source: string | undefined;

  /**
   * Defines the size of the avatar.
   * Will automatically resize the image to the specified size.
   */
  size?: string | number;

  /** Name of the author. */
  name: string | undefined | null;

  /** Route to navigate when the avatar is clicked. */
  route?: string;
}

const DEFAULT_AUTHOR_AVATAR_RESIZE = 180;

export function AuthorAvatar(props: AuthorAvatarProps) {
  const name = props.name ?? '';

  const size = useMemo(() => {
    return props.size ? Number(props.size) : DEFAULT_AUTHOR_AVATAR_RESIZE;
  }, [props.size]);

  const source = useMemo(() => {
    if (!props.source) {
      return undefined;
    }

    return processFilestackUrl(props.source, { resize: { width: size } });
  }, [props.source, size]);

  const sourceSet = useMemo(() => {
    const options: ProcessFileStackURLOptions = {
      resize: { width: size, height: size },
    };

    return processFilestackUrlSrcSet(props.source, options);
  }, [props.source, size]);

  const shortName = useMemo(() => {
    return name.slice(0, 2);
  }, [name]);

  const { push: navigate } = useHistory();

  const onClick = () => {
    if (props.route) {
      navigate(props.route);
    }
  };

  return (
    <Tooltip
      title={name}
      disableHoverListener={props.disabled}
      disableFocusListener={props.disabled}
      disableTouchListener={props.disabled}
      arrow
    >
      <StyledAvatar
        src={source}
        srcSet={sourceSet}
        className={props.className}
        onClick={onClick}
        aria-disabled={props.disabled}
        disabled={props.disabled}
        size={props.size}
        variant={props.variant}
        imgProps={{
          width: props.size,
          height: props.size,
        }}
      >
        {props.children ?? shortName}
      </StyledAvatar>
    </Tooltip>
  );
}
