import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';
import { Skeleton, alpha } from '@mui/material';
import { Link } from 'react-router-dom';
import { SchoolAvatar } from 'shared/components/symbols';

import { Icon } from 'shared/components/ui';

// Container

interface ContainerProps {
  isOpened?: boolean;
}

export const CONTAINER_OPEN_WIDTH = '15rem';
export const CONTAINER_CLOSED_WIDTH = '5rem';

export const Container = styled.div<ContainerProps>(props => {
  const selectedColor = props.theme.palette.primary.sideFont;
  const selectedBackground = props.theme.palette.primary.side;

  const color = selectedColor ?? props.theme.palette.getContrastText(selectedBackground);
  const background = selectedBackground ?? props.theme.palette.primary.main;

  return css`
    width: 100%;
    height: 100%;
    max-width: ${props.isOpened ? CONTAINER_OPEN_WIDTH : CONTAINER_CLOSED_WIDTH};
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    margin: auto;
    background-color: ${background};
    color: ${color};
  `;
});

// Header

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
  border-bottom: solid 2px #cccccc3b;
`;

// Logo

export const Logo = styled(SchoolAvatar)``;

Logo.defaultProps = {
  size: 16 * 4, // 4rem
};

// SchoolName

export const SchoolName = styled.span`
  white-space: normal;
  line-height: normal;
`;

// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  flex: 1;
  padding: 1rem;
`;

// RectangularSkeleton

export const RectangularSkeleton = styled(Skeleton)(props => {
  const background = props.theme.palette.primary.side;
  const contrast = props.theme.palette.getContrastText(background);

  const color = alpha(contrast, 0.05);

  return css`
    border-radius: 0.3rem;
    background-color: ${color};
    min-height: 3rem;
  `;
});

RectangularSkeleton.defaultProps = {
  variant: 'rectangular',
};

// NavList

export const NavList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

// BaseNavItem

interface BasicNavItemProps {
  isSelected?: boolean;
}

const BasicNavItem = (props: BasicNavItemProps & { theme: Theme }) => {
  const color = props.theme.palette.primary.sideFont;
  const selectedColor = props.theme.palette.primary.light;

  const hoverBackground = alpha(color, 0.05);
  const selectedBackground = alpha(selectedColor, 0.1);
  const selectedHoverBackground = alpha(selectedColor, 0.2);

  return css`
    --sidebar-color: ${color};
    --sidebar-background: transparent;
    --sidebar-hover-background: ${hoverBackground};

    display: flex;
    align-items: center;
    border-radius: 0.3rem;
    padding: 0 0.8rem;
    height: 3rem;
    gap: 0.7rem;
    user-select: none;
    cursor: pointer;
    color: var(--sidebar-color);
    background-color: var(--sidebar-background);
    text-decoration: none;

    &:hover {
      background-color: var(--sidebar-hover-background);
    }

    ${props.isSelected &&
    css`
      --sidebar-color: ${selectedColor};
      --sidebar-background: ${selectedBackground};
      --sidebar-hover-background: ${selectedHoverBackground};
    `}
  `;
};

// NavItem

export const NavItem = styled(Link)<BasicNavItemProps>`
  ${BasicNavItem}
`;

// ExternalLinkItem

export const ExternalLinkItem = styled.a<BasicNavItemProps>`
  ${BasicNavItem}
`;

// NavItemText

export const NavItemText = styled.span`
  font-size: 0.9rem;
`;

// NavItemIcon

export const NavItemIcon = styled(Icon)`
  color: inherit;
  font-size: 1.3rem;
`;

// Footer

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  border-top: solid 2px #cccccc3b;
`;
