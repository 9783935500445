import { useQuery } from 'react-query';
import { ForeignIDTimes } from 'getstream';

import { StreamChatEnrichedActivity } from 'shared/types';
import { Maybe } from 'shared/graphql';
import { useStreamChat } from 'providers/StreamChatProvider';
import { recordError } from 'shared/utils/record';

interface Post {
  id?: Maybe<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createdAt?: Maybe<any>;
}

interface Options {
  post: Post | undefined | null;
  skip?: boolean;
}

export const STREAM_CHAT_ENRICHED_ACTIVITY_QUERY_KEY = 'STREAM_CHAT_ENRICHED_ACTIVITY';

/**
 * Custom hook to fetch enriched activity data from Stream Chat for a specific post.
 *
 * @param {Options} options - The options for fetching the enriched activity.
 * @param {boolean} options.skip - Flag to skip the query.
 * @param {Post} options.post - The post object containing the ID and creation time.
 */
export function useStreamChatEnrichedActivity(options: Options) {
  const { streamChatClient: client } = useStreamChat();

  const {
    data,
    isLoading: loading,
    refetch: refresh,
  } = useQuery({
    queryKey: [STREAM_CHAT_ENRICHED_ACTIVITY_QUERY_KEY, options.post?.id],
    enabled: !options.skip || !client || !options.post,
    onError: recordError,

    async queryFn() {
      if (!options.post || !client) {
        return;
      }

      const foreign: ForeignIDTimes = {
        foreign_id: options.post.id!,
        time: options.post.createdAt!,
      };

      const response = await client.getActivities({
        reactions: { counts: true, recent: true, own: true },
        foreignIDTimes: [foreign],
      });

      const activities = (response?.results ?? []) as StreamChatEnrichedActivity[];
      const activity = activities.find(activity => activity.foreign_id === options.post!.id);

      return activity;
    },
  });

  return {
    data,
    loading,
    refresh,
  };
}
