import { Fragment, useMemo, useState } from 'react';
import { Theme, css } from '@emotion/react';
import { Chip } from '@material-ui/core';
import { Box } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import { Formik } from 'formik';
import pluralize from 'pluralize';

import { fileManyRelations } from '@jebel/utils';

import { DialogSymbol, OrganizationNameLink, SchoolNameLink } from 'shared/components/symbols';
import { Button, Icon, FormTextField, Typography, MediaInput } from 'shared/components/ui';
import { DiscountListItemFragment, HomeFeedPostCreateInput } from 'shared/graphql';
import { useHomeFeedPostCreate } from 'features/home/hooks';
import { EMPTY_IMAGE } from 'features/settings/assets';
import { ResultFile } from 'shared/types/files';
import { useResponsive } from 'shared/hooks';
import { generateDiscountLabel, generateDiscountExpirationLabel } from 'shared/utils/discounts';

import { useRedeemDiscount } from '../hooks/useRedeemDiscount';

const describeCSS = (theme: Theme) => css`
  letter-spacing: 0.18px;
  color: ${theme.palette.primary.light};
  font-weight: 500;
  font-size: 36px;
  line-height: 30px;
`;

const alertCss = css`
  width: 100%;
  border: 1px solid #01b9a1;
  border-radius: 8px;
  background-color: #e5f8f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #01b9a1;
`;

const containerCSS = css`
  display: grid;
  gap: 1rem;
`;

const container263CSS = css`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 15px;
`;

const redeemModalBodyCss = css`
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  grid-column-gap: 15px;
`;

const discountAvatarCSS = (theme: Theme) => css`
  width: 16rem;
  height: 10rem;
  background-color: ${theme.palette.background.paper};
  border: 1px solid ${theme.palette.divider};
  border-radius: 0.5rem;
  object-fit: cover;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: 10rem;
  }
`;

const container262CSS = css`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 7px;
  justify-items: start;
  justify-content: start;
`;

const titleCSS = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
`;

const formBlock3CSS = css`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto;
  justify-content: end;
  margin-bottom: 8px;
`;

const button5CSS = css`
  border-radius: 4px;
`;

const uploadFileBoxCSS = css`
  :hover {
    background-color: #abbeff;
  }

  cursor: pointer;
  background-color: #f6f8fa;
  border: 1px dashed #bbbbbb;
  height: 150px;
  display: grid;
  grid-template-rows: min-content min-content;
  place-items: center;
  align-content: center;
`;

const uploadPictureCSS = (theme: Theme) => css`
  color: ${theme.palette.primary.light};
`;

type FormData = {
  message?: string;
  media?: ResultFile[];
};

const INITIALS: FormData = {
  message: '',
  media: [],
};

interface Props {
  onClose: () => void;
  isOpen: boolean;
  discount: DiscountListItemFragment;
}

export function ReedemDiscountModal({ onClose, isOpen, discount }: Props) {
  const [step, setReedemStep] = useState<'offer' | 'redeemed'>('offer');

  const { isMobile } = useResponsive();
  const { create: createPost, loading: publishing } = useHomeFeedPostCreate();
  const { mutate: redeemDiscount, loading: redeeming } = useRedeemDiscount();

  const isRedeeming = step === 'offer';
  const isPosting = step === 'redeemed';
  const isOrganizationOffer = Boolean(discount?.organization);

  const discountLabel = useMemo(() => {
    return generateDiscountLabel(discount?.type, discount?.amountPercent);
  }, [discount]);

  const formattedExpirationDate = useMemo(() => {
    return generateDiscountExpirationLabel(discount?.expirationDate);
  }, [discount?.expirationDate]);

  const handleCreateRedemptionPost = async (form: FormData) => {
    let trimmedText = form.message && form.message.trim();

    if (!trimmedText) trimmedText = 'Discount Redeemed';

    const data: HomeFeedPostCreateInput = {
      text: trimmedText,
      discount: { connect: { id: discount!.id } },
    };

    if (form.media) {
      data.media = fileManyRelations(form.media);
    }

    await createPost(data);
    onClose();
  };

  const handleSubmit = async (data: FormData) => {
    if (isRedeeming) {
      if (!discount?.id) {
        return;
      }

      await redeemDiscount(discount);

      setReedemStep('redeemed');
      return;
    }

    await handleCreateRedemptionPost(data);
  };

  const redeemedCount = discount?.usersRedeemedCount || 0;
  const loading = publishing || redeeming;

  return (
    <DialogSymbol
      dialogProps={{
        fullScreen: false,
        fullWidth: true,
        maxWidth: isMobile ? 'xs' : 'sm',
        onClose,
        open: isOpen,
      }}
      titleProps={{ title: isRedeeming ? 'Redeem Offer' : 'Discount Redeemed' }}
    >
      <Formik initialValues={INITIALS} onSubmit={handleSubmit}>
        {form => (
          <Box css={containerCSS}>
            <Box css={container263CSS}>
              <Box css={redeemModalBodyCss}>
                <img
                  css={discountAvatarCSS}
                  src={discount?.images?.items[0]?.downloadUrl || EMPTY_IMAGE}
                  alt={discount?.title ?? '(Discount)'}
                />

                <Box css={container262CSS}>
                  {isRedeeming && <Chip color="secondary" label={formattedExpirationDate} />}

                  {isOrganizationOffer ? (
                    <OrganizationNameLink css={titleCSS} organization={discount?.organization} />
                  ) : (
                    <SchoolNameLink css={titleCSS} />
                  )}

                  <Typography css={describeCSS}>{discountLabel}</Typography>

                  <Typography css={titleCSS}>{discount?.title}</Typography>

                  {isRedeeming && !!redeemedCount && (
                    <Typography
                      css={theme => css`
                        color: ${theme.palette.secondary.light};
                      `}
                      variant="body2"
                    >
                      Redeemed {redeemedCount}
                      {pluralize(' time', redeemedCount)}
                    </Typography>
                  )}
                </Box>
              </Box>

              {isPosting && (
                <Fragment>
                  <Box padding={2} css={alertCss}>
                    <Icon
                      name="CheckCircleOutline"
                      css={css`
                        margin-right: 14px;
                        fill: #01b9a1;
                      `}
                    />
                    <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
                      DISCOUNT REDEEMED SUCCESSFULLY.
                    </Typography>
                  </Box>

                  <Box>
                    <Typography style={{ fontWeight: 500 }}>
                      Share your experience (optional)
                    </Typography>
                  </Box>

                  <Box mb={1} display="grid">
                    <FormTextField
                      inputProps={{
                        autoComplete: 'off',
                        label: 'MESSAGE',
                        variant: 'outlined',
                        multiline: true,
                        rows: '5',
                        rowsMax: '5',
                      }}
                      fieldProps={{ name: 'message' }}
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" justifyContent="start">
                    <MediaInput
                      maxFiles={10}
                      descriptionPlacement="top"
                      onChange={files => form.setFieldValue('media', files)}
                    >
                      <Box css={uploadFileBoxCSS}>
                        <FileUpload color="secondary" />

                        {isMobile ? (
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              css={uploadPictureCSS}
                              variant={isMobile ? 'body1' : 'subtitle4'}
                            >
                              Upload pictures or
                            </Typography>
                            <Typography
                              css={uploadPictureCSS}
                              variant={isMobile ? 'body1' : 'subtitle4'}
                            >
                              videos here
                            </Typography>
                          </Box>
                        ) : (
                          <Typography
                            css={uploadPictureCSS}
                            variant={isMobile ? 'body1' : 'subtitle4'}
                          >
                            Upload pictures or videos here
                          </Typography>
                        )}
                      </Box>
                    </MediaInput>
                  </Box>
                </Fragment>
              )}

              {isRedeeming && (
                <Typography
                  css={theme => css`
                    color: ${theme.palette.primary.dark};
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: pre-line;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                  `}
                  variant="body1"
                >
                  {discount?.terms}
                </Typography>
              )}
            </Box>

            <Box css={formBlock3CSS}>
              <Button onClick={onClose} variant="text">
                {isRedeeming ? 'Cancel' : 'Exit'}
              </Button>

              <Button
                loading={loading}
                css={button5CSS}
                color="primary"
                variant="contained"
                onClick={form.submitForm}
              >
                {isRedeeming ? ' Redeem Offer' : 'Post content'}
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </DialogSymbol>
  );
}
