import { Fragment, useEffect, useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import { Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AccountCircle, CheckCircle, CheckCircleOutline, Edit } from '@material-ui/icons';

import {
  POST_REPORT_STATUS,
  POST_STATUSES,
  PostReportStatuses,
  POST_REPORT_STATUS_TITLES,
  SORT,
  EVENT_POST_TYPE,
} from '@jebel/constants';
import { createFilterBuilder, overlapText, sanitizeHomeFeedItemText } from '@jebel/utils';

import {
  ContentReportFilter,
  FullContentReportsListQuery,
  FullContentReportsListQueryVariables,
  FullContentReportsListItemFragment,
  FlaggedPostApproveRequestInput,
  FlaggedPostDeleteRequestInput,
} from 'shared/graphql';
import { Modal, ChooseStatusChipOption } from 'shared/components/ui';
import { useSpreadsheetSearch } from 'shared/features/search';
import {
  Spreadsheet,
  useSpreadsheetContext,
  SpreadsheetCellActions,
  MainToolbarActionType,
} from 'shared/features/spreadsheet';
import { APP_URL, ROUTES } from 'shared/constants';
import { buildUrl } from 'shared/routes';
import { useNotifications } from 'shared/features/notifications';
import { useDownloadLazyQueryCSV, useModalState, useToast } from 'shared/hooks';
import { formatTableDate } from 'shared/utils/date';
import { getFileNameWithTimestamp } from 'shared/utils/file';
import { ChooseContentCurationStatusChip } from 'admin-features/reports/components';
import { sendToSentry } from 'shared/utils/sentry';

import {
  CONTENT_CURATION_CATEGORIES,
  ContentCurationPostsSpreadsheetHeadlines,
  ContentCurationReportHeaders,
  ContentCurationSpreadsheetFilters,
} from '../../constants';
import { useContentCurationPostDelete, useContentCurationFlaggedPostApprove } from '../../hooks';
import { CONTENT_REPORTS_LIST_QUERY } from '../../queries';
import { ConfirmDeleteGroupPostModal } from './ConfirmDeleteGroupPostModal';
import { ConfirmDeleteHomeFeedPostModal } from './ConfirmDeleteHomeFeedPostModal';
import { ConfirmDeleteLegacyFeedPostModal } from './ConfirmDeleteLegacyFeedPostModal';
import { FlaggedGroupPostDetailsModal } from './FlaggedGroupPostDetailsModal';
import { FlaggedHomeFeedPostDetailsModal } from './FlaggedHomeFeedPostDetailsModal';
import { FlaggedLegacyFeedPostDetailsModal } from './FlaggedLegacyFeedPostDetailsModal';
import { ConfirmDeleteSchoolNewsPostModal } from './ConfirmDeleteSchoolNewsPostModal';
import { FlaggedLegacySchoolNewsDetailsModal } from './FlaggedSchoolNewsPostDetailsModal';

const blacklistReportedCss = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize}px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  height: 20px;
  background-color: ${theme.palette.primary.main};
`;

type ReportItem = FullContentReportsListItemFragment;

const checkPostStatus = (post: ReportItem, statusesToCheck: PostReportStatuses[]): boolean => {
  if (!post?.reportStatus) {
    return false;
  }
  return statusesToCheck.includes(post?.reportStatus as PostReportStatuses);
};

export function ContentCurationFlaggedPostsList() {
  const { queryParams, currentRowId, selected, setSortOption } = useSpreadsheetContext();
  const { onPostDelete, loading: deleteLoading } = useContentCurationPostDelete();
  const { onApprovePost, loading: approveLoading } = useContentCurationFlaggedPostApprove();
  const { showError, showMessage } = useToast();
  const { push: navigate } = useHistory();
  const { markNotificationsAsRead } = useNotifications();

  useEffect(() => {
    setSortOption({
      order: SORT.desc,
      headcellId: 'updatedAt',
      sort: {
        updatedAt: SORT.desc,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    markNotificationsAsRead(notification => {
      if (!notification.redirectUrl) {
        return false;
      }

      return notification.redirectUrl.includes(APP_URL.admin.contentCuration.flaggedPosts);
    });
  }, [markNotificationsAsRead]);

  const { tableData, tableLoading, queryVariables } =
    useSpreadsheetSearch<FullContentReportsListQuery>({
      query: CONTENT_REPORTS_LIST_QUERY,
      searchingFields: ['text'],
      queryVariables: {
        ...queryParams,
        filter: {
          ...queryParams.filter,
          isFlagged: { not_equals: false },
          category: { not_equals: EVENT_POST_TYPE },
          deletedAt: { equals: 0 },
          OR: [
            { reportStatus: { not_equals: POST_STATUSES.approved } },
            { reportStatus: { not_equals: POST_STATUSES.pending } },
          ],
        },
      },
    });

  const posts = useMemo(() => {
    return tableData?.contentReportsList.items ?? [];
  }, [tableData]);

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<ContentReportFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [downloadHandler] = useDownloadLazyQueryCSV<
    FullContentReportsListQuery,
    FullContentReportsListQueryVariables
  >(CONTENT_REPORTS_LIST_QUERY, {
    filename: getFileNameWithTimestamp('ContentCuration.csv'),
    transform: transformExport,

    variables: {
      filter: downloadFilter,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findPost = (id: unknown) => {
    return posts.find(post => post.id === id);
  };

  const selectedPosts = useMemo(() => {
    return posts.filter(post => selected.includes(post.id as string));
  }, [posts, selected]);

  const selectedPost = useMemo(() => {
    return findPost(currentRowId);
  }, [findPost, currentRowId]);

  const {
    show: isFlaggedPostDetailsModalOpened,
    open: openDetailsModal,
    close: onDetailsModalClose,
    params: viewPostParams,
  } = useModalState<string>();

  const selectedViewPost = useMemo(() => {
    return findPost(viewPostParams);
  }, [findPost, viewPostParams]);

  const {
    show: isConfirmDeletePostModalOpened,
    open: openDeleteConfirmation,
    close: closeDeleteConfirmation,
    params: deleteConfirmationParams,
  } = useModalState<string>();

  const selectedDeletePost = useMemo(() => {
    return findPost(deleteConfirmationParams);
  }, [findPost, deleteConfirmationParams]);

  const canShowAllowOption = (selectedPost: ReportItem | undefined): boolean => {
    if (selectedPosts.length > 1) {
      return selectedPosts.every(post =>
        checkPostStatus(post, [POST_REPORT_STATUS.pendingReview, POST_REPORT_STATUS.postDeleted]),
      );
    }

    if (!selectedPost) {
      return false;
    }

    return checkPostStatus(selectedPost, [
      POST_REPORT_STATUS.pendingReview,
      POST_REPORT_STATUS.postDeleted,
    ]);
  };

  const canShowRemoveOption = (selectedPost: ReportItem | undefined): boolean => {
    if (selectedPosts.length > 1) {
      return false;
    }

    if (!selectedPost) {
      return false;
    }

    return checkPostStatus(selectedPost, [
      POST_REPORT_STATUS.pendingReview,
      POST_REPORT_STATUS.postAllowed,
    ]);
  };

  const canShowViewDetailsOption = (_selectedPost: ReportItem | undefined) => {
    return true;
  };

  const canViewAuthorProfile = (selectedPost: ReportItem | undefined): boolean => {
    if (selectedPosts.length > 1) {
      return false;
    }

    return Boolean(selectedPost?.authorId);
  };

  const allowPost = async (id: string) => {
    const selectedPost = findPost(id);

    if (!selectedPost) {
      return;
    }

    const payload: FlaggedPostApproveRequestInput = {
      postId: id,
      postType: selectedPost.category as string,
      authorId: selectedPost?.authorId as string,
    };

    try {
      if (selectedPost?.category === CONTENT_CURATION_CATEGORIES.userPost) {
        payload.groupId = selectedPost?.groupId;
      }

      if (selectedPost?.category === CONTENT_CURATION_CATEGORIES.legacyPost) {
        payload.legacyDate = selectedPost?.legacyDate;
      }

      showMessage(`Approving post with ID "${id}", please stand by.`);
      await onApprovePost(payload);
      onDetailsModalClose();
    } catch (err) {
      sendToSentry(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }

    onDetailsModalClose();
  };

  const removePost = async (id: string) => {
    const selectedPost = findPost(id);

    if (!selectedPost) {
      return;
    }

    try {
      const payload: FlaggedPostDeleteRequestInput = {
        postId: id,
        postType: selectedPost.category as string,
        authorId: selectedPost.authorId as string,
      };

      await onPostDelete(payload);
      onDetailsModalClose();
    } catch (err) {
      sendToSentry(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }

    onDetailsModalClose();
  };

  const openAuthorProfile = (id: string) => {
    const url = buildUrl(ROUTES.user.explorer.profile, {
      pathParams: { id: selectedPost?.authorId || '' },
    });

    navigate(url);
  };

  const reportStatusComponent = (selectedPost: ReportItem) => {
    const options: ChooseStatusChipOption[] = [];

    if (canShowAllowOption(selectedPost)) {
      options.push({
        id: 'Allow Post',
        label: 'Allow Post',
        value: POST_REPORT_STATUS.postAllowed,

        async onClick() {
          await allowPost(selectedPost.id as string);
        },
      });
    }

    if (canShowRemoveOption(selectedPost)) {
      options.push({
        id: 'Delete',
        label: 'Delete Post',
        value: POST_REPORT_STATUS.postDeleted,

        async onClick() {
          openDeleteConfirmation(selectedPost.id as string);
        },
      });
    }

    return (
      <ChooseContentCurationStatusChip
        status={selectedPost.reportStatus as string}
        options={options}
      />
    );
  };

  const reportData = useMemo(() => {
    const posts = tableData?.contentReportsList?.items ?? [];

    return posts.map(post => {
      const { id, authorName, updatedAt, isAutoReported, lastFlaggedByName } = post;

      const text = overlapText(sanitizeHomeFeedItemText(post.text ?? ''), { size: 15 });

      return {
        id: id as string,
        post: text,
        author: authorName ?? '(No author name found)',
        flaggedBy: isAutoReported ? (
          <Chip css={blacklistReportedCss} label={ENGINE_NAME} />
        ) : (
          lastFlaggedByName
        ),
        updatedAt: formatTableDate(updatedAt),
        reportStatus: reportStatusComponent(post),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  const rowActions = useMemo<SpreadsheetCellActions>(() => {
    const selectedPost = findPost(currentRowId);
    const options: SpreadsheetCellActions = [];

    if (canShowAllowOption(selectedPost)) {
      options.push({
        id: 'Allow Post',
        title: 'Allow Post',
        icon: <CheckCircleOutline />,

        onClickAction: allowPost,
      });
    }

    if (canShowViewDetailsOption(selectedPost)) {
      options.push({
        id: 'View Details',
        title: 'View Details',
        icon: <Edit />,
        onClickAction: openDetailsModal,
      });
    }

    if (canShowRemoveOption(selectedPost)) {
      options.push({
        id: 'Delete',
        title: 'Delete Post',
        icon: <CheckCircle />,
        onClickAction: openDeleteConfirmation,
      });
    }

    if (canViewAuthorProfile(selectedPost)) {
      options.push({
        id: 'View Profile',
        title: 'View Profile',
        icon: <AccountCircle />,

        onClickAction: openAuthorProfile,
      });
    }

    return options;
  }, [currentRowId, openDeleteConfirmation, openDetailsModal, openAuthorProfile]);

  const columnActions = useMemo<MainToolbarActionType>(() => {
    return {
      label: 'Allow Post',
      icon: <CheckCircleOutline />,

      async onClick(selected) {
        if (selected.length === 0) {
          return;
        }

        const isEveryPostMayBeAllowed = selectedPosts.every(post =>
          checkPostStatus(post, [POST_REPORT_STATUS.pendingReview, POST_REPORT_STATUS.postDeleted]),
        );

        if (!isEveryPostMayBeAllowed) {
          showError(`Some of the posts are already allowed. Select different ones.`);
          return;
        }

        await Promise.allSettled(selected.map(allowPost));
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPosts, allowPost]);

  return (
    <Fragment>
      <Modal
        dialogProps={{
          open: isFlaggedPostDetailsModalOpened,
          onClose: onDetailsModalClose,
          fullWidth: true,
        }}
        titleProps={{ title: 'Post Detail' }}
        isVisibleDivider
      >
        {selectedViewPost?.category === CONTENT_CURATION_CATEGORIES.groupPost && (
          <FlaggedGroupPostDetailsModal
            onModalClose={onDetailsModalClose}
            currentPostId={selectedViewPost.id as string}
            onAllowPostClick={allowPost}
            postDeleteLoading={deleteLoading}
            postStatusUpdateLoading={approveLoading || deleteLoading}
            onConfirmDeleteModalOpen={openDeleteConfirmation}
            groupPostsData={tableData}
          />
        )}

        {selectedViewPost?.category === CONTENT_CURATION_CATEGORIES.legacyPost && (
          <FlaggedLegacyFeedPostDetailsModal
            onModalClose={onDetailsModalClose}
            currentPostId={selectedViewPost.id as string}
            onAllowPostClick={allowPost}
            postDeleteLoading={deleteLoading}
            postStatusUpdateLoading={approveLoading || deleteLoading}
            onConfirmDeleteModalOpen={openDeleteConfirmation}
            legacyFeedPostsData={tableData}
          />
        )}

        {selectedViewPost?.category === CONTENT_CURATION_CATEGORIES.schoolPost && (
          <FlaggedLegacySchoolNewsDetailsModal
            onModalClose={onDetailsModalClose}
            currentPostId={selectedViewPost.id as string}
            onAllowPostClick={allowPost}
            postDeleteLoading={deleteLoading}
            postStatusUpdateLoading={approveLoading || deleteLoading}
            onConfirmDeleteModalOpen={openDeleteConfirmation}
            schoolNewsPostsData={tableData}
          />
        )}

        {selectedViewPost?.category === CONTENT_CURATION_CATEGORIES.userPost && (
          <FlaggedHomeFeedPostDetailsModal
            onModalClose={onDetailsModalClose}
            currentPostId={selectedViewPost.id as string}
            onAllowPostClick={allowPost}
            postDeleteLoading={deleteLoading}
            postStatusUpdateLoading={approveLoading || deleteLoading}
            onConfirmDeleteModalOpen={openDeleteConfirmation}
            homeFeedPostsData={tableData}
          />
        )}
      </Modal>

      <Modal
        dialogProps={{
          open: isConfirmDeletePostModalOpened,
          onClose: closeDeleteConfirmation,
          fullWidth: true,
        }}
        titleProps={{
          title: `Delete Post - Confirmation`,
        }}
      >
        {selectedDeletePost?.category === CONTENT_CURATION_CATEGORIES.groupPost && (
          <ConfirmDeleteGroupPostModal
            onModalClose={closeDeleteConfirmation}
            onDeletePost={removePost}
            loading={deleteLoading}
            id={selectedDeletePost.id as string}
          />
        )}

        {selectedDeletePost?.category === CONTENT_CURATION_CATEGORIES.legacyPost && (
          <ConfirmDeleteLegacyFeedPostModal
            onModalClose={closeDeleteConfirmation}
            onDeletePost={removePost}
            loading={deleteLoading}
            id={selectedDeletePost.id as string}
          />
        )}

        {selectedDeletePost?.category === CONTENT_CURATION_CATEGORIES.schoolPost && (
          <ConfirmDeleteSchoolNewsPostModal
            onModalClose={closeDeleteConfirmation}
            onDeletePost={removePost}
            loading={deleteLoading}
            id={selectedDeletePost.id as string}
          />
        )}

        {selectedDeletePost?.category === CONTENT_CURATION_CATEGORIES.userPost && (
          <ConfirmDeleteHomeFeedPostModal
            onModalClose={closeDeleteConfirmation}
            onDeletePost={removePost}
            loading={deleteLoading}
            id={selectedDeletePost.id as string}
          />
        )}
      </Modal>

      <Spreadsheet
        loading={tableLoading}
        headlines={ContentCurationPostsSpreadsheetHeadlines}
        cellActions={rowActions}
        data={reportData}
        itemsCount={tableData?.contentReportsList.count ?? 0}
        toolbarOptions={{
          filters: ContentCurationSpreadsheetFilters,
          mainToolbarAction: columnActions,
          downloadHandler,
          withDownload: true,
          withPerPage: true,
          rawData: tableData?.contentReportsList?.items ?? [],
        }}
      />
    </Fragment>
  );
}

const ENGINE_NAME = 'SightEngine';

function transformExport(response: FullContentReportsListQuery) {
  const data = response?.contentReportsList.items ?? [];

  return data.map(report => {
    const flaggedBy = report.isAutoReported ? ENGINE_NAME : report.lastFlaggedByName;

    return {
      [ContentCurationReportHeaders.post]: report.text ?? '',
      [ContentCurationReportHeaders.author]: report.authorName ?? '',
      [ContentCurationReportHeaders.flaggedBy]: flaggedBy,
      [ContentCurationReportHeaders.date]: formatTableDate(report.updatedAt),
      [ContentCurationReportHeaders.status]: POST_REPORT_STATUS_TITLES[report.reportStatus ?? ''],
    };
  });
}
