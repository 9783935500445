import React from 'react';
import { useTheme } from '@material-ui/core';
import RedeemIcon from '@mui/icons-material/Redeem';

import { formatISO8601Date } from '@jebel/utils';

import { useSpecialOffersCount } from '../../../hooks';
import { useDateFilterState } from '../../../hooks/useDateFilterState';
import { DashboardCountStatsItem } from '../DashboardCountStats/DashboardCountStatsItem';
import { DashboardFilters } from '../../DashboardFilters';
import { formatDecimalNumbers } from '../../../../../shared/utils/numbers';

export function DashboardCountSpecialOffersWrapper() {
  const theme = useTheme();
  const [dateFilter, setPartialDateFilter] = useDateFilterState();
  const {
    activeOffersCount,
    redeemedOffersCount,
    loading: specialOffersLoading,
    onSpecialOffersCountSearch,
  } = useSpecialOffersCount();

  React.useEffect(() => {
    const startDateSpecialOffers = formatISO8601Date(dateFilter.startDate);
    const endDateSpecialOffers = formatISO8601Date(dateFilter.endDate);
    onSpecialOffersCountSearch(startDateSpecialOffers, endDateSpecialOffers);
  }, [dateFilter, onSpecialOffersCountSearch]);
  return (
    <DashboardCountStatsItem
      title="Special Offers"
      icon={<RedeemIcon />}
      color={theme.palette.secondary.main}
      loading={specialOffersLoading}
      counters={[
        {
          label: 'Total Active Offers',
          count: formatDecimalNumbers(activeOffersCount),
        },
        {
          label: 'Redeemed',
          count: formatDecimalNumbers(redeemedOffersCount),
        },
      ]}
    >
      <DashboardFilters
        dateFilter={dateFilter}
        setStartDateFilter={setPartialDateFilter('startDate')}
        setEndDateFilter={setPartialDateFilter('endDate')}
        loading={specialOffersLoading}
      />
    </DashboardCountStatsItem>
  );
}
