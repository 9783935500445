import React from 'react';
import { css } from '@emotion/react';
import { Theme } from '@material-ui/core';

import { Box, Typography } from 'shared/components/ui';

const titleCss = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 2rem;
  border-bottom: 1px solid ${theme.palette.divider};
`;

export const SettingsTitle: React.FC = ({ children }) => {
  return (
    <Box css={titleCss}>
      <Typography variant="h6" align="inherit" paragraph={false}>
        {children}
      </Typography>
    </Box>
  );
};
