import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Avatar } from '@mui/material';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { Typography } from 'shared/components/ui';
import { OrganizationItemFragment } from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';
import { SchoolAvatar } from 'shared/components/symbols';

const ORGANIZATION_AVATAR_SIZE = 40;
const SUPPORTERS_ICON_SIZE = 25;

const overflowTextProperties = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const cardAvatarCss = theme => css`
  margin-right: ${theme.spacing(1)}px;
  width: ${ORGANIZATION_AVATAR_SIZE}px;
  height: ${ORGANIZATION_AVATAR_SIZE}px;
  border: 1px solid ${theme.palette.border};
  border-radius: ${theme.spacing(0.5)}px;
`;

const cardCss = theme => css`
  cursor: pointer;
  padding: ${theme.spacing(3)}px;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const nameTextCss = theme => css`
  line-height: initial;
  margin-right: ${theme.spacing(0.75)}px;
  ${overflowTextProperties}
`;

const locationTextCss = theme => css`
  color: ${theme.palette.text.secondary};
  line-height: initial;
  ${overflowTextProperties}
`;

const organizationInfoBoxCss = css`
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

type OrganizationListItemProps = {
  organization: OrganizationItemFragment;
};

/** @deprecated Use `ExplorerBusinessCard` instead. */
export const OrganizationListItem = ({ organization }: OrganizationListItemProps) => {
  const { name, location } = organization;
  const { configuration: school } = useSchoolConfiguration();

  const organizationAvatarSource = organization?.logo?.downloadUrl ?? undefined;

  /** Check whether the current organization is supporting current school. */
  const isSupportingCurrentSchool = useMemo(() => {
    const supportedSchools = organization.schoolSupporter?.items ?? [];

    // Check an compare whether with current organization.
    // https://8base-dev.atlassian.net/browse/JEB-1587
    return supportedSchools.some(supported => supported.school?.id === school?.id);
  }, [organization, school]);

  return (
    <Box css={cardCss} display="flex">
      <Avatar
        variant="square"
        css={cardAvatarCss}
        src={organizationAvatarSource}
        srcSet={processFilestackUrlSrcSet(organizationAvatarSource, {
          compress: true,
          resize: {
            width: ORGANIZATION_AVATAR_SIZE,
            height: ORGANIZATION_AVATAR_SIZE,
            fit: 'crop',
          },
        })}
      />

      <Box css={organizationInfoBoxCss}>
        <Box display="flex" alignItems="center">
          <Typography css={nameTextCss} color="primary" variant="subtitle5">
            {name}
          </Typography>

          {isSupportingCurrentSchool && <SchoolAvatar size={SUPPORTERS_ICON_SIZE} />}
        </Box>
        <Typography css={locationTextCss} variant="subtitle5">
          {location?.items[0]?.locationName}
        </Typography>
      </Box>
    </Box>
  );
};
