import React from 'react';
import { useMutation } from '@apollo/client';
import errorCodes from '@8base/error-codes';
import { useSnackbar } from 'notistack';

import {
  GroupPost,
  GroupPostsListDocument,
  HomeFeedItem,
  HomeFeedPost,
  LegacyFeedPost,
  LegacyFeedPostsListDocument,
  PostDeleteMutation,
  PostDeleteMutationVariables,
  SchoolNewsPost,
  SchoolNewsPostsListDocument,
} from 'shared/graphql';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';

import { POST_DELETE_MUTATION } from '../queries';
import { HOME_FEED_ITEMS_QUERY } from '../../../../features/home/queries';
import {
  GROUP_POST_TYPE,
  HOME_FEED_POST_TYPE,
  HOME_POST_TYPE,
  LEGACY_FEED_POST_TYPE,
  LEGACY_POST_TYPE,
  SCHOOL_NEWS_POST_TYPE,
  SCHOOL_POST_TYPE,
} from '../constants';
import { createSnackMessage, SNACK_TYPES } from '../../../components/ui';

type SupportedPosts =
  | (HomeFeedPost | SchoolNewsPost | LegacyFeedPost | GroupPost)['__typename']
  | HomeFeedItem['postType'];

export const usePostDelete = () => {
  const [postDelete, { loading }] = useMutation<PostDeleteMutation, PostDeleteMutationVariables>(
    POST_DELETE_MUTATION,
    { refetchQueries: [HOME_FEED_ITEMS_QUERY, 'HomeFeedItems'] },
  );

  const { enqueueSnackbar } = useSnackbar();
  // TODO Fix post delete on the home feed
  const getDeleteVariables = (id, postType: SupportedPosts) => ({
    isHomeFeedPost: postType === HOME_FEED_POST_TYPE || postType === HOME_POST_TYPE,
    isSchoolNewsPost: postType === SCHOOL_NEWS_POST_TYPE || postType === SCHOOL_POST_TYPE,
    isLegacyFeedPost: postType === LEGACY_FEED_POST_TYPE || postType === LEGACY_POST_TYPE,
    isGroupPost: postType === GROUP_POST_TYPE,
    id,
  });

  const onRefetchQueries = React.useCallback((postType: SupportedPosts) => {
    switch (postType) {
      case LEGACY_POST_TYPE:
      case LEGACY_FEED_POST_TYPE:
        return [HOME_FEED_ITEMS_QUERY, LegacyFeedPostsListDocument];
      case SCHOOL_NEWS_POST_TYPE:
      case SCHOOL_POST_TYPE:
        return [HOME_FEED_ITEMS_QUERY, SchoolNewsPostsListDocument];
      case GROUP_POST_TYPE:
        return [HOME_FEED_ITEMS_QUERY, GroupPostsListDocument];
      default:
        return [HOME_FEED_ITEMS_QUERY];
    }
  }, []);

  const onPostDelete = React.useCallback(
    async (id?: string | null, postType?: SupportedPosts): Promise<void> => {
      if (!id) {
        return;
      }

      try {
        await postDelete({
          variables: getDeleteVariables(id, postType),
          refetchQueries: onRefetchQueries(postType),
          context: {
            [SNACKBAR_SUCCESS_MESSAGE]: 'Success: You have deleted the post.',
            [SNACKBAR_ERROR_MESSAGE]: `Error: You haven't deleted the post. Try again later. `,
          },
        });
      } catch (e: any) {
        if (e?.graphQLErrors?.length) {
          e?.graphQLErrors.forEach(error => {
            if (error.code === errorCodes.NotAuthorizedErrorCode) {
              enqueueSnackbar('You cant delete this post', {
                autoHideDuration: 5000,
                content: createSnackMessage(SNACK_TYPES.error),
              });
            }
          });
        }
      }
    },
    [postDelete, getDeleteVariables],
  );

  return { onPostDelete, loading };
};
