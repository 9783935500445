import React, { ReactNode, useState } from 'react';
import { ChromePicker } from 'react-color';

import { Popover } from 'shared/components/ui';

type PickerProps = {
  value: string;
  pickerTrigger: ReactNode;
  onChange: (color: string) => void;
};

export function CustomColorPicker({ value, pickerTrigger, onChange }: PickerProps) {
  const [color, setColor] = useState(value);

  const handleChange = (color: { hex: string }) => {
    setColor(color.hex);
    onChange(color.hex);
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      target={pickerTrigger}
    >
      <ChromePicker color={color} onChange={handleChange} />
    </Popover>
  );
}
