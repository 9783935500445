import { Fragment, useCallback, useMemo } from 'react';
import { Button, MenuItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Menu } from '@material-ui/core';

import { SelectOption } from '@jebel/constants';
import { usePopoverState } from 'shared/hooks';

type Option = SelectOption;
type OptionAlias = Option | string;

const mapAliasToOption = (option: OptionAlias): Option => {
  if (typeof option === 'string') {
    return { value: option, label: option };
  }
  return option;
};

type Props = {
  label: string;
  value: string;
  options: Array<OptionAlias>;
  onChange: (value: string) => void;
  disabled?: boolean;
  height?: string;
};

export function SelectMenu({ options, value, onChange, label, disabled }: Props) {
  const { show, anchor, open: openDropdown, close: closeDropdown } = usePopoverState();

  const mappedOptions = useMemo(() => options.map(mapAliasToOption), [options]);

  const wrapClickOption = useCallback(
    (option: string) => () => {
      onChange(option);
      closeDropdown();
    },
    [onChange, closeDropdown],
  );

  return (
    <Fragment>
      <Button
        variant="text"
        size="small"
        disabled={disabled}
        onClick={openDropdown}
        endIcon={show ? <ExpandLess /> : <ExpandMore />}
      >
        {label}
      </Button>

      <Menu
        open={show}
        anchorEl={anchor}
        getContentAnchorEl={null}
        onClose={closeDropdown}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {mappedOptions.map(option => (
          <MenuItem
            key={option.value}
            selected={option.value === value}
            onClick={wrapClickOption(option.value as string)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
