import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button as ButtonBase } from '@material-ui/core';

// Container

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: space-between;
  background-color: ${p => p.theme.palette.primary.side};
  color: ${p => p.theme.palette.primary.sideFont};
  padding: 0 1.5rem;
  z-index: 8;
  position: sticky;
  bottom: 0;
  left: 0;
  box-shadow: 0 -0.5rem 1rem 0.01rem rgba(0, 0, 0, 0.05);
`;

// Button

interface ButtonProps {
  isSelected?: boolean;
}

export const Button = styled(ButtonBase)<ButtonProps>`
  color: inherit;
  min-width: auto;

  ${p =>
    p.isSelected &&
    css`
      color: ${p.theme.palette.primary.light};
    `}
`;
