import { useQuery } from 'react-query';
import { ForeignIDTimes } from 'getstream';

import { StreamChatEnrichedActivity } from 'shared/types';
import { Maybe } from 'shared/graphql';
import { useStreamChat } from 'providers/StreamChatProvider';
import { recordError } from 'shared/utils/record';
import { useMemo } from 'react';

interface Post {
  id?: Maybe<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createdAt?: Maybe<any>;
}

interface Options {
  skip?: boolean;

  variables: {
    posts: Post[];
  };
}

export const STREAM_CHAT_ENRICHED_ACTIVITIES_QUERY_KEY = 'STREAM_CHAT_ENRICHED_ACTIVITIES';

/**
 * Custom hook to fetch enriched activities from Stream Chat based on provided posts.
 *
 * @param {Options} options - The options for fetching enriched activities.
 * @param {Array<Post>} options.posts - The list of posts to fetch activities for.
 * @param {boolean} options.skip - Flag to skip fetching activities.
 * @param {StreamChatClient} options.streamChatClient - The Stream Chat client instance.
 */
export function useStreamChatEnrichedActivities(options: Options) {
  const { posts } = options.variables;
  const { streamChatClient: client } = useStreamChat();

  const postIDs = useMemo(() => {
    return posts.map(post => post.id!);
  }, [options.variables.posts]);

  const {
    data = [],
    isLoading: loading,
    refetch: refresh,
  } = useQuery({
    queryKey: [STREAM_CHAT_ENRICHED_ACTIVITIES_QUERY_KEY, postIDs],
    enabled: !options.skip || !client || !posts,
    onError: recordError,

    async queryFn() {
      if (!posts || !client) {
        return [];
      }

      const foreignIDs = posts.map<ForeignIDTimes>(post => ({
        foreign_id: post.id!,
        time: post.createdAt!,
      }));

      const response = await client.getActivities({
        reactions: { counts: true, recent: true, own: true },
        foreignIDTimes: foreignIDs,
      });

      return (response?.results ?? []) as StreamChatEnrichedActivity[];
    },
  });

  return {
    data,
    loading,
    refresh,
  };
}
