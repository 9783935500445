import React from 'react';
import { css, Theme } from '@emotion/react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

import { SORT } from '@jebel/constants';

import { Typography } from 'shared/components/ui';
import { HeadlinesType, HeadlineType, useSpreadsheetContext } from 'shared/features/spreadsheet';

const labelTypographyCss = css`
  font-weight: 500;
  white-space: nowrap;
`;

const checkCellCss = () => css`
  width: 4%;
`;

type SpreadsheetHeaderProps = {
  headlines: HeadlinesType;
  withActions?: boolean;
  withCheckbox?: boolean;
  withIndex?: boolean;
};

export const SpreadsheetHeader = ({
  headlines,
  withActions,
  withCheckbox = true,
  withIndex = true,
}: SpreadsheetHeaderProps) => {
  const { setSortOption, sortOption } = useSpreadsheetContext();

  const createSortHandler = React.useCallback(
    ({ id, sortPath: differentPath }: HeadlineType) =>
      () => {
        const isSameHeadcell = sortOption?.headcellId === id;

        const newSortOrder =
          isSameHeadcell && sortOption?.order === SORT.asc ? SORT.desc : SORT.asc;

        const newSortOption = {
          order: newSortOrder,
          headcellId: id,
          sort: differentPath?.(newSortOrder) || { [id]: newSortOrder },
        };

        setSortOption(newSortOption);
      },
    [setSortOption, sortOption?.headcellId, sortOption?.order],
  );

  return (
    <TableHead>
      <TableRow>
        {withCheckbox && <TableCell css={checkCellCss} />}

        {withIndex && <TableCell css={checkCellCss} />}

        {headlines.map(header => {
          const isActiveSort = header.id === sortOption?.headcellId;
          const direction = isActiveSort && sortOption?.order === SORT.desc ? 'desc' : 'asc';
          const withoutSort = typeof header.sortPath !== 'function' || header.withoutSort;

          return (
            <TableCell key={header.name} align="left" css={header.css}>
              {withoutSort ? (
                <Typography variant="body2" css={labelTypographyCss}>
                  {header.label}
                </Typography>
              ) : (
                <TableSortLabel
                  active={isActiveSort}
                  direction={direction}
                  onClick={createSortHandler(header)}
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  <Typography variant="body2" css={labelTypographyCss}>
                    {header.label}
                  </Typography>
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}

        {withActions && (
          <TableCell align="left">
            <Typography variant="body2" css={labelTypographyCss}>
              ACTIONS
            </Typography>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
