import { useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { Print, CloudDownload, CloudUpload, Search } from '@material-ui/icons';

import { Button, Popover } from 'shared/components/ui';
import { SearchControl } from 'shared/features/search';
import {
  SpreadsheetFilterPopover,
  SpreadsheetFiltersType,
  SpreadsheetToolbarOptions,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';
import { SpreadsheetToolbarBulkActions } from './SpreadsheetToolbarBulkActions';

interface Props {
  itemsCount: number;
  filters?: SpreadsheetFiltersType;
  onSelectAll: () => void;
  toolbarOptions?: SpreadsheetToolbarOptions;
  onClickMainAction: () => void;
  toolbarHeader?: JSX.Element;
  textToolbarHeader?: string;
}

const FILE_TYPE = '.csv';

export function SpreadsheetToolbar({
  itemsCount,
  onSelectAll: selectViewable,
  onClickMainAction,
  toolbarOptions,
  toolbarHeader,
  textToolbarHeader,
}: Props) {
  const { selected, loading } = useSpreadsheetContext();

  const allRowsSelected = useMemo(
    () => itemsCount > 0 && selected.length === itemsCount,
    [itemsCount, selected],
  );

  const areSomeRowsSelected = useMemo(() => selected.length > 0, [selected]);

  const onUploadFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = FILE_TYPE;
    input.style.display = 'none';

    if (toolbarOptions?.onUpload) {
      input.addEventListener('change', toolbarOptions.onUpload as unknown as EventListener);
    }

    document.body.appendChild(input);

    input.click();
    input.remove();
  };

  const isMainAction = !!toolbarOptions?.mainToolbarAction;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="1rem"
      gridGap="1rem"
    >
      <Box display="flex" alignItems="center" gridGap="0.5rem" flex={1} flexWrap="wrap">
        {toolbarHeader && <Box flex={1}>{toolbarHeader}</Box>}

        <FormControlLabel
          label={areSomeRowsSelected ? `${selected.length} selected` : 'Select all'}
          control={
            <Checkbox
              checked={allRowsSelected}
              indeterminate={areSomeRowsSelected && !allRowsSelected}
              disabled={loading}
              onChange={selectViewable}
            />
          }
        />

        {isMainAction && (
          <Box display="flex" alignItems="center">
            {toolbarOptions?.mainToolbarAction?.icon && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={toolbarOptions?.mainToolbarAction?.icon}
                disabled={!!toolbarOptions.mainToolbarAction.disabled}
                onClick={onClickMainAction}
                disableElevation
              >
                {toolbarOptions?.mainToolbarAction?.label}
              </Button>
            )}
          </Box>
        )}

        <SpreadsheetToolbarBulkActions toolbarOptions={toolbarOptions} />
      </Box>

      <Box display="flex" justifyContent={isMainAction ? 'space-between' : 'flex-end'}>
        <Box display="flex" alignItems="center" gridGap="0.5rem">
          {Boolean(toolbarOptions?.withSearch) && (
            <Popover
              disabled={loading}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              target={
                <Button size="small" disabled={loading} startIcon={<Search />}>
                  Search
                </Button>
              }
            >
              <SearchControl
                textFieldProps={{ size: 'small', trackSearches: false }}
                withTextField
              />
            </Popover>
          )}

          {toolbarOptions?.withPDFDownload && (
            <Button
              size="small"
              disabled={loading}
              startIcon={<Print />}
              onClick={() =>
                toolbarOptions?.downloadPDFHandler?.(toolbarOptions?.rawData, textToolbarHeader)
              }
            >
              Print as PDF
            </Button>
          )}

          {toolbarOptions?.filters && (
            <SpreadsheetFilterPopover disabled={loading} filters={toolbarOptions.filters} />
          )}

          {toolbarOptions?.withUpload && (
            <Button
              size="small"
              disabled={loading}
              startIcon={<CloudUpload />}
              onClick={onUploadFile}
            >
              Import from CSV
            </Button>
          )}

          {toolbarOptions?.withDownload && (
            <Tooltip
              title={
                selected.length > 0
                  ? `Download ${selected.length} selected.`
                  : `Download all the elements who matched the filter.`
              }
            >
              <Button
                size="small"
                color="secondary"
                variant="contained"
                disabled={loading}
                startIcon={<CloudDownload />}
                disableElevation
                onClick={() => toolbarOptions?.downloadHandler?.(toolbarOptions?.rawData)}
              >
                Download
              </Button>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
}
