import { useMemo, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { Description } from '@material-ui/icons';

import {
  HOME_FEED_ITEM_TYPES,
  HomeFeedItemType,
  POST_TYPES_TITLES,
  SELECT_OPTION_ALL,
  SelectOption,
} from '@jebel/constants';
import { createFilterBuilder, formatISO8601Date } from '@jebel/utils';

import { formatNumberFriendly } from 'shared/utils/numbers';
import { useHomeFeedItems } from 'shared/hooks';
import { SelectMenu } from 'shared/components/ui';
import { useDateFilterState } from 'admin-features/dashboard/hooks/useDateFilterState';

import { DashboardCountStatsItem } from '../DashboardCountStats/DashboardCountStatsItem';
import { DashboardFilters } from '../../DashboardFilters';
import { useStreamChatEnrichedActivities } from 'shared/features/posts';
import { HomeFeedItemFilter } from 'shared/graphql';

const SELECT_TYPE_OPTIONS: SelectOption[] = [
  { label: SELECT_OPTION_ALL, value: SELECT_OPTION_ALL },
  { label: POST_TYPES_TITLES[HOME_FEED_ITEM_TYPES.SCHOOL], value: HOME_FEED_ITEM_TYPES.SCHOOL },
  { label: POST_TYPES_TITLES[HOME_FEED_ITEM_TYPES.HOME], value: HOME_FEED_ITEM_TYPES.HOME },
  {
    label: POST_TYPES_TITLES[HOME_FEED_ITEM_TYPES.JOB_OFFER],
    value: HOME_FEED_ITEM_TYPES.JOB_OFFER,
  },
  { label: POST_TYPES_TITLES[HOME_FEED_ITEM_TYPES.EVENT], value: HOME_FEED_ITEM_TYPES.EVENT },
  { label: POST_TYPES_TITLES[HOME_FEED_ITEM_TYPES.GROUP], value: HOME_FEED_ITEM_TYPES.GROUP },
  { label: POST_TYPES_TITLES[HOME_FEED_ITEM_TYPES.LEGACY], value: HOME_FEED_ITEM_TYPES.LEGACY },
];

export function DashboardCountPostWrapper() {
  const [selectedPostType, setSelectedPostType] = useState<
    HomeFeedItemType | typeof SELECT_OPTION_ALL
  >(SELECT_OPTION_ALL);

  const [dateFilter, setPartialDateFilter] = useDateFilterState();

  const theme = useTheme();

  const postFilter: HomeFeedItemFilter = useMemo(() => {
    const filter = createFilterBuilder<HomeFeedItemFilter>();

    if (selectedPostType !== SELECT_OPTION_ALL) {
      filter.and({ postType: { equals: selectedPostType } });
    }

    if (dateFilter.startDate) {
      const startDate = formatISO8601Date(dateFilter.startDate);

      filter.and({ createdAt: { gte: startDate } });
    }

    if (dateFilter.endDate) {
      const endDate = formatISO8601Date(dateFilter.endDate);

      filter.and({ createdAt: { lte: endDate } });
    }

    return filter.build();
  }, [dateFilter, selectedPostType]);

  const {
    data: posts,
    count: postsCount,
    loading: postsLoading,
  } = useHomeFeedItems({
    variables: {
      filter: postFilter,
    },
  });

  const { data: activities, loading: activitiesLoading } = useStreamChatEnrichedActivities({
    skip: !posts || posts.length === 0,
    variables: {
      posts,
    },
  });

  const loading = postsLoading || activitiesLoading;

  const reactionsCount = useMemo(() => {
    let count = 0;

    for (const activity of activities) {
      const likes = activity.reaction_counts?.like ?? 0;
      const comments = activity.reaction_counts?.comment ?? 0;

      count += likes + comments;
    }

    return count;
  }, [activities]);

  const handleChangeSelectedPostType = (value: string) => {
    setSelectedPostType(value as HomeFeedItemType);
  };

  return (
    <DashboardCountStatsItem
      title="Posts"
      icon={<Description />}
      color={theme.palette.primary.main}
      loading={loading}
      counters={[
        {
          label: 'Total Posts',
          count: formatNumberFriendly(postsCount),
        },
        {
          label: 'Total Engagement',
          count: formatNumberFriendly(reactionsCount),
        },
      ]}
    >
      <DashboardFilters
        dateFilter={dateFilter}
        setStartDateFilter={setPartialDateFilter('startDate')}
        setEndDateFilter={setPartialDateFilter('endDate')}
        loading={loading}
      />

      <SelectMenu
        label={POST_TYPES_TITLES[selectedPostType]}
        value={selectedPostType}
        onChange={handleChangeSelectedPostType}
        height="20px"
        options={SELECT_TYPE_OPTIONS}
        disabled={loading}
      />
    </DashboardCountStatsItem>
  );
}
