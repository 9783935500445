import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Forum } from '@material-ui/icons';

import { SORT, UserStatuses, USER_STATUSES } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { Icon, Modal } from 'shared/components/ui';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { useSpreadsheetSearch } from 'shared/features/search';
import {
  HeadlinesType,
  Spreadsheet,
  SpreadsheetCellActions,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';
import {
  SortOrder,
  User,
  MembersWithZipListQuery,
  UserFilter,
  UserSort,
  useUserUpdateMutation,
} from 'shared/graphql';
import { useInboxContext } from 'providers/InboxProvider';
import { formatToPhone } from 'shared/utils/form';
import { MemberEditRolesModal } from 'admin-features/members/components/MemberEditRolesModal';
import {
  useAudienceGraduatingYearsOptions,
  useCurrentUserOrganizations,
  useDownloadLazyQueryCSV,
} from 'shared/hooks';
import { formatUserName } from 'shared/utils/user';
import { formatTableDate } from 'shared/utils/date';
import { SiteAdminsHeaders } from 'admin-features/settings/constants';
import { getFileNameWithTimestamp } from 'shared/utils/file';

import { memberSpreadsheetFilters } from '../../../members/constants';
import { getUserStatusComponent } from '../../../members/components/MembersSpreadsheet';
import { MEMBERS_WITH_ZIP_LIST_QUERY } from '../../../members/queries';
import { useAcceptMember, useRejectMember } from 'admin-features/members/hooks';

export const SiteAdminsSpreadsheetHeader: HeadlinesType<UserSort> = [
  {
    name: 'fullName',
    label: 'NAME',
    id: 'fullName',
    sortPath: (order: SortOrder) => ({ fullName: order }),
  },
  {
    name: 'email',
    label: 'EMAIL ADDRESS',
    id: 'email',
    sortPath: (order: SortOrder) => ({ email: order }),
  },
  {
    name: 'phone',
    label: 'PHONE',
    id: 'phoneNumber',
    sortPath: (order: SortOrder) => ({ userPreferences: { phoneNumber: order } }),
  },
  {
    name: 'birth',
    label: 'DATE OF BIRTH',
    id: 'birthDate',
    sortPath: (order: SortOrder) => ({ birthDate: order }),
  },
  {
    name: 'status',
    label: 'STATUS',
    id: 'status',
    sortPath: (order: SortOrder) => ({ userStatus: order }),
  },
] as const;

const checkUserStatus = (
  user: User | null | undefined,
  statusesToCheck: UserStatuses[],
): boolean => {
  if (!user?.userStatus) return false;
  return statusesToCheck.includes(user.userStatus as UserStatuses);
};

const ADMIN_ROLE_ADDON = 'Community Admin';
const ADMIN_ROLE = 'CommunityAdmin';

const FALLBACK_RADIUS = '-';
const FALLBACK_START_POINT_ZIP = '';
const FALLBACK_SORT = { firstName: SORT.desc };

export const SiteAdminsList = () => {
  const { data: organizations } = useCurrentUserOrganizations({
    variables: { sort: { name: SORT.asc } },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { onOpenInboxModal } = useInboxContext();
  const { data: graduatingYears } = useAudienceGraduatingYearsOptions();
  const { queryParams, selected, currentRowId, zip } = useSpreadsheetContext();

  const { tableData, queryVariables, tableLoading } = useSpreadsheetSearch<MembersWithZipListQuery>(
    {
      query: MEMBERS_WITH_ZIP_LIST_QUERY,
      searchingFields: ['fullName', 'email', 'userPreferences.phoneNumber'],
      queryVariables: {
        startPointZip: zip.startPointZip ?? FALLBACK_START_POINT_ZIP,
        radius: zip.radius ?? FALLBACK_RADIUS,
        ...queryParams,
        sort: queryParams.sort ?? FALLBACK_SORT,
        filter: {
          ...queryParams.filter,
          rolesAddons: { some: { name: { equals: ADMIN_ROLE_ADDON } } },
        },
      },
    },
  );

  const siteAdminsData = React.useMemo(
    () =>
      tableData?.members?.items.map(el => ({
        id: el.id || '',
        fullName: formatUserName(el),
        email: el.email || '',
        phone: formatToPhone(el.userPreferences?.phoneNumber),
        birth: el?.birthDate ? formatTableDate(el.birthDate) : '(Unknown)',
        status: getUserStatusComponent(el.userStatus),
      })) || [],
    [tableData],
  );

  const { mutate: acceptRequest } = useAcceptMember({
    refetchQueries: ['MembersWithZipList', 'MembersWithZipListCount'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Your invitations have been sent. Thank you.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your invitations haven't been sent`,
    },
  });

  const { mutate: rejectMember } = useRejectMember({
    refetchQueries: ['MembersWithZipList', 'MembersWithZipListCount'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Your request has been sent.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
    },
  });

  const [updateUser] = useUserUpdateMutation({
    refetchQueries: ['MembersWithZipList', 'MembersWithZipListCount'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Your request has been sent.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
    },
  });

  const [editRolesModalAdminId, setEditRolesModalAdminId] = React.useState<string>();
  const [isEditRolesModalOpen, setIsEditRolesModalOpen] = React.useState(false);
  const { onEditRolesModalOpen, onEditRolesModalClose } = React.useMemo(
    () => ({
      onEditRolesModalOpen: () => setIsEditRolesModalOpen(true),
      onEditRolesModalClose: () => setIsEditRolesModalOpen(false),
    }),
    [],
  );

  const isActionsVisible = React.useMemo(
    () => ({
      approve: (selectedAdmin: User | undefined) =>
        selected.length
          ? (
              tableData?.members?.items.filter(({ id }) => selected.includes(id ?? '')) as
                | User[]
                | undefined
            )?.every(admin => checkUserStatus(admin, [USER_STATUSES.pending]))
          : checkUserStatus(selectedAdmin, [USER_STATUSES.pending]),
      reject: (selectedAdmin: User | undefined) =>
        selected.length
          ? (
              tableData?.members?.items.filter(({ id }) => selected.includes(id ?? '')) as
                | User[]
                | undefined
            )?.every(admin => checkUserStatus(admin, [USER_STATUSES.invitationSent]))
          : checkUserStatus(selectedAdmin, [USER_STATUSES.invitationSent]),
      editRoles: !selected.length,
      delete: true,
    }),
    [selected, tableData?.members?.items],
  );

  const spreadsheetActions = React.useMemo((): SpreadsheetCellActions => {
    const selectedAdmin = tableData?.members.items.find(user => user.id === currentRowId) as
      | User
      | undefined;

    const adminRoleAddonID =
      selectedAdmin?.rolesAddons?.items.find(({ name }) => name === ADMIN_ROLE_ADDON)?.id || '';

    const adminRoleID =
      selectedAdmin?.roles?.items.find(({ name }) => name === ADMIN_ROLE)?.id || '';

    return [
      isActionsVisible.approve(selectedAdmin)
        ? {
            id: 'Approve',
            title: 'Approve Request',
            onClickAction: (id: string) => {
              acceptRequest({ id });
            },
            icon: <Icon name="CheckCircle" />,
          }
        : null,
      isActionsVisible.reject(selectedAdmin)
        ? {
            id: 'Reject',
            title: 'Reject',
            icon: <Icon name="DeleteForeverSharp" />,

            onClickAction: (id: string) => {
              rejectMember({ id });
            },
          }
        : null,
      isActionsVisible.editRoles
        ? {
            id: 'edit_admin_roles',
            title: 'Edit Admin Roles',
            onClickAction: (id: string) => {
              onEditRolesModalOpen();
              setEditRolesModalAdminId(id);
            },
            icon: <Icon name="PersonAddAlt1" />,
          }
        : null,
      isActionsVisible.delete
        ? {
            id: 'Delete',
            title: 'Delete',
            onClickAction: (id: string) => {
              updateUser({
                variables: {
                  data: {
                    id,
                    roles: {
                      disconnect: [{ id: adminRoleID }],
                    },
                    rolesAddons: {
                      disconnect: [{ id: adminRoleAddonID }],
                    },
                  },
                },
              });
            },

            icon: <Icon name="Delete" />,
          }
        : null,
    ].filter(Boolean) as SpreadsheetCellActions;
  }, [
    tableData?.members.items,
    isActionsVisible,
    currentRowId,
    selected,
    acceptRequest,
    enqueueSnackbar,
    onEditRolesModalOpen,
    updateUser,
  ]);

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<UserFilter>(queryParams.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryParams, selected]);

  const [downloadHandler] = useDownloadLazyQueryCSV<MembersWithZipListQuery>(
    MEMBERS_WITH_ZIP_LIST_QUERY,
    {
      filename: getFileNameWithTimestamp('SiteAdmins.csv'),

      variables: {
        startPointZip: queryVariables.startPointZip ?? FALLBACK_START_POINT_ZIP,
        radius: queryVariables.radius ?? FALLBACK_RADIUS,
        filter: {
          ...downloadFilter,
          rolesAddons: { some: { name: { equals: ADMIN_ROLE_ADDON } } },
        },
      },

      transform(response) {
        const members = response?.members?.items ?? [];

        return members.map(member => {
          const birthDate = member?.birthDate ? formatTableDate(member.birthDate) : '(Unknown)';

          return {
            [SiteAdminsHeaders.name]: formatUserName(member),
            [SiteAdminsHeaders.email]: member.email,
            [SiteAdminsHeaders.phone]: formatToPhone(member.userPreferences?.phoneNumber),
            [SiteAdminsHeaders.dateOfBirth]: birthDate,
            [SiteAdminsHeaders.status]: member.userStatus ?? USER_STATUSES.pending,
          };
        });
      },
    },
  );

  const handleMessage = React.useCallback(
    (adminsIds: string[]) => {
      const listOfAdmins =
        tableData?.members.items.filter(({ id }) => adminsIds.includes(id ?? '')) || [];

      onOpenInboxModal?.({
        isOpen: true,
        options: {
          members: listOfAdmins?.map(user => user as User),
          messageType: 'personal',
        },
      });
    },
    [onOpenInboxModal, tableData?.members.items],
  );

  const mainToolbarAction = React.useMemo(
    () => ({
      icon: <Forum />,
      label: 'Message',
      onClick: handleMessage,
    }),
    [handleMessage],
  );

  return (
    <React.Fragment>
      <Modal
        dialogProps={{ open: isEditRolesModalOpen, onClose: onEditRolesModalClose }}
        titleProps={{ title: 'Edit User Roles' }}
      >
        <MemberEditRolesModal
          memberId={editRolesModalAdminId}
          onModalClose={onEditRolesModalClose}
        />
      </Modal>

      <Spreadsheet
        data={siteAdminsData}
        headlines={SiteAdminsSpreadsheetHeader}
        toolbarOptions={{
          filters: memberSpreadsheetFilters({ organizations, graduatingYears }),
          mainToolbarAction,
          withPerPage: true,
          withDownload: true,
          downloadHandler,
          rawData: tableData?.members?.items ?? [],
        }}
        cellActions={spreadsheetActions}
        itemsCount={tableData?.members?.count ?? 0}
        loading={tableLoading}
      />
    </React.Fragment>
  );
};
