import { ChangeEvent } from 'react';
import { TablePagination } from '@material-ui/core';

import {
  DEFAULT_PAGE,
  TABLE_PAGE_SIZE_VARIANTS,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';

type SpreadsheetPaginationProps = {
  itemsCount: number;
};

export function SpreadsheetPagination({ itemsCount }: SpreadsheetPaginationProps) {
  const { page, pageSize, setPage, setPageSize } = useSpreadsheetContext();

  const handleChangePage = (_event: unknown, selectedPage: number) => {
    setPage(selectedPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(+event.target.value);
    setPage(DEFAULT_PAGE);
  };

  return (
    <TablePagination
      component="div"
      count={itemsCount}
      page={page - 1}
      rowsPerPage={pageSize}
      rowsPerPageOptions={TABLE_PAGE_SIZE_VARIANTS}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}
