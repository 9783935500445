import { MinimalUserFragment } from 'shared/graphql';

import { formatUserName } from '@jebel/utils';

export { formatUserName };

/** @deprecated Use {@link formatUserName} instead */
export const getUserFullName = (
  user: MinimalUserFragment | null | undefined,
  valueIfNil?: string,
): string => {
  return formatUserName({ firstName: user?.firstName, lastName: user?.lastName }, valueIfNil);
};
