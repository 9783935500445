import { Fragment, MutableRefObject, ReactNode, useCallback, useMemo, useState } from 'react';
import { Theme, css } from '@emotion/react';
import { Avatar, Badge, Box, Button, IconButton, Tooltip } from '@material-ui/core';
import { Image, Photo, RemoveCircle, Send } from '@material-ui/icons';
import { Formik, FormikHelpers, useFormikContext } from 'formik';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { FormTextField, ImageInput, ResultFile } from 'shared/components/ui';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { MentionPosition } from 'shared/features/mentions/types';
import { recordDebug, recordError } from 'shared/utils/record';
import { useResponsive, useToast } from 'shared/hooks';
import { MentionsField } from 'shared/components/form';
import { ResultFileValue } from 'shared/types/files';
import { UserAvatar } from 'shared/components/symbols';

const containerCSS = (theme: Theme) => css`
  --container-gap: 1rem;

  display: flex;
  flex-direction: column;
  gap: var(--container-gap);

  ${theme.breakpoints.down('md')} {
    --container-gap: 0.5rem;
  }
`;

const contentCSS = (theme: Theme) => css`
  --avatar-size: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--container-gap);

  ${theme.breakpoints.down('md')} {
    flex-wrap: wrap;
  }
`;

const contentDescriptionCSS = (theme: Theme) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--container-gap);

  ${theme.breakpoints.down('md')} {
    flex-grow: 1;
  }
`;

const optionsCSS = (theme: Theme) => css`
  --options-gap: 0.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--options-gap);

  ${theme.breakpoints.down('md')} {
    --options-gap: 0.25rem;

    flex-wrap: wrap;
    margin-left: calc(var(--avatar-size) + var(--container-gap));

    button {
      min-width: min-content;
    }
  }
`;

const previewImagesCSS = css`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex: 1;
  flex-wrap: wrap;
`;

const previewImageCSS = css`
  width: 5rem;
  height: 5rem;
`;

const removeImageBadgeCSS = css``;

const removeImageButtonCSS = css`
  cursor: pointer;
`;

const AVATAR_SIZE = 32;

const avatarCSS = css`
  width: var(--avatar-size);
  height: var(--avatar-size);
  margin-top: 0.8rem;
`;

export type CommentFormValues = {
  /** @deprecated Use `message` instead. */
  comment?: string;
  message?: string;
  media?: CommentImage[];
  mentions?: MentionPosition[];
};

export type CommentImage = Pick<ResultFile, 'fileId' | 'filename' | 'downloadUrl' | 'public'>;

export interface CommentFormProps {
  /** @deprecated */
  type?: 'comment' | 'reply';
  /** @deprecated */
  fieldRef?: MutableRefObject<any>;
  /** @deprecated */
  formRef?: MutableRefObject<any>;

  /**
   * @default "Write your message"
   */
  placeholder?: string;

  initialValues?: CommentFormValues;
  /** Allow to extend the current options of the form. */
  addons?: ReactNode;

  /** @deprecated Use `onSubmit` instead. */
  onCommentPost?(
    text: string,
    media: CommentImage[],
    mentions: MentionPosition[],
  ): Promise<unknown>;

  onSubmit?(
    values: CommentFormValues,
    form: FormikHelpers<CommentFormValues>,
  ): Promise<void> | void;

  /** @default true */
  withMentions?: boolean;

  /** @deprecated */
  withAutoFocus?: boolean;
}

export function CommentForm(props: CommentFormProps) {
  const [loading, setLoading] = useState(false);
  const [mentions, setMentions] = useState<MentionPosition[]>([]);

  const { user } = useCurrentUser();
  const { isMobile } = useResponsive();
  const { showError } = useToast();

  const placeholder = props.placeholder ?? 'Write your message.';
  const withMentions = props.withMentions ?? true;

  const initials = useMemo<CommentFormValues>(() => {
    return {
      comment: props.initialValues?.message ?? props.initialValues?.comment ?? '',
      media: props.initialValues?.media ?? [],
      mentions: [],
    };
  }, [props.initialValues]);

  const onSubmit = async (values: CommentFormValues, helpers: FormikHelpers<CommentFormValues>) => {
    setLoading(true);

    const text = values.message ?? values.comment ?? '';
    const media = values.media ?? [];

    values.mentions = mentions;

    try {
      if (text.length === 0 && media.length === 0) {
        recordDebug(`Comment should either have text or image`);
        return;
      }

      await props.onCommentPost?.(text, media, mentions);
      await props.onSubmit?.(values, helpers);

      helpers.resetForm();
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }

    setLoading(false);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initials}>
      {form => {
        const hasValues = form.values.message?.length || form.values.media?.length;
        const isSending = loading || form.isSubmitting;
        const disabled = isSending || !hasValues;

        return (
          <Box css={containerCSS}>
            <Box css={contentCSS}>
              <Box css={contentDescriptionCSS}>
                <UserAvatar user={user} />

                {withMentions ? (
                  <MentionsField
                    name="message"
                    placeholder={isSending ? 'Sending...' : placeholder}
                    fullWidth
                    allowSearchSpace
                    onChangeMentions={setMentions}
                  />
                ) : (
                  <FormTextField
                    fieldProps={{ name: 'message' }}
                    inputProps={{
                      fullWidth: true,
                      placeholder: isSending ? 'Sending...' : placeholder,
                    }}
                  />
                )}
              </Box>

              <Box css={optionsCSS}>
                {isMobile ? (
                  <Fragment>
                    <CommentSendButton disabled={disabled} />
                    <CommentImageButton isSending={isSending} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <CommentImageButton isSending={isSending} />
                    <CommentSendButton disabled={disabled} />
                  </Fragment>
                )}
              </Box>

              {props.addons}
            </Box>

            <CommentPreviewImages />
          </Box>
        );
      }}
    </Formik>
  );
}

function CommentImageButton(props: { isSending?: boolean }) {
  const { values, setFieldValue } = useFormikContext<CommentFormValues>();
  const { isMobile } = useResponsive();

  const onChange = (values: ResultFileValue | null) => {
    setFieldValue('media', Array.isArray(values) ? values : [values]);
  };

  if (isMobile) {
    return (
      <ImageInput value={values.media} showPreviewImage={false} name="images" onChange={onChange}>
        <Button
          size="small"
          aria-label="Attach images"
          variant="outlined"
          color="secondary"
          startIcon={<Image fontSize="small" />}
          disabled={props.isSending}
        >
          Attach images
        </Button>
      </ImageInput>
    );
  }

  return (
    <ImageInput value={values.media} showPreviewImage={false} name="images" onChange={onChange}>
      <Tooltip title="Attach images">
        <IconButton aria-label="Attach images" color="secondary" disabled={props.isSending}>
          <Image fontSize="small" />
        </IconButton>
      </Tooltip>
    </ImageInput>
  );
}

function CommentSendButton(props: { disabled?: boolean }) {
  const { submitForm } = useFormikContext<CommentFormValues>();
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <Button
        aria-label="Send"
        size="small"
        variant="contained"
        color={props.disabled ? 'default' : 'primary'}
        disabled={props.disabled}
        disableElevation
        onClick={submitForm}
        startIcon={<Send fontSize="small" />}
      >
        Send
      </Button>
    );
  }

  return (
    <Tooltip title="Send">
      <IconButton
        aria-label="Send"
        onClick={submitForm}
        color={props.disabled ? 'default' : 'primary'}
        disabled={props.disabled}
      >
        <Send fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

function CommentPreviewImages() {
  const { values, setFieldValue } = useFormikContext<CommentFormValues>();

  const onRemove = useCallback(
    (selected: CommentImage) => () => {
      const newMedia = values.media?.filter(file => file.fileId !== selected.fileId);

      setFieldValue('media', newMedia);
    },
    [values, setFieldValue],
  );

  return (
    <Box css={previewImagesCSS}>
      {values.media?.map(file => (
        <Badge
          key={file.fileId}
          css={removeImageBadgeCSS}
          badgeContent={
            <RemoveCircle css={removeImageButtonCSS} color="error" onClick={onRemove(file)} />
          }
        >
          <Avatar
            css={previewImageCSS}
            variant="rounded"
            src={file.downloadUrl as string}
            alt={file.filename ?? 'Image'}
          >
            <Photo />
          </Avatar>
        </Badge>
      ))}
    </Box>
  );
}
