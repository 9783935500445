import { Fragment, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  CheckCircle,
  DeleteForeverSharp,
  Edit,
  ForumTwoTone,
  Mail,
  PersonAdd,
  ArrowCircleUp,
  Forum,
} from '@mui/icons-material';

import { USER_STATUSES, SORT } from '@jebel/constants';
import {
  canUserBeActivated,
  canUserBeApproved,
  canUserBeCredentialsReset,
  canUserBeRejected,
  canUserReceiveInvitationAgain,
  createFilterBuilder,
  extractYear,
  formatTableDate,
} from '@jebel/utils';

import { Modal, ChooseStatusChipOption } from 'shared/components/ui';
import {
  APP_URL,
  SNACKBAR_ERROR_MESSAGE,
  SNACKBAR_SUCCESS_MESSAGE,
  USER_ACTIVITIES_OPTIONS,
} from 'shared/constants';
import { useSpreadsheetSearch } from 'shared/features/search';
import {
  Spreadsheet,
  SpreadsheetBulkActions,
  SpreadsheetCellActions,
} from 'shared/features/spreadsheet';
import { useSpreadsheetContext } from 'shared/features/spreadsheet/providers';
import {
  MembersWithZipListQuery,
  MemberInfoFragment,
  Maybe,
  UserKeyFilter,
  UserFilter,
  MembersWithZipListQueryVariables,
  useResetUserCredentialsMutation,
} from 'shared/graphql';
import { buildUrl } from 'shared/routes';
import {
  useAudienceGraduatingYearsOptions,
  useCrudPermissions,
  useDownloadLazyQueryCSV,
  useToast,
  useUserInvitation,
  useUserInvitationCSV,
  useUpdateUserRoleAddons,
  useCurrentUserRoleAddons,
} from 'shared/hooks';
import { useInboxContext } from 'providers/InboxProvider';
import { getFileNameWithTimestamp } from 'shared/utils/file';
import { useOrganizations } from 'features/organizations/hooks';
import { recordError } from 'shared/utils/record';

import {
  MembersReportHeaders,
  MembersSpreadsheetHeader,
  memberSpreadsheetFilters,
} from '../constants';
import { MEMBERS_WITH_ZIP_LIST_QUERY, TOGGLE_ACTIVITY_STATUS_MUTATION } from '../queries';
import { MemberEditRolesModal } from './MemberEditRolesModal';
import { ChooseUserStatusChip } from './ChooseUserStatusChip';
import {
  useAcceptMember,
  useAcceptMembers,
  useRejectMember,
  useRejectMembers,
  useResendMemberInvitation,
  useResendMembersInvitation,
} from '../hooks';
import { formatUserName } from 'shared/utils/user';
import { formatToPhone } from 'shared/utils/form';

const ADMIN_ROLE_ADDON = 'Community Admin';
const FALLBACK_RADIUS = '-';
const FALLBACK_START_POINT_ZIP = '';
const FALLBACK_SORT = { createdAt: SORT.desc };

const INVITING_CSV_MESSAGE = `Inviting imported users from CSV, please wait until the process finishes.`;
const INVITING_CSV_MESSAGE_KEY = `INVITING_CSV`;

export function MembersSpreadsheet() {
  const {
    membersPermissions: { add: isCreateAccessed, edit: isEditAccessed, delete: isDeleteAccessed },
    loading: loadingAddons,
  } = useCrudPermissions();

  const { push: navigate } = useHistory();
  const { onOpenInboxModal } = useInboxContext();
  const { showError, showSuccess, showMessage, dismiss } = useToast();
  const { queryParams, sortOption, currentRowId, selected, chipsArray } = useSpreadsheetContext();

  const { mutate: acceptMember } = useAcceptMember({
    refetchQueries: ['MembersWithZipList', 'MemberStats'],
    awaitRefetchQueries: true,
  });

  const { mutate: acceptMembers } = useAcceptMembers({
    refetchQueries: ['MembersWithZipList', 'MemberStats'],
  });

  const { mutate: rejectMember } = useRejectMember({
    refetchQueries: ['MembersWithZipList', 'MemberStats'],
    awaitRefetchQueries: true,
  });

  const { mutate: rejectMembers } = useRejectMembers({
    refetchQueries: ['MembersWithZipList', 'MemberStats'],
  });

  const { mutate: resendInvitation } = useResendMemberInvitation({
    refetchQueries: ['MembersWithZipList', 'MemberStats'],
    awaitRefetchQueries: true,
  });

  const { mutate: resendInvitations } = useResendMembersInvitation({
    refetchQueries: ['MembersWithZipList', 'MemberStats'],
  });

  const { inviteUsers } = useUserInvitation({
    refetchQueries: ['MembersWithZipList', 'MemberStats'],
  });

  const { handle: handleUploadCSV } = useUserInvitationCSV({
    async onComplete(parsed) {
      showMessage(INVITING_CSV_MESSAGE, { id: INVITING_CSV_MESSAGE_KEY });

      try {
        await inviteUsers(parsed, true);

        showSuccess('Invitations have been sent successfully.');
      } catch (err) {
        recordError(err);

        if (err instanceof Error) {
          showError(err.message, { reportable: false });
        }
      } finally {
        dismiss(INVITING_CSV_MESSAGE_KEY);
      }
    },
  });

  const [editRolesModalMemberId, setEditRolesModalMemberId] = useState<string>();
  const [isEditRolesModalOpen, setIsEditRolesModalOpen] = useState(false);

  const onEditRolesModalOpen = () => {
    setIsEditRolesModalOpen(true);
  };

  const onEditRolesModalClose = () => {
    setIsEditRolesModalOpen(false);
  };

  const withCustomActivityFilter = useMemo(
    () => chipsArray.hobbies.length || chipsArray.clubs.length,
    [chipsArray.clubs.length, chipsArray.hobbies.length],
  );

  const { tableData, tableLoading, queryVariables } = useSpreadsheetSearch<
    MembersWithZipListQuery,
    MembersWithZipListQueryVariables
  >({
    query: MEMBERS_WITH_ZIP_LIST_QUERY,
    searchingFields: ['fullName', 'firstName', 'lastName', 'email'],
    queryVariables: {
      startPointZip: FALLBACK_START_POINT_ZIP,
      radius: FALLBACK_RADIUS,
      sort: queryParams.sort ?? FALLBACK_SORT,
      ...queryParams,
    },
  });

  const bulkFilter: UserFilter = useMemo(() => {
    const filter = createFilterBuilder<UserFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [generateCSV] = useDownloadLazyQueryCSV<
    MembersWithZipListQuery,
    MembersWithZipListQueryVariables
  >(MEMBERS_WITH_ZIP_LIST_QUERY, {
    filename: getFileNameWithTimestamp('Members.csv'),

    variables: {
      startPointZip: queryVariables.startPointZip ?? FALLBACK_START_POINT_ZIP,
      radius: queryVariables.radius ?? FALLBACK_RADIUS,
      filter: bulkFilter,
    },

    transform(response) {
      const members = response?.members?.items ?? [];
      return transformExport(members);
    },
  });

  const [toggleActiveStatus] = useMutation(TOGGLE_ACTIVITY_STATUS_MUTATION, {
    refetchQueries: ['MembersWithZipList', 'MemberStats'],
    awaitRefetchQueries: true,
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: `Success! You've toggled user active status.`,
      [SNACKBAR_ERROR_MESSAGE]: `Error! Something went wrong... Try again later.`,
    },
  });

  const [resetUserCredentials] = useResetUserCredentialsMutation();

  const { data: userRoleAddons } = useCurrentUserRoleAddons();
  const { updateUserRoles } = useUpdateUserRoleAddons();
  const { data: graduatingYears } = useAudienceGraduatingYearsOptions();
  const { data: organizations } = useOrganizations({
    variables: {
      sort: { name: SORT.asc },
    },
  });

  const promoteUser = async (member: UserKeyFilter) => {
    const adminRoleAddon = userRoleAddons.find(({ name }) => name === ADMIN_ROLE_ADDON) ?? {};

    const selectedMemberRolesIds = selectedMemberRoleAddons.map(member => member.id ?? '');

    try {
      const newestRoles = [...selectedMemberRolesIds, adminRoleAddon.id];

      await updateUserRoles(member.id as string, newestRoles as string[]);

      showSuccess('Roles updates successfully');
    } catch (err) {
      recordError(err);
      showError('Error while promoting to Admin');
    }
  };

  const getAvailableActions = (status?: string) => {
    const withApproveAction = canUserBeApproved(status ? { userStatus: status } : selectedMember);

    const withActivesActions = canUserBeActivated(status ? { userStatus: status } : selectedMember);

    const withRejectAction = canUserBeRejected(status ? { userStatus: status } : selectedMember);

    const withResendInvitation = canUserReceiveInvitationAgain(
      status ? { userStatus: status } : selectedMember,
    );

    const withResetCredentials = canUserBeCredentialsReset(
      status ? { userStatus: status } : selectedMember,
    );

    return {
      withRejectAction,
      withActivesActions,
      withApproveAction,
      withResendInvitation,
      withResetCredentials,
    };
  };

  const onApproveAction = async (id: string) => {
    const user = members.find(user => user.id === id);

    // Show the message with the email instead of ID.
    await acceptMember({ email: user?.email });
  };

  const onSendInvitation = async (id: string) => {
    const user = members.find(user => user.id === id);

    // Show the message with the email instead of ID.
    await resendInvitation({ email: user?.email });
  };

  const onRejectAction = async (id: string) => {
    const user = members.find(user => user.id === id);

    // Show the message with the email instead of ID.
    await rejectMember({ email: user?.email });
  };

  const onResetCredentials = async (id: string) => {
    if (!selectedMember) {
      return;
    }

    const RESETTING_CREDENTIALS_MESSAGE_KEY = `RESETTING_CREDENTIALS_${id}`;

    showMessage(`Resetting the account credentials for "${selectedMember.email}".`, {
      id: RESETTING_CREDENTIALS_MESSAGE_KEY,
    });

    try {
      await resetUserCredentials({ variables: { user: { id } } });

      showSuccess(
        `The account credentials for "${selectedMember.email}" have been successfully reset. Instructions will be sent to the user via email.`,
      );
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    } finally {
      dismiss(RESETTING_CREDENTIALS_MESSAGE_KEY);
    }
  };

  const onToggleActive = async (id: string, oldStatus: string, newStatus: string) => {
    try {
      await toggleActiveStatus({
        variables: { id, status: newStatus },
        context: {
          [SNACKBAR_SUCCESS_MESSAGE]: `Success! You've toggled user status from ${oldStatus} to ${newStatus}.`,
          [SNACKBAR_ERROR_MESSAGE]: `Error! Something went wrong... Try again later.`,
        },
      });
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    }
  };

  const filterClubs = (
    chipsArray: {
      hobbies: string[];
      clubs: string[];
    },
    member: MemberInfoFragment,
  ) => {
    return (
      !chipsArray.clubs.length ||
      chipsArray?.clubs?.some(club =>
        member?.activityClubs?.includes(
          USER_ACTIVITIES_OPTIONS.clubs.find(({ label }) => label === club)?.value || '',
        ),
      )
    );
  };

  const filterHobbies = (
    chipsArray: {
      hobbies: string[];
      clubs: string[];
    },
    member: MemberInfoFragment,
  ) => {
    return (
      !chipsArray.hobbies.length ||
      chipsArray?.hobbies?.some(hobby =>
        member?.hobbies?.includes(
          USER_ACTIVITIES_OPTIONS.hobbies.find(({ label }) => label === hobby)?.value || '',
        ),
      )
    );
  };

  const getMemberStatusComponent = (member: NormalizedMember) => {
    const options: ChooseStatusChipOption[] = [];

    const { withApproveAction, withActivesActions, withRejectAction, withResendInvitation } =
      getAvailableActions(member.userStatus);

    if (withResendInvitation) {
      options.push({
        label: 'Re-send Invitation',
        value: USER_STATUSES.invitationSent,
        onClick: () => onSendInvitation(member.id),
      });
    }

    if (withApproveAction && isEditAccessed) {
      options.push({
        label: 'Approve Request',
        value: USER_STATUSES.invitationSent,
        onClick: () => onApproveAction(member.id),
      });
    }

    if (withActivesActions) {
      const value =
        member.userStatus === USER_STATUSES.active ? USER_STATUSES.inactive : USER_STATUSES.active;
      const label = member.userStatus === USER_STATUSES.active ? 'Inactive' : 'Active';

      options.push({
        label,
        value,
        onClick: () => onToggleActive(member.id, member.userStatus, value),
      });
    }

    if (withRejectAction) {
      options.push({
        label: 'Reject',
        value: USER_STATUSES.rejected,
        onClick: () => onRejectAction(member.id),
      });
    }

    return <ChooseUserStatusChip status={member.userStatus} options={options} />;
  };

  const members = useMemo(() => {
    const response = tableData?.members.items ?? [];

    return response
      .filter(member => filterClubs(chipsArray, member))
      .filter(member => filterHobbies(chipsArray, member));
  }, [tableData]);

  const normalizedMembers = useMemo(() => {
    return normalizeMembers(members);
  }, [members]);

  const data = useMemo(() => {
    return normalizedMembers.map(member => {
      const userStatus = getMemberStatusComponent(member);
      return { ...member, userStatus };
    });
  }, [normalizedMembers, sortOption, getMemberStatusComponent]);

  const selectedMember = useMemo(() => {
    return normalizedMembers.find(user => user.id === currentRowId);
  }, [currentRowId, normalizedMembers]);

  const selectedMemberRoleAddons = useMemo(() => {
    const selected = members.find(user => user.id === currentRowId);
    return selected?.rolesAddons?.items ?? [];
  }, [currentRowId, members]);

  const spreadsheetActions = useMemo((): SpreadsheetCellActions => {
    const isPromoteToAdminActionVisible = !selectedMemberRoleAddons.find(
      ({ name }) => name === ADMIN_ROLE_ADDON,
    );

    const { withApproveAction, withRejectAction, withResendInvitation, withResetCredentials } =
      getAvailableActions();

    const options: SpreadsheetCellActions = [];

    if (isPromoteToAdminActionVisible && isEditAccessed && !withRejectAction) {
      options.push({
        id: 'promote_to_admin',
        title: 'Promote to Admin',
        onClickAction: (id: string) => promoteUser({ id }),
        icon: <ArrowCircleUp />,
      });
    }

    if (withApproveAction && isEditAccessed) {
      options.push({
        id: 'approve_request',
        title: 'Approve Request',
        onClickAction: onApproveAction,
        icon: <CheckCircle />,
      });
    }

    if (withResetCredentials) {
      options.push({
        id: 'reset_credentials',
        title: 'Reset Account Credentials',
        icon: <Mail />,
        onClickAction: onResetCredentials,
      });
    }

    options.push({
      id: 'view_details',
      title: 'View Details',
      onClickAction: (id: string) => {
        navigate(buildUrl(APP_URL.admin.members.information, { pathParams: { id } }));
      },
      icon: <Edit />,
    });

    if (isEditAccessed) {
      options.push({
        id: 'edit_roles',
        title: 'Edit User Roles',
        onClickAction: (id: string) => {
          onEditRolesModalOpen();
          setEditRolesModalMemberId(id);
        },
        icon: <PersonAdd />,
      });
    }

    options.push({
      id: 'message',
      title: 'Message',
      onClickAction: (id: string) => {
        const selected = members.filter(user => user.id === id);

        if (onOpenInboxModal) {
          onOpenInboxModal({
            isOpen: true,
            options: {
              members: selected,
              messageType: 'personal',
            },
          });
        }
      },
      icon: <ForumTwoTone />,
    });

    if (withRejectAction && isDeleteAccessed) {
      options.push({
        id: 'reject',
        title: 'Reject',
        onClickAction: onRejectAction,
        icon: <DeleteForeverSharp />,
      });
    }

    if (withResendInvitation) {
      options.push({
        id: 'ResendInvitation',
        title: 'Re-send Invitation',
        onClickAction: onSendInvitation,
        icon: <Mail />,
      });
    }

    return options;
  }, [
    selectedMember,
    getAvailableActions,
    isEditAccessed,
    isCreateAccessed,
    onApproveAction,
    isDeleteAccessed,
    onRejectAction,
    promoteUser,
    data,
    onOpenInboxModal,
  ]);

  const bulkActions: SpreadsheetBulkActions = useMemo(() => {
    const actions: SpreadsheetBulkActions = [
      {
        id: 'message',
        icon: <Forum />,
        label: 'Message',
        disabled: tableLoading || selected.length === 0,

        onClick(ids) {
          const selected = members.filter(user => ids.includes(user.id as string));

          if (onOpenInboxModal) {
            onOpenInboxModal({
              isOpen: true,
              options: { members: selected, messageType: 'personal' },
            });
          }
        },
      },
      {
        id: 'resend',
        icon: <Mail />,
        disabled: tableLoading,

        label:
          selected.length === 0 ? 'Re-send invitations' : `Re-send ${selected.length} invitations`,

        description:
          selected.length === 0
            ? 'Re-send invitations to all "Invitation Sent" users'
            : 'Re-send invitation to selected users.',

        onClick() {
          resendInvitations(bulkFilter);
        },
      },
      {
        id: 'approve',
        icon: <CheckCircle />,
        disabled: tableLoading,

        label: selected.length === 0 ? 'Approve all' : `Approve ${selected.length} users`,

        description:
          selected.length === 0
            ? 'Approve all "Pending" users'
            : 'Approve selected "Pending" users.',

        onClick() {
          acceptMembers(bulkFilter);
        },
      },
      {
        id: 'reject',
        icon: <DeleteForeverSharp />,
        disabled: tableLoading,

        label: selected.length === 0 ? 'Reject all' : `Reject ${selected.length} users`,

        description:
          selected.length === 0 ? 'Reject all "Pending" users' : 'Reject selected "Pending" users.',

        onClick() {
          rejectMembers(bulkFilter);
        },
      },
    ];

    return actions;
  }, [selected, members, bulkFilter, tableLoading]);

  return (
    <Fragment>
      <div css={containerCSS}>
        <Spreadsheet
          data={data}
          headlines={MembersSpreadsheetHeader}
          toolbarOptions={{
            filters: memberSpreadsheetFilters({ organizations, graduatingYears }),
            bulkActions,
            withPerPage: !withCustomActivityFilter,
            withDownload: true,
            downloadHandler: generateCSV,
            rawData: tableData?.members?.items ?? [],
            withUpload: true,
            withSearch: true,
            onUpload: handleUploadCSV,
          }}
          cellActions={spreadsheetActions}
          itemsCount={tableData?.members?.count ?? 0}
          loading={tableLoading || loadingAddons}
        />
      </div>

      <Modal
        dialogProps={{ open: isEditRolesModalOpen, onClose: onEditRolesModalClose }}
        titleProps={{ title: 'Edit User Roles' }}
      >
        <MemberEditRolesModal
          memberId={editRolesModalMemberId}
          onModalClose={onEditRolesModalClose}
        />
      </Modal>
    </Fragment>
  );
}

const containerCSS = css`
  display: grid;
  grid-template-columns: 1fr;

  & > * {
    min-width: 0;
  }
`;

export const getStatus = (status?: Maybe<string>) => {
  if (!status || status === USER_STATUSES.pending) {
    return 'pending';
  }

  if (status === USER_STATUSES.invitationSent) {
    return 'invited';
  }

  return status;
};

/** @deprecated Use `ChooseUserStatusChip` instead. */
export const getUserStatusComponent = (status: Maybe<string> | undefined) => {
  return <ChooseUserStatusChip status={getStatus(status)} />;
};

type NormalizedMember = ReturnType<typeof normalizeMembers>[number];

function normalizeMembers(members: MemberInfoFragment[]) {
  return members.map(member => {
    const supportiveOrganizationsCount = member.ownedOrganizations?.count ?? 0;
    const roles = member.rolesAddons?.items ?? [];
    const rolesNames = roles.map(role => role.name ?? 'UNKNOWN').join(', ');
    const postCount = (member.groupPosts?.count ?? 0) + (member.homeFeedPosts?.count ?? 0);

    return {
      id: member.id as string,
      name: formatUserName(member),
      email: member.email,
      type: member.affiliation,
      gender: member.gender,
      phone: formatToPhone(member.userPreferences?.phoneNumber),
      birthDate: member?.birthDate ? formatTableDate(member.birthDate) : '',
      graduatingYear: extractYear(member.graduatingYear),
      isSupporter: supportiveOrganizationsCount > 0 ? 'Yes' : 'No',
      posts: postCount,
      roles: rolesNames,
      userStatus: member.userStatus ?? USER_STATUSES.pending,
      createdAt: member.createdAt ? formatTableDate(member.createdAt) : '',
    };
  });
}

function transformExport(members: MemberInfoFragment[]) {
  return members.map(member => {
    const supportiveOrganizationsCount = member.ownedOrganizations?.count ?? 0;
    const roles = member.rolesAddons?.items ?? [];
    const rolesNames = roles.map(role => role.name ?? 'UNKNOWN').join(', ');
    const postCount = (member.groupPosts?.count ?? 0) + (member.homeFeedPosts?.count ?? 0);

    return {
      [MembersReportHeaders.name]: formatUserName(member),
      [MembersReportHeaders.email]: member.email,
      [MembersReportHeaders.type]: member.affiliation,
      [MembersReportHeaders.gender]: member.gender,
      [MembersReportHeaders.birthDate]: member?.birthDate ? formatTableDate(member?.birthDate) : '',
      [MembersReportHeaders.GraduatingYear]: extractYear(member.graduatingYear),
      [MembersReportHeaders.supporter]: supportiveOrganizationsCount > 0 ? 'Yes' : 'No',
      [MembersReportHeaders.posts]: postCount,
      [MembersReportHeaders.roles]: rolesNames,
      [MembersReportHeaders.createdOn]: member.createdAt ? formatTableDate(member.createdAt) : '',
      [MembersReportHeaders.MemberSince]: member.registrationCompletedAt
        ? formatTableDate(member.registrationCompletedAt)
        : '',
      [MembersReportHeaders.status]: member.userStatus ?? USER_STATUSES.pending,
    };
  });
}
