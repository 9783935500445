import { Fragment } from 'react';
import { css, Theme } from '@emotion/react';
import { Box, Button } from '@material-ui/core';
import { useModalState, useSchoolConfiguration } from 'shared/hooks';

import { Modal, Typography } from '../ui';
import { MobileShortcutInstructionsModal } from './MobileShortcutInstructionsModal';
import { SchoolAvatar } from '../symbols';

interface Props {
  onModalClose: () => void;
}

const avatarBoxCss = (theme: Theme) => css`
  background-color: ${theme.palette.primary.main};
  border-radius: 2px;
  border: 1px solid ${theme.palette.primary.main};
`;

const commonButtonsCSS = (theme: Theme) => css`
  width: 100%;
  padding: ${theme.spacing(1)}px ${theme.spacing(10)}px;
`;

const cancelButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS(theme)};
  color: ${theme.palette.text.primary};
  border: 1px solid ${theme.palette.text.primary};
`;

const submitButtonCSS = (theme: Theme) => css`
  ${commonButtonsCSS(theme)};
  background-color: ${theme.palette.primary.dark};
  color: #ffffff;
`;

const textCss = (theme: Theme) => css`
  color: ${theme.palette.primary.main};
  line-height: 18px;
`;

export function MobileShortcutModal({ onModalClose: onPreviewShortcutModalClose }: Props) {
  const { data: configuration } = useSchoolConfiguration();

  const {
    show: isMobileShortcutInstructionsModalOpen,
    open: onInstructionsModalOpen,
    close: onInstructionsModalClose,
  } = useModalState();

  return (
    <Fragment>
      <Modal
        dialogProps={{
          open: isMobileShortcutInstructionsModalOpen,
          onClose: onInstructionsModalClose,
          fullWidth: true,
          maxWidth: 'xs',
        }}
        dialogContentWidth={280}
      >
        <MobileShortcutInstructionsModal onModalClose={onInstructionsModalClose} />
      </Modal>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={4}
        gridGap={25}
      >
        <Box css={avatarBoxCss}>
          <SchoolAvatar />
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography css={textCss} variant="body1">
            Create a Home Screen icon /
          </Typography>
          <Typography css={textCss} variant="body1">
            Shortcut for Columbus Connects
          </Typography>
          <Typography css={textCss} variant="body1">
            on your Smartphone
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gridGap={10}>
          <Button css={submitButtonCSS} onClick={onInstructionsModalOpen} variant="contained">
            LEARN HOW
          </Button>
          <Button
            css={cancelButtonCSS}
            onClick={onPreviewShortcutModalClose}
            size="medium"
            variant="outlined"
          >
            GOT IT
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
}
