import { Forum, Business } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import { OrganizationItemFragment } from 'shared/graphql';

import { Buttons } from './ExplorerBusinessCard.styles';
import { OrganizationLikeButton } from 'features/organizations/components';

export interface ExplorerBusinessCardButtonsProps {
  data: OrganizationItemFragment;

  withLike: boolean;
  withMessage: boolean;
  withViewProfile: boolean;

  onOpenProfile?(): void;
  onOpenMessage?(): void;
}

export function ExplorerBusinessCardButtons(props: ExplorerBusinessCardButtonsProps) {
  return (
    <Buttons>
      {props.withLike && <LikeButton organization={props.data} />}
      {props.withMessage && <MessageButton onClick={props.onOpenMessage} />}
      {props.withViewProfile && <ViewProfileButton onClick={props.onOpenProfile} />}
    </Buttons>
  );
}

function LikeButton(props: { organization: OrganizationItemFragment }) {
  return <OrganizationLikeButton size="small" organization={props.organization} />;
}

function MessageButton(props: { onClick?(): void }) {
  return (
    <Button
      size="small"
      color="primary"
      startIcon={<Forum fontSize="small" />}
      onClick={props.onClick}
    >
      Message
    </Button>
  );
}

function ViewProfileButton(props: { onClick?(): void }) {
  return (
    <Button
      size="small"
      color="primary"
      startIcon={<Business fontSize="small" />}
      onClick={props.onClick}
    >
      View Profile
    </Button>
  );
}
