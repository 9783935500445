import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { HelpFormValues } from 'shared/components/dialogs';

import {
  useLeftMenuDrawer,
  useRedeemModal,
  useClaimModal,
  useMarkPost,
  useCreatePost,
  useEditPost,
  useAboutModal,
  useInviteModal,
  useCurrentDiscount,
  useResponsive,
  useShowContentCurationBubble,
  useModalState,
  useToast,
} from 'shared/hooks';

function useGlobalStateValues() {
  const { isMobile } = useResponsive();

  const toasts = useToast();
  const leftMenuDrawer = useLeftMenuDrawer(!isMobile);
  const redeemModal = useRedeemModal(false);
  const claimModal = useClaimModal(false);
  const markPost = useMarkPost(false);
  const createPost = useCreatePost(false);
  const editPost = useEditPost(false);
  const aboutModal = useAboutModal(false);
  const inviteModal = useInviteModal(false);
  const currentDiscount = useCurrentDiscount(null);
  const contentCurationBubble = useShowContentCurationBubble(true);
  const helpModal = useModalState<HelpFormValues>();

  useEffect(() => {
    if (isMobile) {
      leftMenuDrawer.close();
    } else {
      leftMenuDrawer.open();
    }
  }, [isMobile]);

  return {
    toasts,
    leftMenuDrawer,
    redeemModal,
    claimModal,
    markPost,
    createPost,
    editPost,
    aboutModal,
    inviteModal,
    currentDiscount,
    /** @deprecated */
    contentCurationBubble,
    helpModal,
  };
}

type GlobalState = ReturnType<typeof useGlobalStateValues>;

const GlobalStateContext = createContext<GlobalState>({} as any);

export function GlobalStateProvider(props: PropsWithChildren<unknown>) {
  const values = useGlobalStateValues();

  return <GlobalStateContext.Provider value={values}>{props.children}</GlobalStateContext.Provider>;
}

export function useGlobalState() {
  return useContext(GlobalStateContext);
}
