/**
 * Creates an array of numbers from 0 to the specified length - 1.
 *
 * @param length - The length of the range to create.
 * @returns An array of numbers from 0 to length - 1.
 */
export function createSimpleRange(length) {
    return Array.from(Array(length).keys());
}
/**
 * Creates an array of numbers progressing from `start` up to, but not including, `end`.
 * A `step` of `-1` is used if a negative `start` is specified without an `end` or `step`.
 *
 * @param start - The start of the range.
 * @param end - The end of the range.
 * @param step - The value to increment or decrement by. Defaults to `1`.
 * @returns An array of numbers from `start` to `end` with a step of `step`.
 */
export function createRange(start, end, step = 1) {
    return Array(Math.ceil((end + 1 - start) / step))
        .fill(start)
        .map((x, y) => x + y * step);
}
