import { HOME_FEED_ITEM_TYPES } from './homeFeedItem';
import { SELECT_OPTION_ALL } from './misc';
export const POST_STATUSES = {
    approved: 'approved',
    rejected: 'rejected',
    pending: 'pending',
    reportReviewPending: 'reportReviewPending',
};
export const POST_REPORT_STATUS = {
    postAllowed: 'postAllowed',
    postDeleted: 'postDeleted',
    pendingReview: 'pendingReview',
};
export const POST_REPORT_STATUS_TITLES = {
    [POST_REPORT_STATUS.postAllowed]: 'Post allowed',
    [POST_REPORT_STATUS.postDeleted]: 'Post deleted',
    [POST_REPORT_STATUS.pendingReview]: 'Pending review',
};
/** @deprecated Use `HOME_FEED_ITEMS_POST_TYPES` instead. */
export const POST_TYPES = {
    all: 'all',
    schoolPosts: 'schoolPosts',
    memberPosts: 'memberPosts',
    jobPosts: 'jobPosts',
    events: 'events',
    groupPosts: 'groupPosts',
    legacyPost: 'legacyPost',
    homeFeed: 'homeFeed',
};
export const POST_TYPES_TITLES = {
    [SELECT_OPTION_ALL]: 'All Posts',
    [HOME_FEED_ITEM_TYPES.SCHOOL]: 'School Posts',
    [HOME_FEED_ITEM_TYPES.HOME]: 'Member Posts',
    [HOME_FEED_ITEM_TYPES.JOB_OFFER]: 'Job Posts',
    [HOME_FEED_ITEM_TYPES.DISCOUNT]: 'Discounts',
    [HOME_FEED_ITEM_TYPES.EVENT]: 'Events',
    [HOME_FEED_ITEM_TYPES.GROUP]: 'Groups Posts',
    [HOME_FEED_ITEM_TYPES.LEGACY]: 'Legacy Posts',
};
export const POST_REACTIONS = {
    replyPost: 'replyPost',
    replyComment: 'replyComment',
    replyTopic: 'replyTopic',
    likePost: 'likePost',
    likeComment: 'likeComment',
    helpfulComment: 'helpfulComment',
    notHelpfulComment: 'notHelpfulComment',
};
