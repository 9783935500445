import { useMemo } from 'react';
import { Theme, css } from '@emotion/react';
import { Grid, Box, CircularProgress, Divider, Paper } from '@mui/material';

import { CenterLayout } from 'shared/components/layouts';
import { Button, Form, Icon, ImageInput, Typography } from 'shared/components/ui';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { useSchoolConfigurationUpdate } from 'shared/hooks/useSchoolConfigurationUpdate';
import { SchoolConfiguration } from 'shared/graphql';

import emailBannerExample from '../../assets/images/emailBannerExample.png';
import emailLogoExample from '../../assets/images/emailLogoExample.png';
import splashImageExample from '../../assets/images/splashImageExample.png';
import { SettingsTitle } from '../SettingsTitle';
import { CustomColorPicker } from './CustomColorPicker';
import { ModalHint } from './ModalHint';

const COLOR_CIRCLE_SIZE = 30;

const contentCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 2rem;

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
    grid-template-columns: 1fr;
  }
`;

const sectionCSS = css`
  display: flex;
  min-width: 0;
  flex-direction: column;
  padding: 1rem;
  flex: 1;
`;

const uploadIconCSS = (theme: Theme) => css`
  font-size: ${theme.typography.fontSize + 11}px;
  fill: ${theme.palette.primary.light};
`;

const uploadPictureCSS = (theme: Theme) => css`
  color: ${theme.palette.primary.light};
`;

const uploadFileBoxCSS = (theme: Theme) => css`
  height: 6rem;
  background-color: #f6f8fa;
  border-style: dashed;
  border-width: 1px;
  border-color: ${theme.palette.divider};
  border-radius: 4px;
  display: grid;
  grid-template-rows: min-content min-content;
  align-items: center;
  justify-items: center;
  align-content: center;
  cursor: pointer;
`;

const blockTitleCss = (theme: Theme) => css`
  color: ${theme.palette.primary.light};
  font-size: ${theme.typography.fontSize + 6}px;
`;

const colorCircleCss = (bgColor: string) => theme =>
  css`
    width: ${COLOR_CIRCLE_SIZE}px;
    height: ${COLOR_CIRCLE_SIZE}px;
    border-radius: 50%;
    background: ${bgColor};
    margin-right: ${theme.spacing(2)}px;
    cursor: pointer;
    border: 1px solid ${theme.palette.text.secondary};
  `;

const colorItemTitleCss = (theme: Theme) => css`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  color: ${theme.palette.primary.light};
  font-size: ${theme.typography.fontSize + 2}px;
`;

const colorHexTextCss = (theme: Theme) => css`
  margin-right: ${theme.spacing(2)}px;
  line-height: initial;
  font-weight: 500;
`;

const colorItemDescriptionCss = css`
  font-weight: 500;
  line-height: 22px;
`;

type ColorItem = {
  name: string;
  text: string;
  description: string;
};

const COLOR_ITEMS: Array<ColorItem> = [
  { name: 'primary', text: 'Primary color', description: 'Used in titles, subtitles and buttons' },
  {
    name: 'secondary',
    text: 'Secondary color',
    description: 'Primary icons and other secondary elements',
  },
  {
    name: 'sidebar',
    text: 'Sidebar & mobile nav background color',
    description: 'Desktop sidebar background and mobile site menu background',
  },
  {
    name: 'sidebarFont',
    text: 'Sidebar Font color',
    description: 'Desktop and mobile sidebar font color',
  },
  { name: 'accent1', text: 'Accent color #1', description: 'Text links, action icons' },
  { name: 'accent2', text: 'Accent color #2  ', description: 'Text links, action icons' },
];

type ImageType = {
  name: string;
  text: string;
  description: string;
  maxWidth: number;
  maxHeight: number;
  hintImage: string;
  modalTitle: string;
};

const IMAGE_ITEMS: Array<ImageType> = [
  {
    name: 'emailBanner',
    text: 'Email banner image',
    description:
      'This banner will appear in most email communications. Should be a 600px wide image in png or jpg format',
    maxWidth: 600,
    maxHeight: 300,
    hintImage: emailBannerExample,
    modalTitle: 'Email banner image',
  },
  {
    name: 'emailLogo',
    text: 'Logo for email and login screens',
    description: 'Max. 350px wide in "png" or "jpg" format',
    maxWidth: 350,
    maxHeight: 140,
    hintImage: emailLogoExample,
    modalTitle: 'Logo for email and login screens',
  },
  {
    name: 'browserLogo',
    text: 'Browser Icon',
    description: 'Max. 42px square in "png" or "jpg" format',
    maxWidth: 42,
    maxHeight: 42,
    hintImage: emailLogoExample,
    modalTitle: 'Customize the browser icon of the application',
  },
  {
    name: 'applicationLogo',
    text: 'Application Logo',
    description: 'Max. 144px square in "png" or "jpg" format',
    maxWidth: 144,
    maxHeight: 144,
    hintImage: emailLogoExample,
    modalTitle: 'Customize the application logo of the application',
  },
  {
    name: 'splashImage',
    text: 'Splash image for login/signup screens',
    description: 'Max. 1200px wide in png or jpg format',
    maxWidth: 1200,
    maxHeight: 1400,
    hintImage: splashImageExample,
    modalTitle: 'Splash image for login/signup screens',
  },
];

const DEFAULT_COLOR = '#FFFFFF';

type FormData = Pick<SchoolConfiguration, 'images' | 'colorSchema'>;

const reset = (resetForm: () => void) => () => resetForm();

export function CustomizationContent() {
  const { data: configuration, loading } = useSchoolConfiguration();
  const { showSuccess } = useToast();
  const { mutate: schoolConfigurationUpdate } = useSchoolConfigurationUpdate();

  const onSubmit = async (formData: FormData) => {
    await schoolConfigurationUpdate({
      colorSchema: { ...formData.colorSchema },
      images: { ...formData.images },
    });

    showSuccess('Settings updated successfully');
  };

  const initials = useMemo<FormData>(
    () => ({
      colorSchema: {
        primary: configuration?.colorSchema?.primary ?? DEFAULT_COLOR,
        secondary: configuration?.colorSchema?.secondary ?? DEFAULT_COLOR,
        sidebar: configuration?.colorSchema?.sidebar ?? DEFAULT_COLOR,
        sidebarFont: configuration?.colorSchema?.sidebarFont ?? DEFAULT_COLOR,
        accent1: configuration?.colorSchema?.accent1 ?? DEFAULT_COLOR,
        accent2: configuration?.colorSchema?.accent2 ?? DEFAULT_COLOR,
      },
      images: {
        emailBanner: configuration?.images?.emailBanner ?? null,
        emailLogo: configuration?.images?.emailLogo ?? null,
        browserLogo: configuration?.images?.browserLogo ?? null,
        applicationLogo: configuration?.images?.applicationLogo ?? null,
        splashImage: configuration?.images?.splashImage ?? null,
      },
    }),
    [configuration],
  );

  if (loading) {
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <SettingsTitle>Customization</SettingsTitle>

      <Form<FormData> initialValues={initials} onSubmit={onSubmit}>
        {({ isSubmitting, setFieldValue, values, resetForm, dirty }) => (
          <Box css={contentCSS}>
            <Paper css={sectionCSS} elevation={1} variant="elevation">
              <Typography css={blockTitleCss} variant="subtitle1">
                Color scheme
              </Typography>

              {COLOR_ITEMS.map((color, index, colorsArray) => (
                <Box key={color.name} py={1}>
                  <Typography css={colorItemTitleCss} variant="subtitle2">
                    {color.text}
                  </Typography>

                  <Box display="flex" alignItems="center" py={1}>
                    <CustomColorPicker
                      value={values.colorSchema?.[color.name] ?? DEFAULT_COLOR}
                      onChange={(value: string) => {
                        setFieldValue(`colorSchema.${color.name}`, value);
                      }}
                      pickerTrigger={
                        <Box css={colorCircleCss(values.colorSchema?.[color.name] ?? '')} />
                      }
                    />

                    <Typography variant="body1" css={colorHexTextCss}>
                      {values.colorSchema?.[color.name] ?? ''}
                    </Typography>

                    <Typography variant="body2" css={colorItemDescriptionCss} color="textSecondary">
                      {color.description}
                    </Typography>
                  </Box>
                  {index !== colorsArray.length - 1 && <Divider />}
                </Box>
              ))}
            </Paper>

            <Paper css={sectionCSS} elevation={1} variant="elevation">
              <Typography css={blockTitleCss} variant="subtitle1">
                Email and login images
              </Typography>

              {IMAGE_ITEMS.map((image, index, imagesArray) => (
                <Box key={image.name} py={1}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                      <Typography css={colorItemTitleCss} variant="subtitle2">
                        {image.text}
                        <ModalHint
                          title={image.modalTitle}
                          maxWidth={image.maxWidth}
                          maxHeight={image.maxHeight}
                          hintImage={image.hintImage}
                        />
                      </Typography>
                      <Typography
                        variant="body1"
                        css={colorItemDescriptionCss}
                        color="textSecondary"
                      >
                        {image.description}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} pt={1.5}>
                      <ImageInput
                        name={image.name}
                        maxFiles={1}
                        initialValue={initials.images?.[image.name]}
                        pickerOptions={{
                          imageMax: [image.maxWidth, image.maxHeight],
                        }}
                        onChange={value => {
                          setFieldValue(`images.${image.name}`, value);
                        }}
                        customPicker={
                          <Box css={uploadFileBoxCSS}>
                            <Icon css={uploadIconCSS} name="FileUpload" variant="filled" />

                            <Typography css={uploadPictureCSS} variant="body2">
                              Upload an image
                            </Typography>
                          </Box>
                        }
                      />
                    </Grid>
                  </Grid>

                  <Box pt={1.5} hidden={index === imagesArray.length - 1}>
                    <Divider />
                  </Box>
                </Box>
              ))}
            </Paper>

            <Box display="flex" justifyContent="end">
              <Box mr={1}>
                <Button
                  disabled={isSubmitting || !dirty}
                  size="medium"
                  variant="outlined"
                  onClick={reset(resetForm)}
                >
                  Cancel
                </Button>
              </Box>
              <Button
                color="primary"
                disabled={!dirty}
                disableElevation
                variant="contained"
                type="submit"
                loading={isSubmitting}
              >
                Save changes
              </Button>
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
}
