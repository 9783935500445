import { ThemeOptions as DeprecatedThemeOptions } from '@material-ui/core';
import { ThemeOptions } from '@mui/material';

export const PRIMARY_MAIN = '#283E55';
export const SECONDARY_MAIN = '#f50057';

export const TEXT_DARK_COLOR = '#ffffff';
export const TEXT_LIGHT_COLOR = '#111111';

const TEXT_BASE_SIZE = 15;

export const customPalette: ThemeOptions & DeprecatedThemeOptions = {
  palette: {
    contrastThreshold: 3.4,

    background: {
      default: '#ffffff',
      light: '#f8f8f8',
      lightBlue: '#236B90',
      dark: '#292929',
    },

    primary: {
      main: PRIMARY_MAIN,
      dark: '#162D46', // colorSchema.primary
      side: '#152D46', // colorSchema.sidebar
      sideFont: '#FFFFFF', // colorSchema.sidebarFont
      light: '#295F7B', // colorSchema.accent2
    },

    secondary: {
      main: SECONDARY_MAIN,
      light: '#EF5C5C', // colorSchema.accent1
    },

    white: '#FFFFFF',
    muted: '#d2d2d2',
    border: '#E7E7E7',
    borderlight: '#DBDBDB',
    borderGrey: '#f4f4f4',
    text: { primary: '#212121', secondary: '#8B8B8B', light: '#9B9B9B' },

    success: { main: '#01B9A1' },
    error: { main: '#EF5C5C' },
    warning: { main: '#FFC107' },
  },

  typography: {
    fontFamily: 'Barlow',
    fontSize: TEXT_BASE_SIZE,
    htmlFontSize: TEXT_BASE_SIZE,

    h1: {
      fontSize: '3rem',
      fontWeight: 600,
    },

    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },

    h3: {
      fontSize: '2rem',
      fontWeight: 600,
    },

    h4: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },

    h5: {
      fontSize: '1.6rem',
      fontWeight: 500,
    },

    h6: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },

    subtitle1: {
      fontSize: '1.8rem',
      fontWeight: 600,
    },

    subtitle2: {
      fontSize: '1.6rem',
      fontWeight: 500,
    },

    subtitle3: {
      fontSize: '1.6rem',
      fontWeight: 500,
    },

    subtitle4: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },

    subtitle5: {
      fontSize: '1rem',
      fontWeight: 500,
    },

    body1: {
      fontSize: TEXT_BASE_SIZE,
      lineHeight: 1.5,
    },
  },

  spacing: 8,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  components: {
    MuiLink: {
      defaultProps: {
        color: 'secondary',
      },

      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        sizeLarge: ({ theme }) => ({
          minHeight: '3.5rem',
          fontSize: theme.typography.body1.fontSize,
        }),
      },
    },
  },

  overrides: {
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },

    MuiButton: {
      sizeLarge: {
        minHeight: '3.5rem',
        fontSize: '1rem',
      },
    },
  },
};
