import { Theme, css } from '@emotion/react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { AppBar, Box, Breadcrumbs, IconButton, Toolbar, Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import { useGlobalState } from 'providers';
import { Button, HomeSearchButton, Icon, Modal } from 'shared/components/ui';
import { NotificationsPopover } from 'shared/features/notifications/components';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { getLocationPaths } from 'shared/routes';
import { ADMIN_BREADCRUMBS, APP_URL, ROUTES, USER_BREADCRUMBS } from 'shared/constants';
import { useCrudPermissions, useModalState, useSchoolConfiguration } from 'shared/hooks';

import { HeaderNavigationPopover } from './HeaderNavigationPopover';
import { HeaderNavigationUserProfile } from './HeaderNavigationUserProfile';
import { InviteMemberModal } from '../../dialogs/InviteModal/components/InviteMemberModal';

type HeaderNavigationProps = {
  isAdminRoute: boolean;
  withBreadcrumbs: boolean;
};

const popoverContainerCss = css`
  display: inline-flex;
  justify-content: center;
  gap: 0.5rem;
`;

const layoutCSS = css`
  display: flex;
  box-shadow: 0 0.5rem 1rem 0.01rem rgba(0, 0, 0, 0.05);
  background-color: white;
`;

const searchBarCSS = (theme: Theme) => css`
  border: solid 1px;
  border-color: ${theme.palette.divider};
`;

const toolbarCSS = (options?: { withSearchBar?: boolean }) => css`
  display: grid;
  grid-template-columns: ${options?.withSearchBar ? '1fr minmax(10rem, 1fr) 1fr' : '1fr 1fr'};
  gap: 1rem;

  & > * {
    min-width: 0;
  }
`;

const optionsContainerCSS = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  gap: 0.5rem;
`;

const breadcrumbsCSS = css`
  &,
  & ol,
  & li {
    flex-wrap: nowrap;
    overflow: hidden;
  }
`;

const breadcrumbLabelCSS = (theme: Theme) => css`
  width: 100%;
  display: block;
  text-decoration: none;
  color: ${theme.palette.text.secondary};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface BreadcrumbsPathProps {
  path: string;
  index: number;
  numberOfRoutes: number;
  isAdminRoute: boolean;
}

function BreadcrumbsPath({ path, index, numberOfRoutes, isAdminRoute }: BreadcrumbsPathProps) {
  const menuItems = isAdminRoute ? ADMIN_BREADCRUMBS : USER_BREADCRUMBS;

  const area = menuItems.find(item => {
    const match = matchPath(path, { path: item.path, exact: true });
    return Boolean(match);
  });

  const isCurrentRoute = index === numberOfRoutes - 1;

  if (!area) {
    return null;
  }

  const areaInfo = matchPath(path, { path: area?.path });

  return (
    <Box key={index}>
      <Typography
        css={breadcrumbLabelCSS}
        component={isCurrentRoute ? 'div' : Link}
        to={areaInfo?.url}
      >
        {area.title}
      </Typography>
    </Box>
  );
}

const shouldNotRenderBreadcrumb = (path: string) => {
  // paths without route component
  const shouldNotRenderPaths = [
    APP_URL.user.home.index,
    APP_URL.user.explorer.profileIndex,
    APP_URL.user.collective.detailsIndex,
    APP_URL.user.home.searchIndex,
    APP_URL.user.mail.index,
    APP_URL.admin.root.index,
    APP_URL.admin.business.detailsRoot,
    APP_URL.admin.members.memberRoot,
    APP_URL.admin.mail.index,
  ];

  return shouldNotRenderPaths.every(p => !matchPath(path, { path: p, exact: true }));
};

export function HeaderNavigation({ isAdminRoute, withBreadcrumbs = true }: HeaderNavigationProps) {
  const { show: isInviteModalOpen, open: onModalOpen, close: onModalClose } = useModalState();

  const { configuration } = useSchoolConfiguration();
  const { leftMenuDrawer } = useGlobalState();
  const { user, loading } = useCurrentUser();
  const { membersPermissions } = useCrudPermissions();
  const { pathname } = useLocation();

  const schoolTitle = configuration?.fullName ?? configuration?.name ?? 'Jebel';

  const paths = getLocationPaths(pathname, 1);
  const pathLength = paths.length;

  const isHome = pathname === ROUTES.user.home.index;

  return (
    <AppBar css={layoutCSS} position="relative">
      <Toolbar css={toolbarCSS({ withSearchBar: isHome })}>
        <Box display="flex" alignItems="center" overflow="hidden">
          <IconButton
            color="default"
            size="medium"
            onClick={leftMenuDrawer.isOpened ? leftMenuDrawer.close : leftMenuDrawer.open}
          >
            <Icon
              name={leftMenuDrawer.isOpened ? 'FormatIndentDecrease' : 'FormatIndentIncrease'}
              variant="filled"
              color="action"
            />
          </IconButton>

          {withBreadcrumbs ? (
            <Breadcrumbs
              css={breadcrumbsCSS}
              expandText="Show path"
              itemsAfterCollapse={1}
              itemsBeforeCollapse={1}
              maxItems={8}
              separator="/"
            >
              <Typography
                css={breadcrumbLabelCSS}
                component={Link}
                to={isAdminRoute ? APP_URL.admin.home.index : APP_URL.user.home.index}
              >
                {schoolTitle}
              </Typography>

              {paths.filter(shouldNotRenderBreadcrumb).map((path, index) => (
                <BreadcrumbsPath
                  key={path}
                  path={path}
                  index={index}
                  numberOfRoutes={pathLength}
                  isAdminRoute={isAdminRoute}
                />
              ))}
            </Breadcrumbs>
          ) : (
            <Typography css={breadcrumbLabelCSS}>{schoolTitle}</Typography>
          )}
        </Box>

        {isHome && <HomeSearchButton css={searchBarCSS} />}

        <Box css={optionsContainerCSS}>
          <Modal
            dialogProps={{
              open: isInviteModalOpen,
              onClose: onModalClose,
              fullWidth: true,
            }}
            titleProps={{ title: 'Invite friends' }}
            isVisibleDivider
          >
            <InviteMemberModal onClose={onModalClose} />
          </Modal>

          {membersPermissions.add && (
            <Button
              color="primary"
              disabled={false}
              loading={false}
              size="medium"
              variant="outlined"
              onClick={onModalOpen}
            >
              Invite
            </Button>
          )}

          <NotificationsPopover />

          <HeaderNavigationPopover
            user={user}
            userLoading={loading}
            isAdminRoute={isAdminRoute}
            target={
              <div css={popoverContainerCss}>
                <HeaderNavigationUserProfile user={user} userLoading={loading} />

                <IconButton color="default">
                  <KeyboardArrowDown />
                </IconButton>
              </div>
            }
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
