import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useSnackbar } from 'notistack';

import { DISCOUNT_STATUSES, SORT } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import {
  ChooseStatusChipOption,
  Icon,
  SNACK_TYPES,
  Typography,
  createSnackMessage,
} from 'shared/components/ui';
import { DISCOUNT_CATEGORIES } from 'shared/constants/discounts';
import { useSpreadsheetSearch } from 'shared/features/search';
import { Spreadsheet, SpreadsheetCellActions } from 'shared/features/spreadsheet';
import { useSpreadsheetContext } from 'shared/features/spreadsheet/providers';
import {
  AdminDiscountFragment,
  AdminDiscountsSpreadsheetListQuery,
  AdminDiscountsSpreadsheetListQueryVariables,
  DiscountFilter,
} from 'shared/graphql';
import { useDownloadLazyQueryCSV, useSchoolConfiguration } from 'shared/hooks';
import { formatTableDate } from 'shared/utils/date';
import { getFileNameWithTimestamp } from 'shared/utils/file';

import {
  COLUMBUS_HIGH_SCHOOL,
  DiscountReportHeaders,
  DiscountsSpreadsheetFilters,
  DiscountsSpreadsheetHeader,
} from '../constants';
import { useDiscountInteraction } from '../hooks';
import { ADMIN_DISCOUNTS_LIST_QUERY } from '../query';
import { getDiscountStatus } from '../utils';
import { ChooseDiscountStatusChip } from './ChooseDiscountStatusChip';

const discountTableNameCSS = css`
  width: 100%;
  text-align: left;
  font-size: 14px;
`;

interface Props {
  onSelectDiscount: (discount?: AdminDiscountFragment) => void;
}

export function DiscountsSpreadsheet({ onSelectDiscount }: Props) {
  const { configuration: school } = useSchoolConfiguration();
  const { queryParams, setSortOption, selected } = useSpreadsheetContext();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSortOption({
      order: SORT.desc,
      headcellId: 'createdAt',
      sort: {
        createdAt: SORT.desc,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { tableData, tableLoading, queryVariables } =
    useSpreadsheetSearch<AdminDiscountsSpreadsheetListQuery>({
      query: ADMIN_DISCOUNTS_LIST_QUERY,
      searchingFields: ['title', 'category', 'organization.name'],
      queryVariables: queryParams,
      fetchPolicy: 'network-only',
    });

  const discounts = useMemo(() => tableData?.discounts?.items ?? [], [tableData]);
  const discountsCount = tableData?.discounts?.count ?? 0;

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<DiscountFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [downloadCSV] = useDownloadLazyQueryCSV<
    AdminDiscountsSpreadsheetListQuery,
    AdminDiscountsSpreadsheetListQueryVariables
  >(ADMIN_DISCOUNTS_LIST_QUERY, {
    filename: getFileNameWithTimestamp('Discounts.csv'),
    transform: transformExport,

    variables: {
      filter: downloadFilter,
    },
  });

  const { onDiscountUpdate, creatingDiscount, updatingDiscount, updatingDiscountStatuses } =
    useDiscountInteraction();

  const isLoading =
    tableLoading || creatingDiscount || updatingDiscount || updatingDiscountStatuses;

  const onDiscountApprove = useCallback(
    async (discountId: string) => {
      if (!discountId) {
        return;
      }

      const discount = discounts.find(el => el.id === discountId);

      if (!discount) {
        return;
      }

      const newStatus = getDiscountStatus(discount?.startDate, discount?.expirationDate);

      if (newStatus === DISCOUNT_STATUSES.expired) {
        enqueueSnackbar('The discount is expired, please change the dates to approve', {
          content: createSnackMessage(SNACK_TYPES.warning),
        });

        return;
      }

      await onDiscountUpdate({ status: DISCOUNT_STATUSES.active, id: discountId });
    },
    [enqueueSnackbar, onDiscountUpdate, discounts],
  );

  const onDiscountReject = useCallback(
    async (id: string) => {
      await onDiscountUpdate({ status: DISCOUNT_STATUSES.rejected, id });
    },
    [onDiscountUpdate],
  );

  const onDiscountInactivate = useCallback(
    async (id: string) => {
      await onDiscountUpdate({ status: DISCOUNT_STATUSES.inactive, id });
    },
    [onDiscountUpdate],
  );

  const spreadsheetActions: SpreadsheetCellActions = [
    {
      id: 'Approve Discount',
      title: 'Approve Discount',
      onClickAction: onDiscountApprove,
      icon: <Icon name="CheckCircle" />,

      checkHidden(id) {
        const discount = discounts.find(discount => discount.id === id);
        return discount?.status !== DISCOUNT_STATUSES.pending;
      },
    },
    {
      id: 'View Details',
      title: 'View Details',
      icon: <Icon name="Edit" />,

      onClickAction(id: string) {
        const discount = discounts.find(discount => discount.id === id);
        onSelectDiscount(discount);
      },
    },
    {
      id: 'Reject',
      title: 'Reject',
      onClickAction: onDiscountReject,
      icon: <Icon name="DeleteForeverSharp" />,

      checkHidden(id) {
        const discount = discounts.find(discount => discount.id === id);
        return discount?.status !== DISCOUNT_STATUSES.pending;
      },
    },

    {
      id: 'Inactive',
      title: 'Inactive',
      icon: <Icon name="DeleteForeverSharp" />,
      onClickAction: onDiscountInactivate,

      checkHidden(id) {
        const discount = discounts.find(discount => discount.id === id);
        return discount?.status !== DISCOUNT_STATUSES.active;
      },
    },
  ];

  const newData = useMemo(() => {
    return discounts.map(discount => {
      const options: ChooseStatusChipOption[] = [];

      const category = DISCOUNT_CATEGORIES.find(category => category.key === discount.category);
      const createdBy = discount.organization?.name ?? school?.fullName ?? '-';

      if (discount.status === DISCOUNT_STATUSES.pending) {
        options.push({
          value: DISCOUNT_STATUSES.active,
          label: 'Approve',
          async onClick() {
            await onDiscountApprove(discount.id as string);
          },
        });

        options.push({
          value: DISCOUNT_STATUSES.rejected,
          label: 'Reject',
          async onClick() {
            await onDiscountReject(discount.id as string);
          },
        });
      }

      if (discount.status === DISCOUNT_STATUSES.active) {
        options.push({
          value: DISCOUNT_STATUSES.rejected,
          label: 'Inactive',
          async onClick() {
            await onDiscountInactivate(discount.id as string);
          },
        });
      }

      return {
        id: discount.id as string,
        status: <ChooseDiscountStatusChip status={discount.status as string} options={options} />,
        name: (
          <Typography css={discountTableNameCSS} onClick={() => onSelectDiscount(discount)}>
            {discount.title}
          </Typography>
        ),
        category: category?.value,
        createdBy: `${createdBy}`,
        expiredDate: formatTableDate(discount.expirationDate),
        createdAt: formatTableDate(discount.createdAt),
        redeemCount: `${discount.usersRedeemedCount || 0} times`,
      };
    });
  }, [
    discounts,
    school?.fullName,
    onDiscountApprove,
    onDiscountReject,
    onDiscountInactivate,
    onSelectDiscount,
  ]);

  return (
    <Spreadsheet
      data={newData}
      headlines={DiscountsSpreadsheetHeader}
      cellActions={spreadsheetActions}
      itemsCount={discountsCount}
      loading={isLoading}
      toolbarOptions={{
        filters: DiscountsSpreadsheetFilters,
        withPerPage: true,
        withDownload: true,
        downloadHandler: downloadCSV,
        rawData: discounts,
      }}
    />
  );
}

function transformExport(response: AdminDiscountsSpreadsheetListQuery) {
  const discounts = response.discounts.items ?? [];

  return discounts.map(discount => {
    return {
      [DiscountReportHeaders.name]: discount.title,
      [DiscountReportHeaders.category]: discount.category,
      [DiscountReportHeaders.createdBy]: discount.organization?.name ?? COLUMBUS_HIGH_SCHOOL,
      [DiscountReportHeaders.expiresOn]: formatTableDate(discount.expirationDate),
      [DiscountReportHeaders.redeemed]: `${discount.usersRedeemedCount || 0} times`,
      [DiscountReportHeaders.createdOn]: formatTableDate(discount.createdAt),
      [DiscountReportHeaders.status]: discount.status,
    };
  });
}
