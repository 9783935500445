import { Button, ButtonProps } from '@material-ui/core';
import { Link } from 'react-router-dom';

export interface ButtonLinkProps extends Omit<ButtonProps<'a'>, 'component'> {
  to: string;
}

/**
 * A component that renders a button styled link using the `Button` and `Link` components.
 *
 * @param {ButtonLinkProps} props - The properties passed to the component.
 * @param {string} props.to - The destination URL or path for the link.
 * @param {React.ReactNode} props.children - The content to be displayed inside the button.
 */
export function ButtonLink({ to, ...props }: ButtonLinkProps) {
  return (
    <Button {...props} component={Link} to={to}>
      {props.children}
    </Button>
  );
}
