import { Button } from '@material-ui/core';
import { FilterAlt } from '@mui/icons-material';

import { Popover } from 'shared/components/ui';
import { SpreadsheetFiltersType, SpreadsheetFilterContent } from 'shared/features/spreadsheet';

export type SpreadsheetFilterPopoverProps = {
  disabled?: boolean;
  filters: SpreadsheetFiltersType;
};

export function SpreadsheetFilterPopover({ filters, disabled }: SpreadsheetFilterPopoverProps) {
  return (
    <Popover
      target={
        <Button size="small" disabled={disabled} startIcon={<FilterAlt />}>
          Filter
        </Button>
      }
    >
      {popover => <SpreadsheetFilterContent filters={filters} handlePopoverClose={popover.close} />}
    </Popover>
  );
}
